<template>
  <SvgBase iconName="TimeTableNotFound" :width="40" :height="46"
    ><g clip-path="url(#clip0)">
      <path d="M39.5 45.3H0V0H39.5V45.3ZM4 41.3H35.5V4H4V41.3Z" :fill="color" />
      <path
        d="M12.7 18.4998C13.8598 18.4998 14.8 17.5596 14.8 16.3998C14.8 15.24 13.8598 14.2998 12.7 14.2998C11.5402 14.2998 10.6 15.24 10.6 16.3998C10.6 17.5596 11.5402 18.4998 12.7 18.4998Z"
        :fill="color"
      />
      <path
        d="M27.7 18.4998C28.8598 18.4998 29.8 17.5596 29.8 16.3998C29.8 15.24 28.8598 14.2998 27.7 14.2998C26.5402 14.2998 25.6 15.24 25.6 16.3998C25.6 17.5596 26.5402 18.4998 27.7 18.4998Z"
        :fill="color"
      />
      <path
        d="M26.7 31.2002C24.9 29.6002 22.6 28.8002 20.2 28.8002C17.8 28.8002 15.5 29.7002 13.7 31.2002L11.7 28.9002C14.1 26.9002 17 25.7002 20.1 25.7002C23.2 25.7002 26.2 26.8002 28.5 28.9002L26.7 31.2002Z"
        :fill="color"
      />
    </g>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'white',
    },
  },
})
export default class IconNotFound extends Vue {}
</script>
