import store from '@/store'
import { CLEAR_PROFILE, SET_BACK_TO_LOGIN } from '@/store/actions'
import axios, { AxiosRequestConfig } from 'axios'
import { SB_FORCE_UPGRADE_VERSION_KEY, SB_PORTAL_VERSION_KEY } from 'smartbarcode-web-core/src/utils/constants'
import { shouldUpdateClientVersion } from 'smartbarcode-web-core/src/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IBarcodeSearchForm } from 'smartbarcode-web-core/src/utils/types/index'
import { version } from '../../package.json'
import { getCurrentUtcOffset } from './timeUtils'
import { getVisitorId } from './helpers'
export * from '@/apis/barcodeAPI'
export * from '@/apis/locationsAPI'
export * from '@/apis/organizationAPI'
export * from '@/apis/statisticAPI'
export * from '@/apis/systemAPI'
export * from '@/apis/timetableAPI'
export * from '@/apis/tradeWaltzAPI'
export * from '@/apis/userAPI'

export const baseUrl = `${process.env.VUE_APP_API_HOST}`

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 60_000,
  withCredentials: true,
})

axiosInstance.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers['UTC-Offset'] = getCurrentUtcOffset()
    config.headers![SB_PORTAL_VERSION_KEY] = version
  }
  return config
})

export async function refreshToken() {
  const payload = { visitorId: await getVisitorId() }
  return await axiosInstance.post('/user/refresh-token', payload)
}

function checkVersion(sbVersion: string | undefined, forceUpgrade: string | undefined) {
  if (sbVersion === null) return
  const clientVersion = version

  // Since value of headers are string we need to parse to boolean
  const isForceUpgrade = forceUpgrade?.toLowerCase() === 'true'

  if (sbVersion) {
    if (shouldUpdateClientVersion(clientVersion, sbVersion)) {
      document.dispatchEvent(new CustomEvent('onUpdateVersionNotification', { detail: { isForceUpgrade } }))
    }
  }
}

axiosInstance.interceptors.response.use(
  (response) => {
    try {
      const sbVersion = response?.headers?.[SB_PORTAL_VERSION_KEY]
      const isForceUpgrade = response?.headers?.[SB_FORCE_UPGRADE_VERSION_KEY]
      checkVersion(sbVersion, isForceUpgrade)
    } catch (e) {}
    return response
  },
  async (error) => {
    if (error?.message === 'Network Error') {
      // TODO: resolve network error when not get data from server
    }
    // Revisit this when refactoring common error code from API
    // const title = error.response?.data?.title || ''
    // const errors = { ...error.response?.data?.errors, title }
    const errors = error.response?.data?.errors
    const sbVersion = error?.response?.headers?.[SB_PORTAL_VERSION_KEY]
    const isForceUpgrade = error?.response?.headers?.[SB_FORCE_UPGRADE_VERSION_KEY]
    checkVersion(sbVersion, isForceUpgrade)
    switch (error?.response?.status) {
      case 400:
        if (errors?.$common) {
          throw errors.$common[0]
        } else {
          throw errors || error.response?.data
        }

      case 401: {
        const originalRequest = error.config
        try {
          await refreshToken()
          if (!isEmpty(originalRequest.data)) {
            originalRequest.data = JSON.parse(originalRequest.data)
          }

          return axiosInstance(originalRequest)
        } catch (err) {
          store.commit(CLEAR_PROFILE)
          store.dispatch(SET_BACK_TO_LOGIN)
        }
        break
      }
      case 403:
        throw new Error('403')

      case 500:
        throw new Error('500')

      default:
        throw new Error(error?.message)
    }
  }
)

export async function customRequest(payload: AxiosRequestConfig<unknown>) {
  return (await axiosInstance.request(payload)).data
}

export interface IClientSearchDataPayload {
  isConfirm: boolean
  clientIds: string[]
  condition: IBarcodeSearchForm
}

export interface IArchivePayload {
  archiveType: 'archive' | 'unarchive'
  condition: IBarcodeSearchForm
}

export interface IPairRequestBody {
  isDryRun?: boolean
  parentBarcodeId: string
  childrenBarcodeIds?: Array<string>
  forcePairing?: boolean
}

export interface IUnpairRequest {
  isDryRun?: boolean
  parentBarcodeId: string
  isUnpairAll: boolean
  unpairChildrenIds?: Array<string>
}
