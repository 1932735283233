
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { LOAD_CLIENT_LIST } from '@/store/actions'
import { CLIENT_VISIBILITY_TEMPLATE } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import cloneDeep from 'lodash/cloneDeep'
import { EUserType } from 'smartbarcode-web-core/src/utils/enums'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IClient,
  IClientList,
  IClientVisibilities,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { InjectReactive, Prop, PropSync, Watch } from 'vue-property-decorator'
import ShowCustomFieldsBlock from './ShowCustomFieldsBlock.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'

@Options({
  emits: ['update:modelValue'],
  name: 'ClientDisplaySettings',
  components: {
    ShowCustomFieldsBlock,
    ItemNotFound,
  },
})
export default class ClientDisplaySettings extends mixins(ValidateForm) {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @Prop({ type: Boolean, default: false }) readonly isReadOnly?: boolean
  @Prop({ type: Boolean, default: true }) readonly isShowTitle?: boolean
  @Prop({ type: String }) readonly currentBCTypeCode?: string | undefined | null

  @PropSync('modelValue', {
    type: Object,
  })
  _syncedModelValue?: IClientVisibilities[]

  trackPointVisibilities: Record<string, boolean>[] = []
  isShowClientPresetDialog = false
  selectedClients: string[] = []
  selectedIndex = 0

  isProcessedSyncedModelValue = false

  get isEditMode() {
    return !!this.$store.state.project.projectDetail.mainInfo?.id
  }

  get projectCode() {
    return this.$store.state.project.projectDetail.mainInfo?.code ?? ''
  }

  @Watch('currentBCTypeCode')
  onChangeCurrentBCTypeCode() {
    if (!this.currentBCTypeCode || !this.syncedModelValue) {
      return
    }
    this.formatSyncModelData()
  }

  get syncedModelValue(): IClientVisibilities[] {
    if (this._syncedModelValue) {
      return this._syncedModelValue
    } else {
      const newVisibilityTemplate = cloneDeep(CLIENT_VISIBILITY_TEMPLATE)
      this._syncedModelValue = [newVisibilityTemplate]
      return this._syncedModelValue
    }
  }

  set syncedModelValue(val: IClientVisibilities[]) {
    if (this._syncedModelValue) {
      this._syncedModelValue = val
    } else {
      const newVisibilityTemplate = cloneDeep(CLIENT_VISIBILITY_TEMPLATE)
      this._syncedModelValue = [newVisibilityTemplate]
    }
  }

  get tpTracingVisibilities() {
    return this.syncedModelValue.map((_, index) => {
      const visibility = {} as Record<string, boolean>
      const visibleTP = Object.keys(this.syncedModelValue[index]?.trackPointTracingVisibilities || {})
      this.trackingPointDatasArr.forEach((tp) => {
        visibility[tp.key] = visibleTP.includes(tp.key)
      })
      return visibility
    })
  }

  fetchClients() {
    if (this.isLoggedAsClient) return
    try {
      this.$store.dispatch(LOAD_CLIENT_LIST)
    } catch (error) {
      errorHandler(error)
    } finally {
    }
  }

  get clients(): IClient[] {
    return (([...this.$store.state.client.clients]
      .filter((val: IClientList) => !!val.clientUser)
      .map((c: IClientList) => ({
        id: c.clientUser.id,
        name: c.client.name,
        email: c.clientUser.email,
      })) ?? []) as unknown) as IClient[]
  }

  @Watch('trackingPointDatasArr', { deep: true })
  onTrackingPointDatasArrChanged() {
    this.formatSyncModelData()
    this.updateTrackPointVisibilities()
  }

  @Watch('syncedModelValue')
  onSyncedModelValueChanged() {
    this.updateTrackPointVisibilities()
    if (!this.isProcessedSyncedModelValue) {
      this.formatSyncModelData()
    }
  }

  openAssignClientDialog(passedIndex: number) {
    if (this.isReadOnly) return
    this.selectedIndex = passedIndex
    this.selectedClients = this.syncedModelValue[this.selectedIndex].clientIds
    this.isShowClientPresetDialog = true
  }

  updateTrackPointVisibilities() {
    if (this.trackPointVisibilities.length <= 0) {
      return
    }
    this.trackPointVisibilities = this.syncedModelValue.map((item) => {
      const visibility = {} as Record<string, boolean>
      const visibleTP = Object.keys(item.trackPointTracingVisibilities || {})

      this.trackingPointDatasArr.forEach((tp) => {
        visibility[tp.key] = visibleTP.includes(tp.key)
      })

      return visibility
    })
  }

  get loggedUser() {
    return this.$store.state.profile?.user || {}
  }

  get isLoggedAsClient() {
    return this.loggedUser.userType === EUserType.CLIENT || false
  }

  setSelectedClients(index: number) {
    if (this.isReadOnly) return
    this.syncedModelValue[index].clientIds = this.selectedClients
    this.isShowClientPresetDialog = false
  }

  cancelSelectClients() {
    this.selectedClients = []
    this.isShowClientPresetDialog = false
  }

  formatSyncModelData() {
    if (this.syncedModelValue.length === 0) {
      return
    }
    for (let i = 0; i < this.syncedModelValue.length; i++) {
      if (this.syncedModelValue[i].activationDataVisibility) {
        this.processActivationData(this.syncedModelValue[i])
        this.processTrackingData(this.syncedModelValue[i])
      }
    }
    this.isProcessedSyncedModelValue = true
  }

  processActivationData(clientVisibility: IClientVisibilities) {
    const activationData = clientVisibility.activationDataVisibility
    if (!activationData.showCustomFields) {
      activationData.showCustomFields = []
    }
    activationData.hasActivationCustomFields = activationData.showCustomFields.length > 0
  }

  processTrackingData(clientVisibility: IClientVisibilities) {
    this.trackingPointDatasArr.forEach((tp) => {
      if (clientVisibility.trackPointTracingVisibilities[tp.key]) {
        clientVisibility.trackPointTracingVisibilities[tp.key].showCustomFieldsCheckBoxes =
          clientVisibility.trackPointTracingVisibilities?.[tp.key]?.showCustomFields.length > 0
      }
    })
  }

  onTrackPointTracingVisibilitiesChange(index: number, changeKey: string) {
    if (!this.syncedModelValue[index].trackPointTracingVisibilities[changeKey]) {
      this.syncedModelValue[index].trackPointTracingVisibilities[changeKey] = {
        showLocation: false,
        showTrackedPersonName: false,
        showCustomFields: [],
        showLinkedBarcodes: false,
      }
    } else delete this.syncedModelValue[index].trackPointTracingVisibilities[changeKey]
  }

  checkValueObjectEmpty(obj: IClientVisibilities) {
    return Object.entries(obj).every((val) => isEmpty(val[1]))
  }

  onTPShowCustomFieldsModelsChanged(index: number, key: string, newVal: boolean) {
    if (newVal) return
    this.syncedModelValue[index].trackPointTracingVisibilities[key].showCustomFields = []
  }

  onAddClientVisibility() {
    if (this.isReadOnly) return
    const newVisibilityTemplate = cloneDeep(CLIENT_VISIBILITY_TEMPLATE)
    this.syncedModelValue.push({ ...newVisibilityTemplate })
  }

  onDislaySettingDelete(index: number) {
    if (this.isReadOnly) return
    const syncedModelValue = [...this.syncedModelValue]
    syncedModelValue.splice(index, 1)
    this.syncedModelValue = [...syncedModelValue]
  }

  async created() {
    this.fetchClients()
    this.formatSyncModelData()
  }
}
