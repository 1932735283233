<template>
  <div class="mt-2 barcode-type-tab">
    <!-- ============================= BUTTONS ============================= -->
    <BarcodeTypeButtonBlock
      ref="bcTypeBlock"
      v-model="barcodeTypes"
      v-model:currentBCType="formModel"
      v-model:currentBCTypeCode="bcTypeCode"
      v-model:originalBarcodeTypeCode="originalBarcodeTypeCode"
      v-model:recycleToLastestVersion="isRecycleToLastestVersion"
      :isReadOnly="isReadOnlyMode"
    />
    <div ref="bcTypeManager"></div>

    <el-form
      class="p-6 bg-gray-200 rounded-md frm-barcode-type overflow-auto"
      :disabled="!isBarcodeTypeSelected || isReadOnlyMode"
    >
      <div class="pb-6 pr-6 border-b border-gray-400 border-solid text-right">
        <el-button
          type="default"
          :disabled="isRecycleToLastestVersion || isDisableDeleteBCType"
          class="bg-red-min text-white focus:text-white"
          @click="bcTypeBlock.deleteBCType()"
          size="medium"
        >
          {{ $t('barcode_type.delete') }}
        </el-button>
      </div>
      <div>
        <!-- ================================ BASIC INFO ======================== -->
        <BasicInfoBlock
          v-model:barcodeTypeCode="bcTypeCode"
          v-model:selectedBarcodeType="formModel"
          :oldBarcodeTypeCode="originalBarcodeTypeCode"
          :isReadOnly="isReadOnlyMode"
        />

        <!-- ============================= OVERRIDE START TRACKING POINTS ============================= -->
        <div class="border-b border-gray-400 border-solid py-8">
          <TrackPointCheckboxesBlock
            :label="$t('barcode_type.start_tracking_point')"
            v-model="formModel.overridableStartTrackPoints"
          />
        </div>

        <!-- ============================= CUSTOM FIELDS ============================= -->
        <CustomFieldBlock
          v-model:activationFields="formModel.activationFields"
          @update:customFields="customFields = $event"
          v-model:countries="formModel.countries"
        />

        <!-- ============================= TRACKING NUMBER ============================= -->
        <TrackingNumberBlock
          :isDisabled="!isHasTrackingNumber || isRecycleToLastestVersion"
          v-model="formModel.activationFields.trackingNumber"
          :activationFields="formModel.activationFields"
        />

        <!-- ============================= ExternalId ============================= -->
        <ExternalIdBlock
          :isDisabled="!isHasExternalId || isRecycleToLastestVersion"
          v-model="formModel.activationFields.externalId"
          :activationFields="formModel.activationFields"
        />

        <!-- ============================= DELETE TRACKING ============================= -->
        <DeleteTrackingBlock v-model="formModel.allowTrackingUndo" />

        <!-- ============================= BULK UPDATE TRACKING POINT ============================= -->
        <BulkUpdateOperationBlock
          v-model:bulkUpdatableTrackPoints="formModel.bulkUpdatableTrackPoints"
          v-model:bulkUpdateOperationLimitCount="formModel.bulkUpdateOperationLimitCount"
        />

        <!-- ============================= BARCODE LINKS AVAILABLE TRACKING POINT ============================= -->
        <BarcodeLinksAvailTPBlock
          :isReadOnly="isReadOnlyMode"
          v-model:barcodeLinkableTrackPoints="formModel.barcodeLinkableTrackPoints"
          v-model:allowToBeLinked="formModel.allowToBeLinked"
          v-model:linking="formModel.linking"
          v-model="formModel.linkedBarcodeHrefType"
        />

        <!-- ============================= LINKED BARCODE HREF TYPE ============================= -->

        <!-- ============================= PARENT - CHILDRENT ALLOW ============================= -->
        <PairedAllowanceBlock
          v-model:allowToPair="formModel.allowToPair"
          v-model:allowToBePaired="formModel.allowToBePaired"
          @update:allowToPair="onChangeAllowToPair"
        />

        <!-- ============================= PAIRING AND UNPARING ============================= -->
        <PairUnpairBlock
          :allowToPair="formModel.allowToPair"
          :isReadOnly="isReadOnlyMode"
          v-model:pairing="formModel.pairing"
          v-model:unpairing="formModel.unpairing"
        />

        <!-- ============================= RESTRICT ROUTE BLOCKS ============================= -->
        <div class="relative border-b border-gray-400 border-solid py-8">
          <div class="pb-5 font-bold">
            {{ $t('barcode_type.trackpoint_restrict') }}
          </div>
          <!-- ============================= RESTRICT ROUTE  ============================= -->
          <RestrictionRouteBlock v-model:trackPointRouteRestrictedPaths="formModel.trackPointRouteRestrictedPaths" />

          <!-- ============================= TRACKING REMARKS  ============================= -->
          <TrackingRemarksBlock
            v-model="formModel.trackPointRouteRemarks"
            :isReadOnly="isReadOnlyMode"
            :customFields="customFields"
          />

          <!-- ============================= TRACKING NOTIFICATION  ============================= -->
          <TrackingNotificationBlock
            v-if="isEnableNotificationBlock"
            v-model="formModel.trackPointRouteNotifications"
            :barcodeReportTemplates="formModel.barcodeReportTemplates"
            :isReadOnly="isReadOnlyMode"
          />
        </div>
        <!-- ============================= CUSTOM EVENT NOTIFICATION  ============================= -->
        <div class="relative border-b border-gray-400 border-solid py-8">
          <RepeatTrackingPointAlertBlock
            v-model:repeatedVisitingTrackPointAlertSettings="formModel.repeatedVisitingTrackPointAlertSettings"
            :currentBCTypeCode="bcTypeCode"
            :barcodeTypes="barcodeTypes"
          />
        </div>
        <!-- ============================= CUSTOM EVENT NOTIFICATION  ============================= -->
        <div class="relative border-b border-gray-400 border-solid py-8">
          <CustomEventNotification
            v-model:customNotificationEvent="formModel.customNotificationEvent"
            :currentBCTypeCode="bcTypeCode"
            :barcodeTypes="barcodeTypes"
          />
        </div>

        <!-- ============================= RECYCLE BARCODE  ============================= -->
        <RecycleBarcodeBlock
          :isReadOnly="isReadOnlyMode"
          :key="formModel.barcodeRecycleStartTrackPoint"
          :recycleToLastestVersion="isRecycleToLastestVersion"
          v-model:allowBarcodeRecycle="formModel.allowBarcodeRecycle"
          v-model:isRecycleToLastestVersion="formModel.isRecycleToLastestVersion"
          v-model:barcodeRecycleAlertSetting="formModel.barcodeRecycleAlertSetting"
          v-model:barcodeRecycleStartTrackPoint="formModel.barcodeRecycleStartTrackPoint"
          v-model:barcodeRecyclableTrackPoints="formModel.barcodeRecyclableTrackPoints"
        />

        <!-- ============================= TRACE HISTORY DISPLAY WHEN NOT LOGGED IN ============================= -->
        <NotLoginTrackHistoryBlock
          v-model:activationDataVisibility="formModel.activationDataVisibility"
          v-model:trackPointTracingVisibilities="formModel.trackPointTracingVisibilities"
        />

        <!-- ============================= CLIENT DISPLAY SETTINGS ============================= -->
        <ClientDisplaySettings :currentBCTypeCode="bcTypeCode" v-model="formModel.clientVisibilities" />

        <!-- ============================= BARCODE UI CONFIG  ============================= -->
        <BarcodeUIConfig v-model:uiConfig="formModel.uiConfig" />

        <!-- ============================= CUSTOM VALIDATOR  ============================= -->
        <CustomValidator
          v-model:customValidators="formModel.customValidators"
          :currentBCTypeCode="bcTypeCode"
          :barcodeTypes="barcodeTypes"
        />

        <!-- ============================= CUSTOM CONTENTS ============================= -->
        <CustomContentBlock
          :isReadOnly="isReadOnlyMode"
          :currentBCTypeCode="currentBCTypeCode"
          v-model="formModel.customContentTemplates"
        />
        <!-- ============================= EXTERNAL WEB API CONTENT ============================= -->
        <ReportTemplateBlock v-model="formModel.barcodeReportTemplates" :isReadOnly="isReadOnlyMode" />
        <!-- ============================= EXTERNAL WEB API CONTENT ============================= -->
        <ExternalWebApiBlock
          v-model="formModel.externalWebAPIIntegration"
          ref="externalWebApiBlock"
          :isReadOnly="isReadOnlyMode"
          :customFields="customFields"
        />
        <!-- ============================= EXTERNAL WEB API CONTENT ============================= -->
        <PredefinedContactsBlock v-model="formModel.predefinedContact" :isReadOnly="isReadOnlyMode" />
        <!-- ============================= SMAPRI CONFIG ============================= -->
        <SmaPriConfigBlock
          v-model:activationFields="formModel.activationFields"
          v-model:smapriTemplateConfig="formModel.smapriTemplateConfig"
        />
      </div>
    </el-form>
  </div>
</template>
<script lang="ts">
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import { DEFAULT_BARCODE_TYPES, LABEL_CHARACTERS_MAX } from '@/utils/constants'
import {
  excludeNonActivationCustomFieldKeys,
  getCustomFieldOptions,
  getStaticOptions,
  runValidationPipe,
} from '@/utils/helpers'
import { openMessage, sortTrackingPoints } from '@/utils/utils'
import { validateEmail } from '@/utils/validator'
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import set from 'lodash/set'
import { maska } from 'maska'
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'
import { objectToArray } from 'smartbarcode-web-core/src/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcodeDefinitionType,
  ICustomFieldFormData,
  IExternalWebAPIIntegration,
  ITrackpoint,
  ITrackPointKeyVal,
  TOptions,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop, ProvideReactive, Ref, Watch } from 'vue-property-decorator'
import BarcodeLinksAvailTPBlock from './blocks/BarcodeLinksAvailTPBlock.vue'
import BarcodeTypeButtonBlock from './blocks/BarcodeTypeButtonBlock.vue'
import BarcodeUIConfig from './blocks/BarcodeUIConfig.vue'
import BasicInfoBlock from './blocks/BasicInfoBlock.vue'
import BulkUpdateOperationBlock from './blocks/BulkUpdateOperationBlock.vue'
import CustomContentBlock from './blocks/CustomContentBlock.vue'
import CustomEventNotification from './blocks/CustomEventNotification.vue'
import RepeatTrackingPointAlertBlock from './blocks/RepeatTrackingPointAlertBlock.vue'
import CustomFieldBlock from './blocks/CustomFieldBlock.vue'
import CustomValidator from './blocks/CustomValidator.vue'
import DeleteTrackingBlock from './blocks/DeleteTrackingBlock.vue'
import ExternalIdBlock from './blocks/ExternalIdBlock.vue'
import ExternalWebApiBlock from './blocks/ExternalWebApiBlock.vue'
import NotLoginTrackHistoryBlock from './blocks/NotLoginTrackHistoryBlock.vue'
import PairedAllowanceBlock from './blocks/PairedAllowanceBlock.vue'
import PairUnpairBlock from './blocks/PairUnpairBlock.vue'
import PredefinedContactsBlock from './blocks/PredefinedContactsBlock.vue'
import RecycleBarcodeBlock from './blocks/RecycleBarcodeBlock.vue'
import ReportTemplateBlock from './blocks/ReportTemplateBlock.vue'
import RestrictionRouteBlock from './blocks/RestrictionRouteBlock.vue'
import TrackingNotificationBlock from './blocks/TrackingNotification.vue'
import TrackingNumberBlock from './blocks/TrackingNumberBlock.vue'
import TrackingRemarksBlock from './blocks/TrackingRemarksBlock.vue'
import TrackPointCheckboxesBlock from './blocks/TrackPointCheckboxesBlock.vue'
import SmaPriConfigBlock, { reservedFieldNames } from './blocks/SmaPriConfigBlock.vue'
import ClientDisplaySettings from './blocks/ClientDisplaySettings.vue'
export declare type TActivationRouteKey =
  | 'trackPointRouteRestrictedPaths'
  | 'trackPointRouteNotifications'
  | 'trackPointRouteRemarks'
  | 'customValidators'
  | 'customNotificationEvent'

export declare type TActivationPointKey = 'pairing' | 'unpairing' | 'trackPointTracingVisibilities'

@Options({
  directives: { maska },
  name: 'BarcodeType',
  components: {
    BarcodeIcon,
    TrackPointCheckboxesBlock,
    TrackingNumberBlock,
    ExternalIdBlock,
    DeleteTrackingBlock,
    PairedAllowanceBlock,
    PairUnpairBlock,
    NotLoginTrackHistoryBlock,
    CustomFieldBlock,
    RestrictionRouteBlock,
    RecycleBarcodeBlock,
    BasicInfoBlock,
    BarcodeLinksAvailTPBlock,
    CustomContentBlock,
    BarcodeTypeButtonBlock,
    BarcodeUIConfig,
    TrackingRemarksBlock,
    TrackingNotificationBlock,
    CustomValidator,
    ExternalWebApiBlock,
    BulkUpdateOperationBlock,
    ReportTemplateBlock,
    CustomEventNotification,
    PredefinedContactsBlock,
    SmaPriConfigBlock,
    RepeatTrackingPointAlertBlock,
    ClientDisplaySettings,
  },
})
export default class BarcodeType extends Vue {
  @ProvideReactive() trackingPointDatasArr: ITrackPointKeyVal[] = []
  @ProvideReactive() trackingPointDataShown: ITrackPointKeyVal[] = []
  @ProvideReactive() customFields: ICustomFieldFormData[] = []

  @Prop({ default: false }) readonly startLoading: boolean = false
  @Ref() bcTypeBlock!: BarcodeTypeButtonBlock

  formModel: IBarcodeDefinitionType = cloneDeep(DEFAULT_BARCODE_TYPES.default)
  isRecycleToLastestVersion = false
  originalBCTypeCode = ''
  currentBCTypeCode = ''
  trackPoints = {} as Record<string, ITrackpoint>

  projectBCTypes = {} as Record<string, IBarcodeDefinitionType>

  get isDisableDeleteBCType() {
    return Object.keys(this.projectBCTypes).length <= 1
  }

  @Watch('customFields')
  onCustomFieldsChanged() {
    // delete reference
    // When customfield deleted or changed we find places to remove reference.
    // delete UI list
    const modelValue = this.formModel.uiConfig.listViewTitleLabelField
    if (!modelValue) return
    const options = getStaticOptions()
    const customField = getCustomFieldOptions({ fields: this.customFields || [], excludeFieldType: [] })
    options.push(...customField)
    const isExist = options?.find((val) => val?.value === modelValue)
    if (!isExist) {
      this.formModel.uiConfig.listViewTitleLabelField = ''
    }
  }

  get barcodeTypes(): Record<string, IBarcodeDefinitionType> {
    return this.projectBCTypes ?? {}
  }

  set barcodeTypes(bcTypes: Record<string, IBarcodeDefinitionType>) {
    this.projectBCTypes = bcTypes
  }

  get isHasExternalId() {
    return (
      this.formModel.activationFields?.externalId?.isMandatory ||
      this.formModel.activationFields?.externalId?.isAvailable
    )
  }

  get isHasTrackingNumber() {
    return (
      this.formModel.activationFields.trackingNumber.isMandatory ||
      this.formModel.activationFields.trackingNumber.isAvailable
    )
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  get originalBarcodeTypeCode() {
    return this.originalBCTypeCode
  }

  set originalBarcodeTypeCode(newCode: string) {
    this.originalBCTypeCode = newCode
  }

  get bcTypeCode() {
    return this.currentBCTypeCode
  }

  set bcTypeCode(newCode: string) {
    const oldCode = this.bcTypeCode
    this.$nextTick(() => this.bcTypeBlock.updateBCTypeCode(newCode, oldCode))
    this.currentBCTypeCode = newCode
  }

  isBarcodeTypeSelected(): boolean {
    return this.bcTypeBlock?.isBarcodeTypeSelected ?? false
  }

  @Watch('startLoading')
  loading() {
    if (this.startLoading && isEmpty(this.barcodeTypes)) {
      this.$nextTick(() => this.bcTypeBlock.addNewBCType())
    }
  }

  @Watch('$store.state.project.projectDetail.barcodeTypes', { immediate: true })
  getBarcodeType() {
    const newBCTypes = this.$store.state.project.projectDetail.barcodeTypes
    // apply default value for some fields available in new project. but some old project don't have this kind of field.
    const applyMandatoryOptions2BarcodeTypes = () => {
      Object.keys(this.barcodeTypes).forEach((key) => {
        const bcType = this.barcodeTypes[key]
        ;[
          'linkedBarcodeHrefType',
          'activationDataVisibility',
          'trackPointTracingVisibilities',
          'clientVisibilities',
          'activationFields.trackingNumber',
          'activationFields.externalId',
          'activationFields.customFields',
          'trackPointRouteRemarks',
          'trackPointRouteNotifications',
          'externalWebAPIIntegration',
          'barcodeReportTemplates',
          'activationFields.trackingNumber.validationType',
          'linking',
          'predefinedContact',
        ].forEach((k) => {
          if (!get(bcType, k)) set(bcType, k, cloneDeep(get(DEFAULT_BARCODE_TYPES.default, k)))
        })

        bcType.uiConfig = {
          ...DEFAULT_BARCODE_TYPES.default.uiConfig,
          ...bcType.uiConfig,
        }
      })
    }

    if (!isEqual(this.barcodeTypes, newBCTypes)) {
      this.barcodeTypes = cloneDeep(newBCTypes ?? {})
      this.$nextTick(() => this.bcTypeBlock.selectLatestBCType())
    }

    applyMandatoryOptions2BarcodeTypes()
  }

  @Watch('$store.state.project.projectDetail.trackPoints', { immediate: true })
  getTrackPoints() {
    const newTPs = this.$store.state.project.projectDetail.trackPoints
    if (isEmpty(newTPs)) return

    this.trackPoints = cloneDeep(newTPs)
    const arrTrackPoints = objectToArray<ITrackpoint>(this.trackPoints) || []
    sortTrackingPoints(arrTrackPoints)
    this.trackingPointDatasArr = arrTrackPoints
    this.trackingPointDataShown = cloneDeep(arrTrackPoints).filter((val) => !val.value.isEnd)
  }

  @Watch('endTrackPointKey')
  onEndTPKeyChange(newKey: string | undefined) {
    if (!newKey) return
    Object.keys(this.barcodeTypes).forEach((key) => {
      const customContentAvailTP = this.barcodeTypes[key].customContentTemplates?.[0].trackPointKeys
      if (customContentAvailTP) customContentAvailTP[0] = newKey
    })
  }

  get isEnableNotificationBlock() {
    return this.$store.getters.isEnableNotificationSetting
  }

  onChangeAllowToPair(val: boolean) {
    if (val) {
      this.formModel.pairing = {}
      this.formModel.unpairing = {}
    } else {
      delete this.formModel.pairing
      delete this.formModel.unpairing
    }
  }

  // Call from parent component
  barcodeTypesPostPackage() {
    // force reload tps and not wait for slowly reload tps
    this.getTrackPoints()
    this.onEndTPKeyChange(this.endTrackPointKey)

    const barcodeTypes = cloneDeep(this.barcodeTypes)

    // exclude keys that should not available inside customfield payload of ActivationFields
    Object.values(barcodeTypes).forEach((bctype) => {
      Object.values(bctype.activationFields.customFields ?? {}).forEach((customField) =>
        excludeNonActivationCustomFieldKeys(customField)
      )
    })
    return barcodeTypes
  }

  get endTrackPointKey(): string | undefined {
    return Object.keys(this.trackPoints).find((tpKey) => this.trackPoints[tpKey].isEnd)
  }

  validate(): boolean {
    // validate all labels of custom fields
    let isLabelValid = true
    let fieldName: string | undefined
    let isExternalWebAPIValid = true
    let isExternalAPIValidButtonLabelValid = true
    let isExternalAPIValidUrlEndPointLabelValid = true
    let isReportBarcodeTemplateLabelValid = true
    let isReportBarcodeTemplateFileUrlValid = true
    let isPredefinedEmailValid = true
    let isPredefinedPhoneValid = true
    let isSmaPriConfigPathValid = true
    let isSmaPriConfigFilledFieldValid = true
    let isSmaPriConfigUniquelyValid = true
    let areSmaPriConfigAllFieldNamesValid = true
    let isSmaPriConfigNotUseReservedFieldNameValid = true
    Object.keys(this.barcodeTypes).forEach((typeKey) => {
      const barcodeType = this.barcodeTypes[typeKey] as IBarcodeDefinitionType

      Object.values(barcodeType.activationFields.customFields ?? {}).forEach((field) => {
        if ((field.label ?? '').length > LABEL_CHARACTERS_MAX && !fieldName) {
          isLabelValid = false
          fieldName = `${this.$t('barcode_type.activation')} → ${barcodeType.name} → ${field.label}`
        }

        if ([ECustomFieldType.SINGLE_SELECT, ECustomFieldType.MULTI_SELECT].includes(field.fieldType ?? '')) {
          const options: TOptions = field.selections ?? {}
          Object.keys(options).forEach((k) => {
            if (options[k].label.length > LABEL_CHARACTERS_MAX && !fieldName) {
              isLabelValid = false
              fieldName = `${this.$t('barcode_type.activation')} → ${barcodeType.name} → ${field.label} → ${
                options[k].label
              }`
            }
          })
        }
      })

      if (
        barcodeType?.externalWebAPIIntegration &&
        !this.checkValueObjectEmpty(barcodeType?.externalWebAPIIntegration)
      ) {
        const externalWebAPIIntegration = barcodeType?.externalWebAPIIntegration
        if (externalWebAPIIntegration?.requestBody) {
          try {
            JSON.parse(JSON.stringify(externalWebAPIIntegration?.requestBody, null, '\t').replaceAll(/\s/g, ''))
          } catch (error) {
            isExternalWebAPIValid = false
            return false
          }
        }

        if (isEmpty(externalWebAPIIntegration.endpointUrl)) {
          isExternalAPIValidUrlEndPointLabelValid = false
          return false
        }

        if (isEmpty(externalWebAPIIntegration.buttonLabel)) {
          isExternalAPIValidButtonLabelValid = false
          return false
        }
      }

      if (barcodeType.barcodeReportTemplates) {
        barcodeType.barcodeReportTemplates.forEach((item) => {
          if (item && (!isEmpty(item?.templateFilePath) || !isEmpty(item?.buttonLabel))) {
            if (isEmpty(item.templateFilePath)) {
              isReportBarcodeTemplateFileUrlValid = false
              return false
            }

            if (isEmpty(item.buttonLabel)) {
              isReportBarcodeTemplateLabelValid = false
              return false
            }
          }
        })
      }

      if (barcodeType.predefinedContact) {
        barcodeType.predefinedContact.emails.forEach((i) => {
          if (!validateEmail(i)) {
            isPredefinedEmailValid = false
            return false
          }
        })
        barcodeType.predefinedContact.numberContacts.forEach((i) => {
          if (!parsePhoneNumber(i.number, i.countryCode as CountryCode).isValid()) {
            isPredefinedPhoneValid = false
            return false
          }
        })
      }

      if (barcodeType.smapriTemplateConfig.isEnabledSmaPri) {
        if (!barcodeType.smapriTemplateConfig.templateFilePath) {
          isSmaPriConfigPathValid = false
          return false
        }

        if (barcodeType.smapriTemplateConfig.templateFields.some((field) => !field.fieldName)) {
          isSmaPriConfigFilledFieldValid = false
          return false
        }

        const uniqueTemplateFieldKeys = [
          ...new Set(barcodeType.smapriTemplateConfig.templateFields.map((field) => field.fieldName)),
        ]
        if (!(barcodeType.smapriTemplateConfig.templateFields.length === uniqueTemplateFieldKeys.length)) {
          isSmaPriConfigUniquelyValid = false
          return false
        }

        const alphanumericRegex = /^([0-9]|[A-z])+$/g
        const isIncludeInvalidCharacter = barcodeType.smapriTemplateConfig.templateFields
          .map((field) => field.fieldName)
          .filter((fieldName) => !reservedFieldNames.includes(fieldName))
          .some((fieldName) => !fieldName.match(alphanumericRegex))
        if (isIncludeInvalidCharacter) {
          areSmaPriConfigAllFieldNamesValid = false
          return false
        }

        let isIncludingReservedFieldName = false
        barcodeType.smapriTemplateConfig.templateFields.forEach((field) => {
          if (reservedFieldNames.includes(field.fieldName) && field.key !== field.fieldName) {
            isIncludingReservedFieldName = true
          }
        })
        if (isIncludingReservedFieldName) {
          isSmaPriConfigNotUseReservedFieldNameValid = false
          return false
        }
      }
    })

    const BCTypeKey = Object.keys(this.barcodeTypes).find((key) => isEmpty(this.barcodeTypes[key].userTypes))
    return runValidationPipe([
      {
        isInvalid: !!Object.keys(this.barcodeTypes).find((k) => k.trim() === ''),
        exec: () => openMessage(this.$t('barcode_type.one_barcode_type_code_empty'), 'error'),
      },
      {
        isInvalid: !!Object.values(this.barcodeTypes).find((i: IBarcodeDefinitionType) => i.name.trim() === ''),
        exec: () => openMessage(this.$t('barcode_type.one_barcode_type_name_empty'), 'error'),
      },
      {
        isInvalid: !isExternalAPIValidUrlEndPointLabelValid,
        exec: () => openMessage(this.$t('external_web_api_url_invalid'), 'error'),
      },
      {
        isInvalid: !isExternalAPIValidButtonLabelValid,
        exec: () => openMessage(this.$t('external_web_api_button_label_invalid'), 'error'),
      },
      {
        isInvalid: !isReportBarcodeTemplateFileUrlValid,
        exec: () => openMessage(this.$t('report_barcode_template_file_url_invalid'), 'error'),
      },
      {
        isInvalid: !isReportBarcodeTemplateLabelValid,
        exec: () => openMessage(this.$t('report_barcode_template_button_label_invalid'), 'error'),
      },
      {
        isInvalid: !isExternalWebAPIValid || !this.$refs.externalWebApiBlock.isJSonValid,
        exec: () => openMessage(this.$t('json_format_error'), 'error'),
      },
      {
        isInvalid: !isLabelValid,
        exec: () =>
          openMessage(
            this.$t('field_label_maxLength_error', { field: fieldName, val1: LABEL_CHARACTERS_MAX }),
            'error'
          ),
      },
      {
        isInvalid: !!BCTypeKey,
        exec: () =>
          openMessage(this.$t('no_user_type_error', { val1: this.barcodeTypes[BCTypeKey ?? ''].name }), 'error'),
      },
      {
        isInvalid: !isPredefinedEmailValid,
        exec: () => openMessage(this.$t('email_format_incorrect'), 'error'),
      },
      {
        isInvalid: !isPredefinedPhoneValid,
        exec: () => openMessage(this.$t('phone_format_incorrect'), 'error'),
      },
      {
        isInvalid: !isSmaPriConfigPathValid,
        exec: () => openMessage(this.$t('barcode_type.smapri.error.path_invalid'), 'error'),
      },
      {
        isInvalid: !isSmaPriConfigFilledFieldValid,
        exec: () => openMessage(this.$t('barcode_type.smapri.error.unfilled_field'), 'error'),
      },
      {
        isInvalid: !isSmaPriConfigUniquelyValid,
        exec: () => openMessage(this.$t('barcode_type.smapri.error.not_unique_field'), 'error'),
      },
      {
        isInvalid: !areSmaPriConfigAllFieldNamesValid,
        exec: () => openMessage(this.$t('barcode_type.smapri.error.invalid_field'), 'error'),
      },
      {
        isInvalid: !isSmaPriConfigNotUseReservedFieldNameValid,
        exec: () => {
          const reservedNames = `"${reservedFieldNames.join('","')}"`
          return openMessage(
            this.$t('barcode_type.smapri.error.reserved_field_name', { reservedNames: reservedNames }),
            'error'
          )
        },
      },
    ])
  }

  checkValueObjectEmpty(obj: IExternalWebAPIIntegration) {
    return Object.entries(obj).every((val) => isEmpty(val[1]))
  }

  @Watch('formModel.barcodeReportTemplates')
  updateNotificationReportTemplateKey() {
    if (this.formModel.trackPointRouteNotifications && this.formModel.barcodeReportTemplates) {
      const reportTemplateCodes =
        this.formModel.barcodeReportTemplates.map((item) => item?.code ?? '') || ([] as string[])
      const trackPointRouteNotifications = [...this.formModel.trackPointRouteNotifications]
      trackPointRouteNotifications.forEach((element, index) => {
        if (element.requestReportButtonLabelGenerates) {
          const newRequestReports = element.requestReportButtonLabelGenerates.filter((item) =>
            reportTemplateCodes.includes(item)
          )
          trackPointRouteNotifications[index].requestReportButtonLabelGenerates = [...newRequestReports]
        }
      })
      this.formModel.trackPointRouteNotifications = [...trackPointRouteNotifications]
    }
  }
}
</script>

<style lang="scss" scoped>
.frm-barcode-type {
  max-height: calc(100vh - 300px);
}
</style>
