
import BarcodeRowMixin from '@/components/mixins/BarcodeRowMixin.vue'
import { MASKED_ANNOTATION } from 'smartbarcode-web-core/src/utils/constants'
import { Options } from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'

@Options({ name: 'BarcodeRowEsign' })
export default class BarcodeRowEsign extends Mixins(BarcodeRowMixin) {
  @Prop({ type: Array }) readonly customFieldImages?: Array<Record<string, string>>
  @Prop({ type: Boolean, default: false }) readonly hidden!: boolean

  get isMasked() {
    return this.customFieldImages?.some((image) => image?.value === MASKED_ANNOTATION)
  }

  changeSlide(index: number) {
    this.$refs.imageCarousel.setActiveItem(index)
  }
}
