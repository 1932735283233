import { IChartOption } from 'smartbarcode-web-core/src/utils/types'
import { axiosInstance } from '@/utils/api'

export async function fetchCustomGraphs() {
  const response = await axiosInstance.get('/statistics/graphs')
  return response?.data
}
export async function createCustomGraph(chartOption?: IChartOption) {
  const response = await axiosInstance.post('/statistics/graph', chartOption)
  return response?.data
}

export async function editCustomGraph(chartOption: IChartOption) {
  const response = await axiosInstance.put('/statistics/graph', chartOption)
  return response?.data
}

export async function deleteCustomGraph(graphId: string) {
  const response = await axiosInstance.delete('/statistics/graph', {
    data: {
      graphId,
    },
  })
  return response?.data
}

export async function getExecutedGraph(graphId: string) {
  const response = await axiosInstance.get('/statistics/exec-graph', {
    params: { graphId },
  })
  return response?.data
}
