<template>
  <div class="relative mt-5 p-5 border-b border-gray-400 border-solid py-8">
    <div class="flex" style="place-content: space-between">
      <div v-if="isShowTitle" class="font-bold">
        {{ $t('barcode_type.client_data_display_settings') }}
      </div>
    </div>
    <div class="relative">
      <div
        v-for="(clientVisibility, index) in syncedModelValue"
        :key="index"
        class="mt-5 custom-field-bg-color rounded-lg p-6"
      >
        <div>
          <div v-if="!isReadOnly" class="text-right">
            <el-button type="primary" @click="openAssignClientDialog(index)" :disabled="isReadOnly">
              {{ $t('barcodeImport.assign_client') }}
            </el-button>
          </div>
          <div class="grid grid-cols-5 my-5 gap-4">
            <div class="col-span-2 text-right">{{ $t('barcode_type.basic_info') }}</div>
            <div class="col-span-3">
              <div class="mb-3">
                <el-checkbox v-model="clientVisibility.activationDataVisibility.showOrigin" :disabled="isReadOnly">
                  {{ $t('barcode_type.origin') }}
                </el-checkbox>
              </div>
              <div class="mb-3">
                <el-checkbox v-model="clientVisibility.activationDataVisibility.showDestination" :disabled="isReadOnly">
                  {{ $t('barcode_type.destination') }}
                </el-checkbox>
              </div>

              <div class="mb-3">
                <el-checkbox
                  v-model="clientVisibility.activationDataVisibility.showTrackingNumber"
                  :disabled="isReadOnly"
                >
                  {{ $t('tracking_number') }}
                </el-checkbox>
              </div>
              <div class="mb-3">
                <el-checkbox v-model="clientVisibility.activationDataVisibility.showExternalId" :disabled="isReadOnly">
                  {{ $t('barcode_type.external_id') }}
                </el-checkbox>
              </div>
              <div class="mb-3">
                <el-checkbox v-model="clientVisibility.activationDataVisibility.showDimension" :disabled="isReadOnly">
                  {{ $t('barcode_type.dimension') }}
                </el-checkbox>
              </div>
              <div class="mb-3">
                <el-checkbox v-model="clientVisibility.activationDataVisibility.showProduct" :disabled="isReadOnly">
                  {{ $t('barcode_type.product_info') }}
                </el-checkbox>
              </div>
              <div class="mb-3">
                <el-checkbox
                  v-model="clientVisibility.activationDataVisibility.hasActivationCustomFields"
                  :disabled="isReadOnly"
                >
                  {{ $t('record_item_setting') }}
                </el-checkbox>
              </div>
              <ShowCustomFieldsBlock
                :disabled="isReadOnly"
                v-if="clientVisibility.activationDataVisibility.hasActivationCustomFields"
                v-model:customFieldVisibilities="clientVisibility.activationDataVisibility.showCustomFields"
              />

              <div class="mb-3">
                <el-checkbox
                  v-model="clientVisibility.activationDataVisibility.showLinkedBarcodes"
                  :disabled="isReadOnly"
                >
                  {{ $t('barcode_type.linked_barcodes') }}
                </el-checkbox>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-5 my-5 gap-4">
            <div class="col-span-2 text-right">{{ $t('barcode_type.view_trace_history') }}</div>
            <div class="col-span-3">
              <div class="mb-3" v-for="kv in trackingPointDatasArr" :key="kv.key">
                <div>
                  <el-checkbox
                    v-model="tpTracingVisibilities[index][kv.key]"
                    @change="onTrackPointTracingVisibilitiesChange(index, kv.key)"
                    :disabled="isReadOnly"
                  >
                    {{ kv.value.name }} {{ `(${kv.key})` }}
                  </el-checkbox>
                </div>
                <div
                  v-if="tpTracingVisibilities[index][kv.key]"
                  class="border-l-2 border-gray-500 border-solid pl-2 ml-12"
                >
                  <div class="p-1">
                    <el-checkbox
                      v-model="clientVisibility.trackPointTracingVisibilities[kv.key].showLocation"
                      :disabled="isReadOnly"
                    >
                      {{ $t('barcode_type.location') }}
                    </el-checkbox>
                  </div>
                  <div class="p-1">
                    <el-checkbox
                      v-model="clientVisibility.trackPointTracingVisibilities[kv.key].showTrackedPersonName"
                      :disabled="isReadOnly"
                    >
                      {{ $t('barcode_type.name_of_person_who_added_tracking') }}
                    </el-checkbox>
                  </div>

                  <div class="p-1">
                    <el-checkbox
                      v-model="clientVisibility.trackPointTracingVisibilities[kv.key].showCustomFieldsCheckBoxes"
                      @change="onTPShowCustomFieldsModelsChanged(index, kv.key, $event)"
                      :disabled="isReadOnly"
                    >
                      {{ $t('record_item_setting') }}
                    </el-checkbox>
                  </div>
                  <ShowCustomFieldsBlock
                    :disabled="isReadOnly"
                    v-if="clientVisibility.trackPointTracingVisibilities[kv.key].showCustomFieldsCheckBoxes"
                    v-model:customFieldVisibilities="
                      clientVisibility.trackPointTracingVisibilities[kv.key].showCustomFields
                    "
                  />

                  <div class="p-1">
                    <el-checkbox
                      v-model="clientVisibility.trackPointTracingVisibilities[kv.key].showLinkedBarcodes"
                      :disabled="isReadOnly"
                    >
                      {{ $t('barcode_type.linked_barcodes') }}
                    </el-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-3 flex flex-wrap gap-3 items-center justify-between">
            <el-button
              @click="onDislaySettingDelete(index)"
              class="bg-red-min text-white focus:text-white"
              v-if="!isReadOnly"
              :disabled="isReadOnly"
            >
              {{ $t('delete') }}
            </el-button>
          </div>
        </div>
      </div>
      <div>
        <ItemNotFound
          iconColor="black"
          background="bg-primary-pure-white"
          class="mb-5"
          :content="$t('permissions.no_data')"
          v-if="syncedModelValue.length === 0"
        />
      </div>
      <div v-if="!isReadOnly" class="absolute add-client-setting">
        <el-button type="primary" @click="onAddClientVisibility()">{{ $t('barcode_type.add_new_rule') }}</el-button>
      </div>
    </div>
    <div class="dialog-area">
      <el-dialog
        v-if="!isLoggedAsClient"
        custom-class="el-dialog--custom"
        v-model="isShowClientPresetDialog"
        width="1000px"
        top="0"
        :title="$t('barcodeImport.assign_client')"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        :show-close="true"
        @close="isShowClientPresetDialog = false"
      >
        <div>
          {{ $t('select_clients_to_asign_data_visibility') }}
        </div>
        <div class="my-4">
          <el-checkbox-group v-model="selectedClients">
            <div class="flex flex-row flex-wrap px-2 group-name-container">
              <div v-for="client in clients" :key="client.id" class="w-1/4 py-2">
                <el-checkbox :value="client.id" :label="client.id" :disabled="isReadOnly">
                  {{ client.name }}
                </el-checkbox>
              </div>
            </div>
          </el-checkbox-group>
        </div>
        <template #footer>
          <div class="flex">
            <div class="flex-1">
              <el-button
                type="default"
                class="btn-default-cancel"
                @click="cancelSelectClients()"
                :disabled="isReadOnly"
              >
                {{ $t('barcode_type.cancel') }}
              </el-button>
            </div>
            <div class="flex-1">
              <el-button type="primary" @click="setSelectedClients(selectedIndex)" :disabled="isReadOnly">
                {{ $t('set') }}
              </el-button>
            </div>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script lang="ts">
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { LOAD_CLIENT_LIST } from '@/store/actions'
import { CLIENT_VISIBILITY_TEMPLATE } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import cloneDeep from 'lodash/cloneDeep'
import { EUserType } from 'smartbarcode-web-core/src/utils/enums'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IClient,
  IClientList,
  IClientVisibilities,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { InjectReactive, Prop, PropSync, Watch } from 'vue-property-decorator'
import ShowCustomFieldsBlock from './ShowCustomFieldsBlock.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'

@Options({
  emits: ['update:modelValue'],
  name: 'ClientDisplaySettings',
  components: {
    ShowCustomFieldsBlock,
    ItemNotFound,
  },
})
export default class ClientDisplaySettings extends mixins(ValidateForm) {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @Prop({ type: Boolean, default: false }) readonly isReadOnly?: boolean
  @Prop({ type: Boolean, default: true }) readonly isShowTitle?: boolean
  @Prop({ type: String }) readonly currentBCTypeCode?: string | undefined | null

  @PropSync('modelValue', {
    type: Object,
  })
  _syncedModelValue?: IClientVisibilities[]

  trackPointVisibilities: Record<string, boolean>[] = []
  isShowClientPresetDialog = false
  selectedClients: string[] = []
  selectedIndex = 0

  isProcessedSyncedModelValue = false

  get isEditMode() {
    return !!this.$store.state.project.projectDetail.mainInfo?.id
  }

  get projectCode() {
    return this.$store.state.project.projectDetail.mainInfo?.code ?? ''
  }

  @Watch('currentBCTypeCode')
  onChangeCurrentBCTypeCode() {
    if (!this.currentBCTypeCode || !this.syncedModelValue) {
      return
    }
    this.formatSyncModelData()
  }

  get syncedModelValue(): IClientVisibilities[] {
    if (this._syncedModelValue) {
      return this._syncedModelValue
    } else {
      const newVisibilityTemplate = cloneDeep(CLIENT_VISIBILITY_TEMPLATE)
      this._syncedModelValue = [newVisibilityTemplate]
      return this._syncedModelValue
    }
  }

  set syncedModelValue(val: IClientVisibilities[]) {
    if (this._syncedModelValue) {
      this._syncedModelValue = val
    } else {
      const newVisibilityTemplate = cloneDeep(CLIENT_VISIBILITY_TEMPLATE)
      this._syncedModelValue = [newVisibilityTemplate]
    }
  }

  get tpTracingVisibilities() {
    return this.syncedModelValue.map((_, index) => {
      const visibility = {} as Record<string, boolean>
      const visibleTP = Object.keys(this.syncedModelValue[index]?.trackPointTracingVisibilities || {})
      this.trackingPointDatasArr.forEach((tp) => {
        visibility[tp.key] = visibleTP.includes(tp.key)
      })
      return visibility
    })
  }

  fetchClients() {
    if (this.isLoggedAsClient) return
    try {
      this.$store.dispatch(LOAD_CLIENT_LIST)
    } catch (error) {
      errorHandler(error)
    } finally {
    }
  }

  get clients(): IClient[] {
    return (([...this.$store.state.client.clients]
      .filter((val: IClientList) => !!val.clientUser)
      .map((c: IClientList) => ({
        id: c.clientUser.id,
        name: c.client.name,
        email: c.clientUser.email,
      })) ?? []) as unknown) as IClient[]
  }

  @Watch('trackingPointDatasArr', { deep: true })
  onTrackingPointDatasArrChanged() {
    this.formatSyncModelData()
    this.updateTrackPointVisibilities()
  }

  @Watch('syncedModelValue')
  onSyncedModelValueChanged() {
    this.updateTrackPointVisibilities()
    if (!this.isProcessedSyncedModelValue) {
      this.formatSyncModelData()
    }
  }

  openAssignClientDialog(passedIndex: number) {
    if (this.isReadOnly) return
    this.selectedIndex = passedIndex
    this.selectedClients = this.syncedModelValue[this.selectedIndex].clientIds
    this.isShowClientPresetDialog = true
  }

  updateTrackPointVisibilities() {
    if (this.trackPointVisibilities.length <= 0) {
      return
    }
    this.trackPointVisibilities = this.syncedModelValue.map((item) => {
      const visibility = {} as Record<string, boolean>
      const visibleTP = Object.keys(item.trackPointTracingVisibilities || {})

      this.trackingPointDatasArr.forEach((tp) => {
        visibility[tp.key] = visibleTP.includes(tp.key)
      })

      return visibility
    })
  }

  get loggedUser() {
    return this.$store.state.profile?.user || {}
  }

  get isLoggedAsClient() {
    return this.loggedUser.userType === EUserType.CLIENT || false
  }

  setSelectedClients(index: number) {
    if (this.isReadOnly) return
    this.syncedModelValue[index].clientIds = this.selectedClients
    this.isShowClientPresetDialog = false
  }

  cancelSelectClients() {
    this.selectedClients = []
    this.isShowClientPresetDialog = false
  }

  formatSyncModelData() {
    if (this.syncedModelValue.length === 0) {
      return
    }
    for (let i = 0; i < this.syncedModelValue.length; i++) {
      if (this.syncedModelValue[i].activationDataVisibility) {
        this.processActivationData(this.syncedModelValue[i])
        this.processTrackingData(this.syncedModelValue[i])
      }
    }
    this.isProcessedSyncedModelValue = true
  }

  processActivationData(clientVisibility: IClientVisibilities) {
    const activationData = clientVisibility.activationDataVisibility
    if (!activationData.showCustomFields) {
      activationData.showCustomFields = []
    }
    activationData.hasActivationCustomFields = activationData.showCustomFields.length > 0
  }

  processTrackingData(clientVisibility: IClientVisibilities) {
    this.trackingPointDatasArr.forEach((tp) => {
      if (clientVisibility.trackPointTracingVisibilities[tp.key]) {
        clientVisibility.trackPointTracingVisibilities[tp.key].showCustomFieldsCheckBoxes =
          clientVisibility.trackPointTracingVisibilities?.[tp.key]?.showCustomFields.length > 0
      }
    })
  }

  onTrackPointTracingVisibilitiesChange(index: number, changeKey: string) {
    if (!this.syncedModelValue[index].trackPointTracingVisibilities[changeKey]) {
      this.syncedModelValue[index].trackPointTracingVisibilities[changeKey] = {
        showLocation: false,
        showTrackedPersonName: false,
        showCustomFields: [],
        showLinkedBarcodes: false,
      }
    } else delete this.syncedModelValue[index].trackPointTracingVisibilities[changeKey]
  }

  checkValueObjectEmpty(obj: IClientVisibilities) {
    return Object.entries(obj).every((val) => isEmpty(val[1]))
  }

  onTPShowCustomFieldsModelsChanged(index: number, key: string, newVal: boolean) {
    if (newVal) return
    this.syncedModelValue[index].trackPointTracingVisibilities[key].showCustomFields = []
  }

  onAddClientVisibility() {
    if (this.isReadOnly) return
    const newVisibilityTemplate = cloneDeep(CLIENT_VISIBILITY_TEMPLATE)
    this.syncedModelValue.push({ ...newVisibilityTemplate })
  }

  onDislaySettingDelete(index: number) {
    if (this.isReadOnly) return
    const syncedModelValue = [...this.syncedModelValue]
    syncedModelValue.splice(index, 1)
    this.syncedModelValue = [...syncedModelValue]
  }

  async created() {
    this.fetchClients()
    this.formatSyncModelData()
  }
}
</script>

<style lang="scss" scoped>
.add-client-setting {
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 50%);
}

.dialog-area :deep() {
  .el-overlay .el-dialog {
    max-width: 1000px;
  }

  .el-button--small {
    padding: 7px;
    min-height: 28px !important;
    margin-top: 3px;
  }
  .el-form-item {
    margin-bottom: 16px;
    &__item {
      top: calc(100% - 5px);
    }
  }
}
</style>
