
import SearchForm from '@/components/barcodeSearch/SearchForm.vue'
import BaseInput from '@/components/common/BaseInput.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import PhoneInputOnlyNumber from '@/components/common/PhoneInputOnlyNumber.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import BarcodeTypeButtonBlock from '@/components/project/blocks/BarcodeTypeButtonBlock.vue'
import ClientDisplaySettings from '@/components/project/blocks/ClientDisplaySettings.vue'
import {
  CLEAR_DETAIL_CLIENT,
  FETCH_CLIENT,
  FETCH_COUNTRIES,
  FETCH_STAFF,
  LOAD_ACTIVE_PROJECTS_LIST,
  LOAD_CLIENT_LIST,
  SET_PAGE_NOT_FOUND,
} from '@/store/actions'
import { fetchProjectById, saveClient } from '@/utils/api'
import { DEFAULT_BARCODE_TYPES, POPULAR_COUNTRIES, POPULAR_COUNTRY_LIST } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import {
  buildSearchUrlQuery,
  getInitClientDetailState,
  initDataSearch,
  removeCharacterPhoneNumber,
} from '@/utils/helpers'
import { openMessage, sortTrackingPoints } from '@/utils/utils'
import { el } from 'date-fns/locale'
import parsePhoneNumber, { AsYouType, CountryCode } from 'libphonenumber-js'
import { get, isEqual, set } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { maska } from 'maska'
import { OTHER_COUNTRY_LIST, PHONE_MASK, ZIPCODE_MASK } from 'smartbarcode-web-core/src/utils/constants'
import { objectToArray } from 'smartbarcode-web-core/src/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcodeDefinitionType,
  IBarcodeSearchForm,
  IClient,
  IClientDetailVisibilities,
  ICountryCode,
  IProject,
  ISearchConditionBlock,
  IStaff,
  ITrackpoint,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { ProvideReactive, Ref, Watch } from 'vue-property-decorator'
interface IOption {
  value: string
  label: string
  disabled: boolean
}
@Options({
  components: {
    PageHeader,
    PhoneInputOnlyNumber,
    SearchForm,
    ClientDisplaySettings,
    BaseInput,
    BarcodeTypeButtonBlock,
  },
  directives: { maska },
  name: 'ClientForm',
})
export default class ClientForm extends mixins(ValidateForm) {
  @ProvideReactive() projectVersion = 0
  @ProvideReactive() trackingPointDatasArr: ITrackPointKeyVal[] = []
  @ProvideReactive() trackingPointDataShown: ITrackPointKeyVal[] = []
  @Ref() bcTypeBlock!: BarcodeTypeButtonBlock
  projectBCTypes = {} as Record<string, IBarcodeDefinitionType>
  formModel: IBarcodeDefinitionType = cloneDeep(DEFAULT_BARCODE_TYPES.default)
  currentBCTypeCode = ''
  originalBCTypeCode = ''
  isRecycleToLastestVersion = false
  trackPoints = {} as Record<string, ITrackpoint>

  isSaveDisabled = false
  clientForm = {} as IClient
  isEditPassword = false
  countries: {
    popularCountries: string[]
    allCountries: string[]
  } = {
    popularCountries: [],
    allCountries: [],
  }

  loading = false
  projectData: IOption[] = []
  projectsWithClientSetting: IProject[] = []
  selectedProject: IProject | null = null
  selectedBarCodeType = ''
  currentSelectedProjectIndex = 0
  listBarcode = []

  listProjectVersion: string[] = []
  selectedProjectVersion: string | null = null

  get clientDisplayVisibilitiesInfo() {
    if (!this.selectedProject) return false
    const barcodeType = this.selectedProject.barcodeTypes[this.selectedBarCodeType]
    return barcodeType ? barcodeType.clientVisibilities : false
  }

  @Watch('bcTypeCode')
  onWatch() {
    this.selectedBarCodeType = this.bcTypeCode
  }

  get barcodeTypes(): Record<string, IBarcodeDefinitionType> {
    return this.projectBCTypes ?? {}
  }

  set barcodeTypes(bcTypes: Record<string, IBarcodeDefinitionType>) {
    this.projectBCTypes = bcTypes
  }

  get originalBarcodeTypeCode() {
    return this.originalBCTypeCode
  }

  set originalBarcodeTypeCode(newCode: string) {
    this.originalBCTypeCode = newCode
  }

  get bcTypeCode() {
    return this.currentBCTypeCode
  }

  set bcTypeCode(newCode: string) {
    const oldCode = this.bcTypeCode
    this.$nextTick(() => this.bcTypeBlock.updateBCTypeCode(newCode, oldCode))
    this.currentBCTypeCode = newCode
  }

  @Watch('selectedProject', { immediate: true })
  onChangSelectedProject() {
    this.getTrackPoints()
    this.getBarcodeType()
  }

  getTrackPoints() {
    if (!this.selectedProject) return
    const newTPs = this.selectedProject.trackPoints
    if (isEmpty(newTPs)) return

    this.trackPoints = cloneDeep(newTPs)
    const arrTrackPoints = objectToArray<ITrackpoint>(this.trackPoints) || []
    sortTrackingPoints(arrTrackPoints)
    this.trackingPointDatasArr = arrTrackPoints
    this.trackingPointDataShown = cloneDeep(arrTrackPoints).filter((val) => !val.value.isEnd)
  }

  getBarcodeType() {
    if (!this.selectedProject) return
    const newBCTypes = Object.entries(this.selectedProject.barcodeTypes).reduce(
      (acc: Record<string, IBarcodeDefinitionType>, [key, value]) => {
        if (value.clientVisibilities) {
          acc[key] = value
        }
        return acc
      },
      {}
    )
    const applyMandatoryOptions2BarcodeTypes = () => {
      Object.keys(this.barcodeTypes).forEach((key) => {
        const bcType = this.barcodeTypes[key]
        ;[
          'linkedBarcodeHrefType',
          'activationDataVisibility',
          'trackPointTracingVisibilities',
          'activationFields.trackingNumber',
          'activationFields.externalId',
          'activationFields.customFields',
          'trackPointRouteRemarks',
          'trackPointRouteNotifications',
          'externalWebAPIIntegration',
          'barcodeReportTemplates',
          'activationFields.trackingNumber.validationType',
          'linking',
          'predefinedContact',
        ].forEach((k) => {
          if (!get(bcType, k)) set(bcType, k, cloneDeep(get(DEFAULT_BARCODE_TYPES.default, k)))
        })

        bcType.uiConfig = {
          ...DEFAULT_BARCODE_TYPES.default.uiConfig,
          ...bcType.uiConfig,
        }
      })
    }

    if (!isEqual(this.barcodeTypes, newBCTypes)) {
      this.barcodeTypes = cloneDeep(newBCTypes ?? {})
      this.$nextTick(() => this.bcTypeBlock.selectLatestBCType())
    }

    applyMandatoryOptions2BarcodeTypes()
  }

  async onVersionChange(version: number) {
    const clientVisibilities = this.clientForm.clientVisibilities
    if (!clientVisibilities) {
      return
    }

    const selectedProj = clientVisibilities.find(
      (el) => el.projectCode === this.selectedProject?.code && el.projectVersion.toString() === version.toString()
    )

    this.selectedProjectVersion = version.toString()
    if (selectedProj) {
      await this.handleGetProjectInfo(selectedProj.projectId)
    }
  }

  async onSelectProject(index: number) {
    this.currentSelectedProjectIndex = index
    if (!this.projectsWithClientSetting[this.currentSelectedProjectIndex]) {
      return
    }
    await this.handleGetProjectInfo(this.projectsWithClientSetting?.[this.currentSelectedProjectIndex]?.id, true)
  }

  get projectList() {
    return this.projectData.map((project) => ({
      ...project,
      disabled: this.isPresetSearchAvail,
    }))
  }

  get isSetDisplayedBarcodeByClient() {
    return this.$store.state.profile?.organization?.setDisplayedBarcodeByClient || false
  }

  get carrierIds() {
    return this.clientForm.clientUser.carrierIds
  }

  get searchPreset(): IBarcodeSearchForm {
    const preset = this.clientForm.clientUser.condition ?? initDataSearch()
    preset.searchConditionBlocks.forEach(async (block: ISearchConditionBlock) => {
      // specially handle projectId
      const firstCond = block.searchConditions[0]
      if (firstCond.key === 'projectId') {
        block.searchConditions.splice(0, 1)
      }

      block.searchConditions.forEach((condition) => {
        // specially handle subKey
        const keys = (condition.key ?? '').split('.')
        if (keys.length === 3) {
          condition.key = `${keys[0]}.${keys[1]}`
          condition.subKey = keys[2]
        }
      })
    })

    return Object.freeze({
      ...preset,
      version: preset.version ?? 0,
    })
  }

  get isPresetSearchAvail() {
    return this.clientForm.clientUser.setPresetSearch
  }

  get getCarrierList() {
    return this.$store.state.carrier?.staffs || []
  }

  get availableCarrierList() {
    const staffs: IStaff[] = this.getCarrierList

    return staffs.filter(
      (staff) => staff.clientIds?.includes(this.clientForm.clientUser.id || '') || staff.clientIds?.length === 0
    )
  }

  onPresetCliked() {
    this.$router.push({ name: 'barcodeSearch', query: buildSearchUrlQuery(this.searchPreset, 1) })
  }

  async handleGetProjectInfo(id: string | undefined, isRenewListProject?: boolean) {
    if (!id) return
    this.loading = true
    try {
      this.loading = true
      const responseData = await fetchProjectById(id)
      if (responseData) {
        this.selectedProject = responseData.project
        if (isRenewListProject) {
          this.listProjectVersion = []
          this.handleGetListProjectVersion(this.selectedProject)
        }
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  handleGetListProjectVersion(project: IProject | null) {
    if (!project || !this.clientForm.clientVisibilities) return
    const matchedClientVisibilities = this.clientForm.clientVisibilities.filter(
      (clientVisibility: IClientDetailVisibilities) => clientVisibility.projectCode === project.code
    )
    this.listProjectVersion = matchedClientVisibilities
      .sort((a, b) => b.projectVersion - a.projectVersion)
      .map((project) => project.projectVersion.toString())

    this.selectedProjectVersion = this.listProjectVersion[0]
  }

  async fetchProjects() {
    if (isEmpty(this.$store.state.project?.activeProjects)) {
      await this.$store.dispatch(LOAD_ACTIVE_PROJECTS_LIST)
    }

    this.projectData = this.$store.state.project?.activeProjects.map(
      (item: IProject) =>
        (({
          value: item.code ?? '',
          label: item.name ?? '',
          disabled: this.isPresetSearchAvail,
        } as unknown) as IOption)
    )

    this.setClientProjectWithClientVisibility()
  }

  setClientProjectWithClientVisibility() {
    this.projectsWithClientSetting = this.handleGetProjectWithClientSetting()
  }

  handleGetProjectWithClientSetting() {
    if (!this.clientForm.clientVisibilities) {
      return []
    }
    const projectCodes = this.clientForm.clientVisibilities.map((project) => project.projectCode)
    const filteredList = this.$store.state.project?.activeProjects.filter((item: IProject) => {
      return item.code && projectCodes.includes(item.code)
    })
    return filteredList
  }

  @Watch('clientForm.clientUser.phone.number')
  onPhoneChange() {
    const phoneNumber = parsePhoneNumber(
      this.clientForm.clientUser.phone.number || '',
      this.clientForm.clientUser.phone.countryCode as CountryCode
    )
    if (phoneNumber) {
      this.clientForm.clientUser.phone.number = new AsYouType(
        this.clientForm.clientUser.phone.countryCode as CountryCode
      )
        .input(this.clientForm.clientUser.phone.number || '')
        .replaceAll(' ', '-')
    }
  }

  formatPhoneValidate = (rule: string, value: string, callback: Function) => {
    if (isEmpty(value)) return callback(new Error(this.messages.required))

    const phoneNumber = parsePhoneNumber(
      this.clientForm.clientUser.phone.number || '',
      this.clientForm.clientUser.phone.countryCode as CountryCode
    )

    if (phoneNumber) {
      if (phoneNumber.isValid()) {
        callback()
      } else {
        callback(new Error(this.messages.number))
      }
    } else {
      callback(new Error(this.messages.number))
    }
  }

  formatAddressPhoneValidate = (rule: string, value: string, callback: Function) => {
    if (isEmpty(value)) {
      return callback(new Error(this.messages.required))
    }

    setTimeout(() => {
      let isValid = false
      if (this.masks.phoneNumber) {
        if (this.masks.phoneNumber.length === value.length) isValid = true
        if (this.masks.phoneNumber.length === PHONE_MASK.DEFAULT.length) isValid = true
      } else {
        if (value.length <= 11) isValid = true
      }
      if (isValid) {
        callback()
      } else {
        callback(new Error(this.messages.number))
      }
    }, 100)
  }

  rules = {
    clientName: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    address: {
      country: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      postalCode: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      prefecture: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      address1: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      phoneNumber: [{ required: true, validator: this.formatAddressPhoneValidate, trigger: 'blur' }],
      companyName: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    },
    clientUser: {
      email: [{ required: true, validator: this.requiredEmailValidate, trigger: 'blur' }],
      password: [{ required: true, validator: this.requiredPasswordValidate, trigger: 'blur' }],
      firstName: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      lastName: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      phone: {
        number: [{ required: true, validator: this.formatPhoneValidate, trigger: 'blur' }],
        countryCode: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
      },
    },
  }

  get countryList() {
    return {
      popularCountries: POPULAR_COUNTRY_LIST,
      allCountries: OTHER_COUNTRY_LIST,
    } as {
      popularCountries: Record<string, ICountryCode>
      allCountries: Record<string, ICountryCode>
    }
  }

  get masks() {
    return {
      phoneNumber: PHONE_MASK[this.clientForm?.address?.country] || PHONE_MASK.DEFAULT,
      postalCode: ZIPCODE_MASK[this.clientForm?.address?.country] || '',
      dimension: '#*.#',
    }
  }

  updatePhoneNumber() {
    this.clientForm.clientUser.phone.number = ''
  }

  updateClientContactNumber() {
    this.clientForm.address.phoneNumber = ''
  }

  onSaveClient(formName: string) {
    this.isSaveDisabled = true
    this.$refs[formName].validate((valid: string) => {
      if (valid) {
        this.saveClient()
      } else {
        openMessage(this.$t('validate_occur'), 'error')
        this.isSaveDisabled = false
        return false
      }
    })
  }

  get id() {
    return this.$route.params.id
  }

  async saveClient() {
    try {
      this.loading = true
      const newPhoneFormat = removeCharacterPhoneNumber(this.clientForm?.clientUser?.phone.number)
      const newData = {
        ...this.clientForm,
        clientUser: {
          ...this.clientForm.clientUser,
          phone: {
            ...this.clientForm.clientUser.phone,
            number: newPhoneFormat,
          },
          condition: cloneDeep(this.$store.state.client.client.clientUser.condition),
        },
      }
      await saveClient({ ...newData, ...{ id: this.id } }, this.isEditPassword)
      openMessage(this.$t('save_successful'), 'success')

      await this.$store.dispatch(LOAD_CLIENT_LIST)
      this.backToClientList()
    } catch (err) {
      errorHandler(err)
    } finally {
      this.isSaveDisabled = false
      this.loading = false
    }
  }

  onChangeEditPassword() {
    if (this.id) {
      if (this.isEditPassword) {
        this.rules.clientUser.password = [{ required: true, validator: this.requiredPasswordValidate, trigger: 'blur' }]
      } else {
        this.rules.clientUser.password = []
      }
    }
  }

  backToClientList() {
    this.$router.push({ name: 'clients' })
  }

  async loadClientForm() {
    try {
      this.clientForm = getInitClientDetailState()
      if (this.id) {
        this.loading = true
        const promises = [this.$store.dispatch(FETCH_CLIENT, this.id)]
        if (this.isSetDisplayedBarcodeByClient) {
          promises.push(this.$store.dispatch(FETCH_STAFF))
        }
        await Promise.all(promises)
      } else {
        this.$store.commit(CLEAR_DETAIL_CLIENT)
      }
    } catch (error) {
      this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'client.client' })
    } finally {
      this.loading = false
    }
  }

  @Watch('$store.state.client.client')
  getInfo() {
    this.clientForm = cloneDeep(this.$store.state.client.client)
    this.setClientProjectWithClientVisibility()
  }

  async initList() {
    this.countries.popularCountries = { ...POPULAR_COUNTRIES }
    if (!this.$store.state.profile?.isLoadCountry) {
      this.$store.dispatch(FETCH_COUNTRIES).then(() => {
        this.countries.allCountries = this.$store.state.profile?.countries
      })
    } else {
      this.countries.allCountries = this.$store.state.profile?.countries
    }

    this.onChangeEditPassword()
    this.messages.required = this.$t('this_field_is_required')
    this.messages.number = this.$t('please_enter_numeric')
    this.messages.email = this.$t('email_format_incorrect')
    this.messages.password = this.$t('please_enter_password')
    await this.loadClientForm()
  }

  async created() {
    await Promise.all([this.initList(), this.fetchProjects()])
    if (this.projectData.length > 0) {
      await this.handleGetProjectInfo(this.projectsWithClientSetting?.[this.currentSelectedProjectIndex]?.id, true)
    }
  }
}
