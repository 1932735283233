<template>
  <div class="flex mx--2 h-full">
    <div class="px-2px" :class="`w-${labelWidth}`">
      <div class="h-full text-gray-500 bg-gray-100 px-2">
        <template v-if="isLabelTooltip">
          <div class="tooltip-label flex items-center h-full">
            <el-tooltip
              :class="`box-item w-full ${isMaxWordLabelLength ? 'break-all' : ''}`"
              effect="dark"
              :content="label"
              placement="top"
            >
              <label>{{ displayLabel }}</label>
            </el-tooltip>
          </div>
        </template>
        <template v-else>
          <div class="flex items-center h-full">
            <span class="mr-2">{{ label }}</span>
            <template v-if="hidden">
              <el-tooltip class="font-bold" effect="dark" :content="$t('hidden_field_tooltip')" placement="top">
                <span class="bold text-blue-600">ⓘ</span>
              </el-tooltip>
            </template>
            <template v-if="isMasked">
              <el-tooltip class="font-bold" effect="dark" :content="$t('isMasked_field_tooltip')" placement="top">
                <span class="bold text-blue-600">ⓘ</span>
              </el-tooltip>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div :class="`w-full--${labelWidth} px-2px h-full`">
      <div class="flex bg-white flex-col px-2 h-40 overflow-y-auto">
        <div class="flex my-2 items-center" v-for="(item, index) in values ?? []" :key="index">
          <div v-if="item?.url !== maskedAnnotation" class="px-2 w-7">
            <IconCommonFile />
          </div>
          <div class="pl-4 pr-2">
            <a
              v-if="!isMasked"
              :href="item?.url"
              class="flex h-8 text-black items-center truncate leading-normal url-underline"
            >
              {{ item.name }}
            </a>
            <a
              v-else
              :class="[
                'flex h-8 text-black items-center truncate leading-normal url-underline',
                item?.url === maskedAnnotation ? 'masked-value' : '',
              ]"
            >
              {{ maskedAnnotation }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BarcodeRowMixin from '@/components/mixins/BarcodeRowMixin.vue'
import IconCommonFile from '@/components/svg/IconCommonFile.vue'
import { MASKED_ANNOTATION } from 'smartbarcode-web-core/src/utils/constants'
import { IFileItem } from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'

@Options({
  components: { IconCommonFile },
  name: 'BarcodeRowMulti',
})
export default class BarcodeRowMulti extends Mixins(BarcodeRowMixin) {
  @Prop({ type: Array }) readonly values?: IFileItem[]
  @Prop({ type: Boolean, default: false }) readonly hidden!: boolean

  get isMasked() {
    return this.values?.some((item) => item?.url === MASKED_ANNOTATION)
  }

  get maskedAnnotation() {
    return MASKED_ANNOTATION
  }
}
</script>
