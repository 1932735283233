<template>
  <div class="w-2/4">
    <el-autocomplete
      v-if="isAutoCompleteSearch"
      v-model="_searchItem.value"
      :fetch-suggestions="querySearch"
      clearable
      class="w-full el-select"
      :highlight-first-item="true"
    />
    <el-input v-else :class="'w-full'" size="medium" v-model="_searchItem.value"></el-input>
  </div>
</template>

<script lang="ts">
import SearchItemField from '@/components/barcodeSearch/SearchItemField.vue'
import { getBarcodeSearchAutoComplete } from '@/utils/api'
import { BARCODE_AUTO_COMPLETE_SEARCH_KEYS } from '@/utils/constants'
import cloneDeep from 'lodash/cloneDeep'
import { IBarcodeSearchFormItem, IBarcodeSearchAutoCompleteRequest } from 'smartbarcode-web-core/src/utils/types'
import { Options } from 'vue-class-component'
import { Mixins, PropSync } from 'vue-property-decorator'

interface IAutoCompleteResults {
  key: string
  value: string
}

@Options({
  components: {},
  name: 'SearchItemTextbox',
})
export default class SearchItemTextbox extends Mixins(SearchItemField) {
  @PropSync('searchItem', { type: Object }) _searchItem!: IBarcodeSearchFormItem

  created() {
    this.oldData = cloneDeep(this._searchItem)
  }

  get isAutoCompleteSearch() {
    return BARCODE_AUTO_COMPLETE_SEARCH_KEYS.includes(this._searchItem.key)
  }

  get searchData() {
    return this.$store.state.barcode.dataSearch
  }

  get constructAutcoCompleteQuery() {
    const { projectCode, version, projectId } = this.searchData
    const autoCompleteSearch: IBarcodeSearchAutoCompleteRequest = {
      condition: {
        projectCode,
        version,
        projectId,
        autoComplete: {
          key: this._searchItem.key,
          value: this._searchItem.value || '',
        },
      },
    }
    return autoCompleteSearch
  }

  async querySearch(queryString: string, cb: Function) {
    const emptyValue: string[] = []
    try {
      if (queryString?.length > 1) {
        const searchCondition = this.constructAutcoCompleteQuery
        const searchResults: IAutoCompleteResults[] = await getBarcodeSearchAutoComplete(searchCondition)
        const results = searchResults
          .map((val) => {
            return { label: val?.key, value: val?.value }
          })
          .filter(
            (value, index, self) => index === self.findIndex((t) => t.label === value.label && t.value === value.value)
          )

        cb(results)
      } else {
        cb(emptyValue)
      }
    } catch (error) {
      cb(emptyValue)
    }
  }
}
</script>
