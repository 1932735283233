
import BarcodePDFLayout from '@/components/barcodeImport/BarcodePDFLayout.vue'
import ColorPicker from '@/components/composables/ColorPicker.vue'
import {
  BARCODE_IMPORT_LAYOUT_PDF_EXPORT,
  BARCODE_IMPORT_NUMBER_ITEM_PDF_EXPORT,
  BARCODE_IMPORT_ONLINE_TRAVEL_LAYOUT_PDF_EXPORT,
  BARCODE_IMPORT_PDF_LABELS,
  BARCODE_IMPORT_PDF_MULTI_LINE_LAYOUT,
  EXPORT_PDF_COLOR_PALLET,
  OPERATION_TYPE,
} from '@/utils/constants'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { FIELD_TYPE } from 'smartbarcode-web-core/src/utils/constants'
import {
  IBarcodeDefinitionType,
  IBarcodeList,
  IFieldOption,
  IProject,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop } from 'vue-property-decorator'
import { displayMomentAtParam } from '@/utils/timeUtils'
import moment from 'moment'
import { generateOnlineTravelLayout } from '@/utils/helpers'
import errorHandler from '@/utils/errorHandler'

@Options({
  components: {
    BarcodePDFLayout,
    ColorPicker,
  },
  emits: ['done:exportPDF'],
  name: 'BarcodePDF',
})
export default class BarcodePDF extends Vue {
  @InjectReactive() projectVersion!: number
  @Prop({ type: Object }) readonly project!: IProject
  @Prop({ type: Array }) readonly barcodes!: IBarcodeList[]
  @Prop({ type: String }) readonly barcodeType?: string
  @Prop({ type: String }) readonly operationType?: string

  numberItemLayout = 0
  selectedBackGroundColor = '#FFFFFF'
  barcodeImportLayoutValue = 0
  barcodeImportShowLabel = [BARCODE_IMPORT_PDF_LABELS.trackingNumber]
  isShowExportPDFDialog = false
  isTriggerExportPDF = false
  loading = false

  get colorPallet() {
    return EXPORT_PDF_COLOR_PALLET
  }

  get isNotBulkBarcodeImport() {
    return this.operationType !== OPERATION_TYPE.BULK_CREATE
  }

  get barcodeImportLayouts() {
    const result = {} as Record<number, string>
    for (const key in BARCODE_IMPORT_LAYOUT_PDF_EXPORT) {
      result[BARCODE_IMPORT_LAYOUT_PDF_EXPORT[key]] = this.$t(`barcodeImport.${key}`)
    }
    if (this.project.code === 'sgw003') {
      for (const key in BARCODE_IMPORT_ONLINE_TRAVEL_LAYOUT_PDF_EXPORT) {
        result[BARCODE_IMPORT_ONLINE_TRAVEL_LAYOUT_PDF_EXPORT[key]] = this.$t(`barcodeImport.${key}`)
      }
    }
    return result
  }

  get displayLabels() {
    const options = [] as IFieldOption[]
    if (
      this.project.code === 'sgw003' &&
      this.barcodeImportLayoutValue === BARCODE_IMPORT_ONLINE_TRAVEL_LAYOUT_PDF_EXPORT.onlineTravel
    ) {
      options.push(
        ...[
          {
            value: BARCODE_IMPORT_PDF_LABELS.externalId,
            label: `${this.$t('barcode.externalId')}`,
          },
        ]
      )
      this.barcodeImportShowLabel = [BARCODE_IMPORT_PDF_LABELS.externalId]
    } else {
      if (this.barcodeImportLayoutValue === BARCODE_IMPORT_LAYOUT_PDF_EXPORT.a34) {
        options.push({
          value: '',
          label: this.$t('empty_field'),
        })
      }
      options.push(
        ...[
          {
            value: BARCODE_IMPORT_PDF_LABELS.trackingNumber,
            label: `${this.$t('projects.trackingNumber')}`,
          },
          {
            value: BARCODE_IMPORT_PDF_LABELS.externalId,
            label: `${this.$t('barcode.externalId')}`,
          },
        ]
      )

      if (this.projectVersion !== 0) {
        if (this.barcodeType) {
          const item = this.barcodeTypeDetail
          if (!isEmpty(item)) {
            const customFields = item.activationFields.customFields
            for (const key in customFields) {
              if (
                customFields[key].fieldType !== FIELD_TYPE.ESIGN &&
                customFields[key].fieldType !== FIELD_TYPE.FILES
              ) {
                options.push({
                  value: `customFields.${key}`,
                  label: `[${this.$t('record_item_setting')} ]  ${customFields[key].label}`,
                })
              }
            }
          }
        } else {
          for (const keyBC in this.project.barcodeTypes) {
            const item = this.project.barcodeTypes[keyBC]
            if (!isEmpty(item)) {
              const customFields = item.activationFields.customFields
              for (const key in customFields) {
                if (
                  customFields[key].fieldType !== FIELD_TYPE.ESIGN &&
                  customFields[key].fieldType !== FIELD_TYPE.FILES
                ) {
                  options.push({
                    value: `customFields.${key}`,
                    label: `[${this.$t('record_item_setting')} ]  ${customFields[key].label}`,
                  })
                }
              }
            }
          }
        }
      }
    }
    return options
  }

  get barcodeTypeDetail(): IBarcodeDefinitionType {
    return this.project.barcodeTypes?.[this.barcodeType || ''] || ({} as IBarcodeDefinitionType)
  }

  showComfirmPopup() {
    this.isShowExportPDFDialog = true
  }

  calculateItemLayout() {
    this.numberItemLayout = BARCODE_IMPORT_NUMBER_ITEM_PDF_EXPORT[this.barcodeImportLayoutValue]
    if (BARCODE_IMPORT_PDF_MULTI_LINE_LAYOUT.includes(this.barcodeImportLayoutValue)) {
      this.barcodeImportShowLabel = [
        [BARCODE_IMPORT_PDF_LABELS.trackingNumber, '', '', '', '', '', '', ''],
        [BARCODE_IMPORT_PDF_LABELS.trackingNumber, '', ''],
      ][this.barcodeImportLayoutValue]
    } else {
      this.barcodeImportShowLabel = [BARCODE_IMPORT_PDF_LABELS.trackingNumber]
    }
  }

  formatBarcodeType(barcodeTypeDefinition: IBarcodeDefinitionType): IBarcodeDefinitionType {
    return barcodeTypeDefinition as IBarcodeDefinitionType
  }

  exportPDFDone() {
    this.loading = false
    this.isTriggerExportPDF = false
    this.$emit('done:exportPDF')
  }

  async exportPDF() {
    try {
      this.loading = true
      if (this.barcodeImportLayoutValue === BARCODE_IMPORT_ONLINE_TRAVEL_LAYOUT_PDF_EXPORT.onlineTravel) {
        await generateOnlineTravelLayout(this.barcodes, this.selectedBackGroundColor, this.exportFilename)
      } else {
        this.isShowExportPDFDialog = false
        setTimeout(() => (this.isTriggerExportPDF = true), 150)
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  get exportFilename() {
    return `barcode-group-${displayMomentAtParam(moment())}.pdf`
  }

  async created() {
    this.barcodeImportLayoutValue = BARCODE_IMPORT_LAYOUT_PDF_EXPORT.a446

    this.calculateItemLayout()
  }
}
