<template>
  <div class="flex flex-col" v-loading.fullscreen="loading">
    <el-dialog
      custom-class="el-dialog--custom"
      v-model="isShowExportPDFDialog"
      width="630px"
      top="0"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <template #title>
        <span> {{ $t('barcodeImport.barcodePDFDownload') }}</span>
      </template>
      <div class="grid grid-cols-12 my-3 items-center">
        <span class="col-span-3 w-36 flex justify-end"> {{ $t('barcodeImport.numberBarcodes') }}</span>
        <span class="col-span-9 pl-5">
          {{ $t('barcodeImport.formatNumberBarcodes', { number: barcodes.length }) }}</span
        >
      </div>
      <div class="grid grid-cols-12 my-1 items-center">
        <span class="col-span-3 flex justify-end"> {{ $t('barcodeImport.layout') }}</span>
        <div class="col-span-5 pl-4">
          <el-select
            key="barcodeImportLayout"
            v-model="barcodeImportLayoutValue"
            size="medium"
            @change="calculateItemLayout"
          >
            <el-option v-for="(item, key) in barcodeImportLayouts" :key="key" :label="item" :value="Number(key)" />
          </el-select>
        </div>

        <div class="col-span-4 flex justify-end items-center mr-4">
          <div :class="['col-span-3 mr-4', $t('barcode_type.background_color').length > 3 ? '' : 'mx-5']">
            {{ $t('barcode_type.background_color') }}
          </div>
          <ColorPicker
            :placement="'bottom-end'"
            v-model:selectedVal="selectedBackGroundColor"
            :colorArray="colorPallet"
          />
        </div>
      </div>
      <template v-if="isNotBulkBarcodeImport">
        <template v-if="barcodeImportShowLabel.length > 1">
          <template v-for="(item, index) in barcodeImportShowLabel" :key="index">
            <div class="grid grid-cols-12 my-3 items-center">
              <span class="col-span-3 w-36 flex justify-end">
                {{ $t('barcodeImport.display_label') }} {{ index + 1 }}</span
              >
              <el-select class="col-span-9 pl-4 mr-4" v-model="barcodeImportShowLabel[index]" size="large" filterable>
                <el-option v-for="(item, key) in displayLabels" :key="key" :label="item.label" :value="item.value" />
              </el-select>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="grid grid-cols-12 my-3 items-center">
            <span class="col-span-3 w-36 flex justify-end"> {{ $t('barcodeImport.display_label') }}</span>
            <el-select class="col-span-9 pl-4 mr-4" v-model="barcodeImportShowLabel[0]" size="large" filterable>
              <el-option v-for="(item, key) in displayLabels" :key="key" :label="item.label" :value="item.value" />
            </el-select>
          </div>
        </template>
      </template>
      <div class="grid grid-cols-12 my-5 items-center">
        <span class="col-span-3 w-36 flex justify-end"> {{ $t('barcodeImport.numberSheetsPaper') }}</span>
        <span class="col-span-9 pl-5">
          {{ barcodes.length }} / {{ numberItemLayout }} =
          {{ Math.round((barcodes.length / numberItemLayout) * 10) / 10 }}
          ({{ $t('barcodeImport.formatNumberBarcodes', { number: Math.ceil(barcodes.length / numberItemLayout) }) }})
        </span>
      </div>
      <template #footer>
        <div class="flex">
          <div class="flex-1">
            <el-button type="default" class="btn-default-cancel" @click="isShowExportPDFDialog = false">
              {{ $t('cancel') }}
            </el-button>
          </div>
          <div class="flex-1">
            <el-button type="primary" @click="exportPDF()">
              {{ $t('timetable.process') }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>

    <BarcodePDFLayout
      v-if="isTriggerExportPDF"
      ref="barcodePDFLayout"
      :color="selectedBackGroundColor"
      :barcodes="barcodes"
      :layout="barcodeImportLayoutValue"
      :showLabel="barcodeImportShowLabel"
      :project="project"
      @update:exportPDFDone="exportPDFDone"
    />
  </div>
</template>

<script lang="ts">
import BarcodePDFLayout from '@/components/barcodeImport/BarcodePDFLayout.vue'
import ColorPicker from '@/components/composables/ColorPicker.vue'
import {
  BARCODE_IMPORT_LAYOUT_PDF_EXPORT,
  BARCODE_IMPORT_NUMBER_ITEM_PDF_EXPORT,
  BARCODE_IMPORT_ONLINE_TRAVEL_LAYOUT_PDF_EXPORT,
  BARCODE_IMPORT_PDF_LABELS,
  BARCODE_IMPORT_PDF_MULTI_LINE_LAYOUT,
  EXPORT_PDF_COLOR_PALLET,
  OPERATION_TYPE,
} from '@/utils/constants'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { FIELD_TYPE } from 'smartbarcode-web-core/src/utils/constants'
import {
  IBarcodeDefinitionType,
  IBarcodeList,
  IFieldOption,
  IProject,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop } from 'vue-property-decorator'
import { displayMomentAtParam } from '@/utils/timeUtils'
import moment from 'moment'
import { generateOnlineTravelLayout } from '@/utils/helpers'
import errorHandler from '@/utils/errorHandler'

@Options({
  components: {
    BarcodePDFLayout,
    ColorPicker,
  },
  emits: ['done:exportPDF'],
  name: 'BarcodePDF',
})
export default class BarcodePDF extends Vue {
  @InjectReactive() projectVersion!: number
  @Prop({ type: Object }) readonly project!: IProject
  @Prop({ type: Array }) readonly barcodes!: IBarcodeList[]
  @Prop({ type: String }) readonly barcodeType?: string
  @Prop({ type: String }) readonly operationType?: string

  numberItemLayout = 0
  selectedBackGroundColor = '#FFFFFF'
  barcodeImportLayoutValue = 0
  barcodeImportShowLabel = [BARCODE_IMPORT_PDF_LABELS.trackingNumber]
  isShowExportPDFDialog = false
  isTriggerExportPDF = false
  loading = false

  get colorPallet() {
    return EXPORT_PDF_COLOR_PALLET
  }

  get isNotBulkBarcodeImport() {
    return this.operationType !== OPERATION_TYPE.BULK_CREATE
  }

  get barcodeImportLayouts() {
    const result = {} as Record<number, string>
    for (const key in BARCODE_IMPORT_LAYOUT_PDF_EXPORT) {
      result[BARCODE_IMPORT_LAYOUT_PDF_EXPORT[key]] = this.$t(`barcodeImport.${key}`)
    }
    if (this.project.code === 'sgw003') {
      for (const key in BARCODE_IMPORT_ONLINE_TRAVEL_LAYOUT_PDF_EXPORT) {
        result[BARCODE_IMPORT_ONLINE_TRAVEL_LAYOUT_PDF_EXPORT[key]] = this.$t(`barcodeImport.${key}`)
      }
    }
    return result
  }

  get displayLabels() {
    const options = [] as IFieldOption[]
    if (
      this.project.code === 'sgw003' &&
      this.barcodeImportLayoutValue === BARCODE_IMPORT_ONLINE_TRAVEL_LAYOUT_PDF_EXPORT.onlineTravel
    ) {
      options.push(
        ...[
          {
            value: BARCODE_IMPORT_PDF_LABELS.externalId,
            label: `${this.$t('barcode.externalId')}`,
          },
        ]
      )
      this.barcodeImportShowLabel = [BARCODE_IMPORT_PDF_LABELS.externalId]
    } else {
      if (this.barcodeImportLayoutValue === BARCODE_IMPORT_LAYOUT_PDF_EXPORT.a34) {
        options.push({
          value: '',
          label: this.$t('empty_field'),
        })
      }
      options.push(
        ...[
          {
            value: BARCODE_IMPORT_PDF_LABELS.trackingNumber,
            label: `${this.$t('projects.trackingNumber')}`,
          },
          {
            value: BARCODE_IMPORT_PDF_LABELS.externalId,
            label: `${this.$t('barcode.externalId')}`,
          },
        ]
      )

      if (this.projectVersion !== 0) {
        if (this.barcodeType) {
          const item = this.barcodeTypeDetail
          if (!isEmpty(item)) {
            const customFields = item.activationFields.customFields
            for (const key in customFields) {
              if (
                customFields[key].fieldType !== FIELD_TYPE.ESIGN &&
                customFields[key].fieldType !== FIELD_TYPE.FILES
              ) {
                options.push({
                  value: `customFields.${key}`,
                  label: `[${this.$t('record_item_setting')} ]  ${customFields[key].label}`,
                })
              }
            }
          }
        } else {
          for (const keyBC in this.project.barcodeTypes) {
            const item = this.project.barcodeTypes[keyBC]
            if (!isEmpty(item)) {
              const customFields = item.activationFields.customFields
              for (const key in customFields) {
                if (
                  customFields[key].fieldType !== FIELD_TYPE.ESIGN &&
                  customFields[key].fieldType !== FIELD_TYPE.FILES
                ) {
                  options.push({
                    value: `customFields.${key}`,
                    label: `[${this.$t('record_item_setting')} ]  ${customFields[key].label}`,
                  })
                }
              }
            }
          }
        }
      }
    }
    return options
  }

  get barcodeTypeDetail(): IBarcodeDefinitionType {
    return this.project.barcodeTypes?.[this.barcodeType || ''] || ({} as IBarcodeDefinitionType)
  }

  showComfirmPopup() {
    this.isShowExportPDFDialog = true
  }

  calculateItemLayout() {
    this.numberItemLayout = BARCODE_IMPORT_NUMBER_ITEM_PDF_EXPORT[this.barcodeImportLayoutValue]
    if (BARCODE_IMPORT_PDF_MULTI_LINE_LAYOUT.includes(this.barcodeImportLayoutValue)) {
      this.barcodeImportShowLabel = [
        [BARCODE_IMPORT_PDF_LABELS.trackingNumber, '', '', '', '', '', '', ''],
        [BARCODE_IMPORT_PDF_LABELS.trackingNumber, '', ''],
      ][this.barcodeImportLayoutValue]
    } else {
      this.barcodeImportShowLabel = [BARCODE_IMPORT_PDF_LABELS.trackingNumber]
    }
  }

  formatBarcodeType(barcodeTypeDefinition: IBarcodeDefinitionType): IBarcodeDefinitionType {
    return barcodeTypeDefinition as IBarcodeDefinitionType
  }

  exportPDFDone() {
    this.loading = false
    this.isTriggerExportPDF = false
    this.$emit('done:exportPDF')
  }

  async exportPDF() {
    try {
      this.loading = true
      if (this.barcodeImportLayoutValue === BARCODE_IMPORT_ONLINE_TRAVEL_LAYOUT_PDF_EXPORT.onlineTravel) {
        await generateOnlineTravelLayout(this.barcodes, this.selectedBackGroundColor, this.exportFilename)
      } else {
        this.isShowExportPDFDialog = false
        setTimeout(() => (this.isTriggerExportPDF = true), 150)
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  get exportFilename() {
    return `barcode-group-${displayMomentAtParam(moment())}.pdf`
  }

  async created() {
    this.barcodeImportLayoutValue = BARCODE_IMPORT_LAYOUT_PDF_EXPORT.a446

    this.calculateItemLayout()
  }
}
</script>

<style lang="scss" scoped>
:deep() {
  .el-select {
    &--medium {
      width: 200px;
    }
  }

  &.el-overlay {
    .el-dialog {
      &.el-dialog--custom {
        max-width: 630px !important;
      }
    }
  }
}
</style>
