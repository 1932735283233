import { IExportPaginationParams } from 'smartbarcode-web-core/src/utils/types'
import { axiosInstance } from '@/utils/api'

export async function getAccessLog(targetMonth: string /* yyyy-MM */) {
  const response = await axiosInstance.post('/system/log-export', { targetMonth })
  return response?.data
}

export async function getAccessLogOutput(dataSearch: IExportPaginationParams) {
  const response = await axiosInstance.get('/system/log-exports', { params: dataSearch })
  return response?.data
}
