import { ITimetableUpdateNode } from 'smartbarcode-web-core/src/utils/types'
import { axiosInstance } from '@/utils/api'

export async function uploadTimetableCsv(payload: FormData, uploadProgressCallback: Function) {
  const response = await axiosInstance.post('/timetable/timetable', payload, {
    onUploadProgress: (event: ProgressEvent) => uploadProgressCallback(event),
  })
  return response?.data
}

export async function getTimetable(date: string) {
  const response = await axiosInstance.get('/timetable/timetable', {
    params: { date },
  })

  return response?.data
}

export async function getTimetableSchedule(timetableId: string) {
  const response = await axiosInstance.get('/timetable/schedule', {
    params: {
      timetableId,
    },
  })

  return response?.data
}

export async function acknowledgeTimetable(payload: ITimetableUpdateNode) {
  const response = await axiosInstance.post('timetable/ack', payload)
  return response?.data
}

export async function updateTimeTable(payload: ITimetableUpdateNode) {
  const response = await axiosInstance.put('/timetable/time', payload)

  return response?.data
}

export async function deleteTimeTable(id: string) {
  const response = await axiosInstance.delete('/timetable/timetable', {
    data: {
      timetableId: id,
    },
  })

  return response?.data
}
