import i18n from '@/i18n'
import { openMessage } from '@/utils/utils'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { FIELD_TYPE } from 'smartbarcode-web-core/src/utils/constants'
import {
  ECustomFieldType,
  EDisplayRestrictionOption,
  EDisplaySetting,
  EFileKind,
  ERestrictionOption,
} from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IActivationCustomField,
  IActivationFields,
  IBarcodeDefinitionType,
  IBarcodeList,
  IBarcodeSearchConditionItem,
  IBarcodeSearchForm,
  IBarcodeSearchFormItem,
  IBindingSetting,
  IClient,
  ICreateUserGroupForm,
  ICustomFieldCustomValidation,
  ICustomFieldFormData,
  ICustomNotificationEvent,
  ICustomValidator,
  IFieldOption,
  IFieldRestriction,
  IMappingLabel,
  IProjectCustomField,
  IProjectDetailMainInfo,
  ISearchConditionBlock,
  ISmaPriTemplateConfig,
  IValidation,
} from 'smartbarcode-web-core/src/utils/types/index'
import { EBindingSetting } from 'smartbarcode-web-core/src/utils/types/tradeWaltzTypes/index'
import {
  BARCODE_SEARCH_CUSTOM_FIELD_PATHS,
  BARCODE_SEARCH_CUSTOM_FIELD_PREFIX,
  BARCODE_SEARCH_KEYS,
  BARCODE_SEARCH_TRACKING_DATA_CUSTOM_FIELD_PREFIX,
  BARCODE_TRACKING_SEARCH_KEYS,
  CUSTOM_FIELD_ICONS,
  LOCAL_STORAGE_ITEM,
  LOGICAL_OPERATOR_VALUE,
  PROJECT_NEW_KEY_ID,
  TABLE_SORT_ASC,
  TABLE_SORT_DESC,
  TIMETABLE_DATETIME,
} from './constants'
import errorHandler from './errorHandler'
import { getCurrentUtcOffset } from './timeUtils'
import { PDFDocument } from 'pdf-lib'
import { renderQrcode } from './exportPdf'

const rangeKeys = [
  BARCODE_SEARCH_KEYS.createdDate,
  BARCODE_SEARCH_KEYS.updatedDate,
  BARCODE_SEARCH_KEYS.activatedDateTime,
  BARCODE_SEARCH_KEYS.lastTrackingDateTime,
  BARCODE_SEARCH_KEYS.dimension,
  `${BARCODE_SEARCH_KEYS.trackingData}.${BARCODE_TRACKING_SEARCH_KEYS.trackedDateTime}`,
]
const hasSubKeys = [
  BARCODE_SEARCH_KEYS.origin,
  BARCODE_SEARCH_KEYS.destination,
  BARCODE_SEARCH_KEYS.dimension,
  BARCODE_SEARCH_KEYS.customField,
  BARCODE_SEARCH_KEYS.trackingData,
]

export function generateNewKey(data: Record<string, unknown>, prefix: string, plus = 0): string {
  const index = Object.keys(data).length + plus
  const key = prefix + index
  return data[key] ? generateNewKey(data, prefix, plus + 1) : key
}

export function randomString(length = 8): string {
  return Math.random()
    .toString(36)
    .substring(2, length + 2)
}

export function removeCharacterPhoneNumber(value: string): string {
  return value
    .replaceAll('-', '')
    .replaceAll('(', '')
    .replaceAll(')', '')
    .trim()
}
/* eslint-disable @typescript-eslint/no-explicit-any */
export function deleteByPath(obj: any, path: string) {
  let currentObject = obj
  const parts = path.split('.')
  const last = parts.pop()
  for (const part of parts) {
    currentObject = currentObject[part]
    if (!currentObject) return
  }

  if (last) delete currentObject[last]
}

export function isNumber(value: string) {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(value)
}

export function displayRestrictionRule(value: EDisplayRestrictionOption): IFieldRestriction {
  return {
    isMandatory: value === EDisplayRestrictionOption.MANDATORY,
    isAvailable: value === EDisplayRestrictionOption.MANDATORY || value === EDisplayRestrictionOption.OPTIONAL,
  }
}

export function restrictionRule(value: string): IFieldRestriction {
  return {
    isMandatory: value === ERestrictionOption.MANDATORY,
    isAvailable: value === ERestrictionOption.AVAILABLE || value === ERestrictionOption.MANDATORY,
  }
}

export function fieldRestriction2String(f: IFieldRestriction): ERestrictionOption {
  if (f) {
    if (f.isMandatory && f.isAvailable) {
      return ERestrictionOption.MANDATORY
    } else if (f.isAvailable) {
      return ERestrictionOption.AVAILABLE
    }
  }

  return ERestrictionOption.UNAVAILABLE
}

export function fieldDisplayRestriction2String(f: IFieldRestriction): EDisplayRestrictionOption {
  if (f) {
    if (f.isMandatory && f.isAvailable) {
      return EDisplayRestrictionOption.MANDATORY
    } else if (f.isAvailable) {
      return EDisplayRestrictionOption.OPTIONAL
    }
  }

  return EDisplayRestrictionOption.HIDDEN
}

export function excludeNonActivationCustomFieldKeys(customField: IActivationCustomField) {
  const commonKeys = ['copyOnRecycle', 'fieldType', 'isMandatory', 'order', 'label', 'default', 'isHidden']
  const availableKeys: Record<string, string[]> = {
    [ECustomFieldType.TEXT]: [
      'minLength',
      'maxLength',
      'multiLine',
      'ocrReaderType',
      'barcodeReaderType',
      'ocrImageAutoSaveField',
    ],
    [ECustomFieldType.SINGLE_SELECT]: ['selections'],
    [ECustomFieldType.MULTI_SELECT]: ['selections'],
    [ECustomFieldType.DATE]: [],
    [ECustomFieldType.ESIGN]: [],
    [ECustomFieldType.NUMBER]: [
      'precision',
      'minValue',
      'maxValue',
      'ocrReaderType',
      'barcodeReaderType',
      'ocrImageAutoSaveField',
    ],
    [ECustomFieldType.FILES]: ['fileKind', 'overlayImage'],
    [ECustomFieldType.PHONE_NUMBER]: ['countryCode'],
    [ECustomFieldType.EMAIL]: [],
    [ECustomFieldType.REFERENCE]: [
      'minLength',
      'maxLength',
      'multiLine',
      'template',
      'ocrReaderType',
      'customParams',
      'customHeaders',
      'barcodeReaderType',
      'ocrImageAutoSaveField',
    ],
    [ECustomFieldType.GHG_EMISSION]: [],
    [ECustomFieldType.CALCULATION]: ['template'],
  }
  const keys = [...commonKeys, ...(availableKeys[customField.fieldType] ?? [])]
  Object.entries(customField)
    .filter((i) => !keys.includes(i[0]))
    .forEach((i) => delete customField[i[0] as keyof IActivationCustomField])
}

export function getInitClientDetailState(): IClient {
  return {
    isEdit: false,
    clientName: '',
    address: {
      country: '',
      postalCode: '',
      prefecture: '',
      address1: '',
      address2: '',
      phoneNumber: '',
      companyName: '',
    },
    clientUser: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      phone: {
        number: '',
        countryCode: 'JP',
      },
      visibleProjectCodes: [],
      roleType: '',
    },
    isActive: true,
  }
}

export function getInitFieldRestriction(): IFieldRestriction {
  return { isMandatory: false, isAvailable: true }
}

export function initDataSearch(): IBarcodeSearchForm {
  const utcOffset = getCurrentUtcOffset()
  return {
    projectCode: '',
    version: 0,
    utcOffset,
    searchConditionBlocks: [
      {
        searchConditions: [
          {
            key: '_id',
            valueType: 'string',
            value: '',
          },
        ] as IBarcodeSearchFormItem[],
        logicalOperator: LOGICAL_OPERATOR_VALUE.and,
      },
    ],
    logicalOperator: LOGICAL_OPERATOR_VALUE.and,
    count: 10,
    skip: 0,
    isArchived: false,
    sortKey: 'audit.createdDateTime',
    sortOrder: -1,
  }
}

export function formatSearchFieldConditions(item: IBarcodeSearchFormItem): IBarcodeSearchFormItem {
  const isExactSearchKeys = [BARCODE_SEARCH_KEYS.id, BARCODE_SEARCH_KEYS.currentTrackpoint]

  try {
    if (rangeKeys.includes(item.key) || rangeKeys.includes(`${item.key}.${item.subKey}`)) {
      delete item.value
      delete item.valueList
    } else if (
      (item.key === BARCODE_SEARCH_KEYS.customField || item.key === BARCODE_SEARCH_KEYS.trackingData) &&
      item.componentType === FIELD_TYPE.MULTI_SELECT
    ) {
      delete item.value
      delete item.minValue
      delete item.maxValue
    } else {
      delete item.minValue
      delete item.maxValue
      delete item.valueList
    }

    if (!hasSubKeys.includes(item.key)) {
      delete item.subKey
    }

    if (isExactSearchKeys.includes(item.key)) {
      item.isExactValue = true
    }
  } catch (ex) {}
  return item
}

export function formatSearchConditions(
  conditions: IBarcodeSearchFormItem[],
  projectId: string
): IBarcodeSearchConditionItem[] {
  const result = [] as IBarcodeSearchConditionItem[]
  try {
    if (projectId) {
      result.push({
        key: 'projectId',
        value: projectId,
        valueType: 'string',
        isExactValue: true,
      } as IBarcodeSearchConditionItem)
    }

    for (const item of conditions) {
      const formatItem = formatSearchFieldConditions(item)
      if (rangeKeys.includes(formatItem.key)) {
        if (formatItem.valueType === 'date') {
          if (formatItem.minValue) {
            formatItem.minValue = moment(item.minValue)
              .local()
              .format(TIMETABLE_DATETIME.DATE_RESPONSE_FORMAT) // Add 1 days when send to server to display
          }
          if (formatItem.maxValue) {
            formatItem.maxValue = moment(formatItem.maxValue)
              .add(1, 'days')
              .format(TIMETABLE_DATETIME.DATE_RESPONSE_FORMAT)
          }
        }
      } else {
        if (formatItem.valueType === 'date') {
          if (formatItem.value) {
            formatItem.minValue = moment(formatItem.value[0])
              .local()
              .format(TIMETABLE_DATETIME.DATE_RESPONSE_FORMAT)
            formatItem.maxValue = moment(formatItem.value[1])
              .add(1, 'days')
              .format(TIMETABLE_DATETIME.DATE_RESPONSE_FORMAT)
            const fullKey = `${formatItem.key}.${formatItem.subKey}`
            if (
              formatItem.key.indexOf(BARCODE_SEARCH_CUSTOM_FIELD_PREFIX) === 0 ||
              fullKey.indexOf(BARCODE_SEARCH_TRACKING_DATA_CUSTOM_FIELD_PREFIX) === 0
            ) {
              formatItem.valueType = 'dateString'
            }
            delete formatItem.value
          }
        }
      }
      const { subKey, ...restItem } = formatItem

      if (hasSubKeys.includes(formatItem.key) && subKey) {
        restItem.key = `${restItem.key}.${subKey}`
        if (
          (formatItem.key === BARCODE_SEARCH_CUSTOM_FIELD_PREFIX ||
            restItem.key.toString().includes(BARCODE_SEARCH_TRACKING_DATA_CUSTOM_FIELD_PREFIX)) &&
          formatItem.componentType &&
          BARCODE_SEARCH_CUSTOM_FIELD_PATHS[formatItem.componentType]
        ) {
          restItem.key += `.${BARCODE_SEARCH_CUSTOM_FIELD_PATHS[formatItem.componentType]}`
        }
      }

      delete restItem.bcTypeKey
      delete restItem.componentType

      if (!isEmpty(restItem.key)) result.push(restItem as IBarcodeSearchConditionItem)
    }
  } catch (ex) {}

  return result
}

export function formatSearchConditionBlocks(conditionBlocks: ISearchConditionBlock[]): ISearchConditionBlock[] {
  const result = [] as ISearchConditionBlock[]
  conditionBlocks.forEach((block, blockIdx) => {
    const conditions = block.searchConditions

    try {
      if (!result[blockIdx]) {
        result[blockIdx] = {
          searchConditions: [],
        }
      }

      if (block.logicalOperator) {
        result[blockIdx].logicalOperator = block.logicalOperator
      }

      for (const item of conditions) {
        const formatItem = formatSearchFieldConditions(item)

        if (rangeKeys.includes(formatItem.key) || rangeKeys.includes(`${formatItem.key}.${formatItem.subKey}`)) {
          if (formatItem.valueType === 'date') {
            if (formatItem.minValue) {
              formatItem.minValue = moment(item.minValue)
                .local()
                .format(TIMETABLE_DATETIME.DATE_RESPONSE_FORMAT)
            }
            if (formatItem.maxValue) {
              formatItem.maxValue = moment(formatItem.maxValue)
                .add(1, 'days') // Add 1 days when send to server to display
                .format(TIMETABLE_DATETIME.DATE_RESPONSE_FORMAT)
            }
          }
        } else {
          if (formatItem.valueType === 'date') {
            if (formatItem.value) {
              formatItem.minValue = moment(formatItem.value[0])
                .local()
                .format(TIMETABLE_DATETIME.DATE_RESPONSE_FORMAT)
              formatItem.maxValue = moment(formatItem.value[1]).format(TIMETABLE_DATETIME.DATE_RESPONSE_FORMAT)
              const fullKey = `${formatItem.key}.${formatItem.subKey}`
              if (
                formatItem.key.indexOf(BARCODE_SEARCH_CUSTOM_FIELD_PREFIX) === 0 ||
                fullKey.toString().includes(BARCODE_SEARCH_TRACKING_DATA_CUSTOM_FIELD_PREFIX)
              ) {
                formatItem.valueType = 'dateString'
              }
              delete formatItem.value
            }
          }
        }
        const { subKey, ...restItem } = formatItem
        if (hasSubKeys.includes(formatItem.key) && subKey) {
          restItem.key = `${restItem.key}.${subKey}`
          if (
            (formatItem.key === BARCODE_SEARCH_CUSTOM_FIELD_PREFIX ||
              restItem.key.toString().includes(BARCODE_SEARCH_TRACKING_DATA_CUSTOM_FIELD_PREFIX)) &&
            formatItem.componentType &&
            BARCODE_SEARCH_CUSTOM_FIELD_PATHS[formatItem.componentType]
          ) {
            restItem.key += `.${BARCODE_SEARCH_CUSTOM_FIELD_PATHS[formatItem.componentType]}`
          }
        }

        delete restItem.bcTypeKey
        delete restItem.componentType

        if (!isEmpty(restItem.key)) result[blockIdx].searchConditions.push(restItem as IBarcodeSearchConditionItem)
      }
    } catch (ex) {
      console.log('error', ex)
    }
  })

  return result
}

export function copyClipboard(value: string, message: string) {
  const el = document.createElement('textarea')
  el.value = value
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  openMessage(message, 'success')
}

export function showErrorMessage(error: string, t: Function, te: Function) {
  let message = ''
  if (te(`errors.${error}`)) {
    message = t(`errors.${error}`)
  }
  openMessage(message, 'error')
}

export function getFieldTemplate(key: string, defaultTemplate: Record<string, string>) {
  const t = i18n.global.t
  switch (key) {
    case 'trackingPermissions':
      return { field: key, val1: t('projects.trackingPoint') }
    default:
      return defaultTemplate
  }
}

export function errorHandle(e: Record<string, unknown>, payload: Record<string, unknown>, isShowDialog: boolean) {
  try {
    const t = i18n.global.t
    const te = i18n.global.te
    let errorCode: string[] = []
    let errorKey = ''
    const errorEntries = Object.entries(e)
    const errorMessage: string[] = []
    errorEntries.forEach((element) => {
      let errorLabel = JSON.parse(JSON.stringify(payload))
      const path = element[0].split('.')
      if (typeof element[1] !== 'string') {
        errorKey = element[0]
        errorCode = element[1] as string[]
      }
      if (path.length > 1) {
        path.forEach((val) => {
          if (val.includes('[')) {
            const splited = val.split('[')
            const key = splited[0]
            const index = splited[1].split(']')[0]
            errorLabel = Object.values(errorLabel?.[key] || {})?.[parseInt(index)]
          } else {
            // for special cases when want to get name value instead of object key
            if (val === 'trackPointForms') {
              errorLabel = `${errorLabel.name} ${t('projects.nextTrackingPoint')}`
            } else {
              errorLabel = errorLabel[val] || val
            }
          }
        })
      } else {
        if (errorLabel[path[0]]) {
          errorLabel = errorLabel[path[0]]
        }
      }
      if (typeof errorLabel === 'string') {
        errorMessage.push(errorLabel)
      }
    })

    if (typeof errorCode === 'object') {
      if (e.message) {
        openMessage(t(`errors.${e.message}`), 'error')
      }
      if (!e.message) {
        errorCode.forEach((element) => {
          const splited = element.split('(')
          const key = splited[0]
          let rule = splited[0]
          if (splited.length > 1) {
            rule = splited[1].split(')')[0]
          }
          if (te(`errors.${key}`)) {
            const message = errorMessage.join(', ')
            if (isShowDialog) {
              errorHandler(
                t(
                  `errors.${key}`,
                  getFieldTemplate(errorKey, { field: !isEmpty(message) ? message : errorKey, val1: rule })
                ),
                {},
                true
              )
            } else {
              openMessage(
                t(`errors.${key}`, {
                  field: message.length > 10 ? message?.slice(0, 10).concat('...') : message,
                  val1: rule,
                }),
                'error'
              )
            }
          } else {
            openMessage(key, 'error')
          }
        })
      }
    } else {
      throw e
    }
  } catch (err) {
    openMessage((err as Error).toString(), 'error')
  }
}

export async function getVisitorId() {
  let visitorId = ''
  visitorId = localStorage.getItem(LOCAL_STORAGE_ITEM.VISITOR_ID) || ''
  if (visitorId) {
    return visitorId
  } else {
    visitorId = (await FingerprintJS.load().then((fp) => fp.get())).visitorId
    localStorage.setItem(LOCAL_STORAGE_ITEM.VISITOR_ID, visitorId)
    return visitorId
  }
}

export function getTradeWaltzStoreBindingSetting(): {
  reqPOBindingSettings: IBindingSetting
  reqBookingBindingSettings: IBindingSetting
  storeBLBindingSettings: IBindingSetting
} {
  const defaultBindingSettings = ({
    reqPOBindingSettings: null,
    reqBookingBindingSettings: null,
    storeBLBindingSettings: null,
  } as unknown) as {
    reqPOBindingSettings: IBindingSetting
    reqBookingBindingSettings: IBindingSetting
    storeBLBindingSettings: IBindingSetting
  }

  let localStorageBidingData = ''
  localStorageBidingData = localStorage.getItem(LOCAL_STORAGE_ITEM.TRADEWALTZ_BINDING_SETTING) || ''
  if (localStorageBidingData) {
    return JSON.parse(localStorageBidingData)
  } else {
    localStorage.setItem(LOCAL_STORAGE_ITEM.TRADEWALTZ_BINDING_SETTING, JSON.stringify(defaultBindingSettings))
    return JSON.parse(localStorageBidingData)
  }
}

export function setTradeWaltzStoreBindingSetting(data: {
  reqPOBindingSettings: IBindingSetting
  reqBookingBindingSettings: IBindingSetting
  storeBLBindingSettings: IBindingSetting
}) {
  localStorage.setItem(LOCAL_STORAGE_ITEM.TRADEWALTZ_BINDING_SETTING, JSON.stringify(data))
}

export function formatBarcodeType(data: IBarcodeDefinitionType) {
  const activationFields = data.activationFields

  const keyUpdateRecycle = ['origin', 'destination', 'trackingNumber', 'dimension', 'externalId', 'product'] as Array<
    keyof IActivationFields
  >
  keyUpdateRecycle.forEach((item) => {
    const dataActivationField = activationFields[item]
    data.activationFields = {
      [item]: {
        copyOnRecycle: dataActivationField?.copyOnRecycle || false,
        isMandatory: dataActivationField?.isMandatory || false,
      },
      ...data.activationFields,
    }
  })

  const keyUpdateAvailable = [] as Array<keyof IActivationFields>
  keyUpdateAvailable.forEach((item) => {
    const dataActivationField = activationFields[item] as {
      [index: string]: IProjectCustomField
    }

    if (dataActivationField) {
      for (const i in dataActivationField) {
        dataActivationField[i].isMandatory = dataActivationField[i]?.isMandatory || false
      }
    }
  })

  if (data.uiConfig) {
    data.uiConfig.qrCodeVisibility = data.uiConfig?.qrCodeVisibility
      ? data.uiConfig.qrCodeVisibility === EDisplaySetting.UNKNOWN
        ? EDisplaySetting.VISIBLE
        : data.uiConfig.qrCodeVisibility
      : EDisplaySetting.VISIBLE

    if (
      !data?.uiConfig?.previewAddTrackingVisiblity ||
      data?.uiConfig?.previewAddTrackingVisiblity === EDisplaySetting.UNKNOWN
    ) {
      data.uiConfig.previewAddTrackingVisiblity = EDisplaySetting.HIDDEN
    }
  } else {
    data.uiConfig = {
      trackpointFlowchartVisibility: EDisplaySetting.VISIBLE,
      tracingVisibility: EDisplaySetting.VISIBLE,
      parentBarcodeVisibility: EDisplaySetting.VISIBLE,
      childrenBarcodeVisibility: EDisplaySetting.VISIBLE,
      listViewTitleLabelField: '',
      qrCodeVisibility: EDisplaySetting.VISIBLE,
      previewAddTrackingVisiblity: EDisplaySetting.HIDDEN,
    }
  }
  if (!data.customValidators) {
    data.customValidators = [] as Array<ICustomValidator>
  }

  if (!data.customNotificationEvent) {
    data.customNotificationEvent = [] as ICustomNotificationEvent[]
  }

  if (!data.repeatedVisitingTrackPointAlertSettings) {
    data.repeatedVisitingTrackPointAlertSettings = {}
  }

  if (!data.smapriTemplateConfig) {
    data.smapriTemplateConfig = {} as ISmaPriTemplateConfig
  }

  return data
}

export function getStaticOptions(): IFieldOption[] {
  const options = [] as IFieldOption[]
  const t = i18n.global.t
  options.push(
    ...[
      { value: 'id', label: t('barcode_type.id') },
      {
        value: 'audit.createdDateTime',
        label: t('barcode_type.create_date_time'),
      },
      {
        value: 'audit.updatedDateTime',
        label: t('barcode_type.update_date_time'),
      },
      {
        value: 'activationData.trackingNumber',
        label: `[ ${t('barcode_type.activation')} ] ${t('projects.trackingNumber')}`,
      },
      {
        value: 'activationData.externalId',
        label: `[ ${t('barcode_type.activation')} ] ${t('barcode.externalId')}`,
      },
      {
        value: 'activationData.dimension.height',
        label: `[ ${t('barcode_type.activation')} ][ ${t('barcode.dimension')} ] ${t('height')}`,
      },
      {
        value: 'activationData.dimension.width',
        label: `[ ${t('barcode_type.activation')} ][ ${t('barcode.dimension')} ] ${t('width')}`,
      },
      {
        value: 'activationData.dimension.depth',
        label: `[ ${t('barcode_type.activation')} ][ ${t('barcode.dimension')} ] ${t('depth')}`,
      },
      {
        value: 'activationData.dimension.weight',
        label: `[ ${t('barcode_type.activation')} ][ ${t('barcode.dimension')} ] ${t('weight')}`,
      },
    ]
  )
  ;['origin', 'destination'].forEach((type) => {
    ;['companyName', 'country', 'postalCode', 'prefecture', 'address1', 'address2', 'phoneNumber'].forEach((field) => {
      options.push({
        value: `activationData.${type}.${field}`,
        label: `[ ${t('barcode_type.activation')} ][ ${t('barcode.' + type)} ] ${t(field)}`,
      })
    })
  })

  return options
}

export function getTWBLLinkageGoodsLineBindingOption(): IFieldOption[] {
  const options = [] as IFieldOption[]
  const t = i18n.global.t
  options.push(...[{ value: 'linkageTransportation.blGrp.blNo', label: t('B/L NO.') }])

  return options
}

export function getTWBookingLinkageGoodsLineBindingOption(): IFieldOption[] {
  const options = [] as IFieldOption[]
  const t = i18n.global.t
  options.push(
    ...[
      { value: `${EBindingSetting.LINKAGE_GOODS_LINE_BINDING}-referenceNoGrp.bookingNo`, label: t('BOOKING NO') },
      {
        value: `${EBindingSetting.LINKAGE_GOODS_LINE_BINDING}-referenceNoGrp.commercialInvoiceNo`,
        label: t('送り状番号'),
      },
    ]
  )

  return options
}

export function getTWBookingLinkageGoodsLinePackingBindingOption(): IFieldOption[] {
  const options = [] as IFieldOption[]
  const t = i18n.global.t
  options.push(
    ...[
      {
        value: `${EBindingSetting.LINKAGE_GOODS_LINE_PACKING_BINDING}-netGrossMeasurementGrp.containerNo`,
        label: t('コンテナ番号'),
      },
    ]
  )

  return options
}

export function getTWBookingBindingOption(): IFieldOption[] {
  const options = [] as IFieldOption[]
  const t = i18n.global.t
  options.push(
    ...[
      { value: 'linkageTransportation.logisticsLoadingGrp.portOfLoadingName', label: t('積込港') },
      { value: 'linkageTransportation.logisticsLoadingGrp.ladenOnBoardTheVessel', label: t('本船積込日') },
      { value: 'linkageTransportation.logisticsDeliveryGrp.placeOfDelivery', label: t('荷渡地（名称）') },
      { value: 'linkageTransportation.logisticsDeliveryGrp.placeOfDeliveryEta', label: t('荷渡地到着予定日') },
      { value: 'linkageTransportation.logisticsDeliveryGrp.finalDestinationName', label: t('最終仕向地') },
      { value: 'linkageTransportation.logisticsDischargingGrp.portOfDischargingName', label: t('仕向港') },
      { value: 'linkageTransportation.logisticsLoadingGrp.nameOfVessel', label: t('本船名') },
      { value: 'linkageTransportation.blRequestGrp.grossWeight', label: t('貨物重量（グロス)') },
      { value: 'linkageTransportation.carrierGrp.carrierName', label: t('キャリアName') },
      { value: 'linkageTransportation.notify1Grp.notify1Name', label: t('通知先名称') },
      { value: 'processSeparate.estimateFareGrp.estimateQuotationOfTotalFreight', label: t('コンテナ番号') },
    ]
  )

  return options
}

export function getTWPOLinkageGoodsLineBindingOption(): IFieldOption[] {
  const options = [] as IFieldOption[]
  const t = i18n.global.t
  options.push(
    ...[
      { value: `${EBindingSetting.LINKAGE_GOODS_LINE_BINDING}-referenceNoGrp.poNo`, label: t('注文No') },
      { value: `${EBindingSetting.LINKAGE_GOODS_LINE_BINDING}-goodsGrp.goodsNameEn`, label: t('商品名') },
      { value: `${EBindingSetting.LINKAGE_GOODS_LINE_BINDING}-goodsGrp.countryOfOriginName`, label: t('原産地名') },
    ]
  )

  return options
}

export function getTWPOLinkageGoodsLinePackingBindingOption(): IFieldOption[] {
  const options = [] as IFieldOption[]
  const t = i18n.global.t
  options.push(
    ...[
      {
        value: `${EBindingSetting.LINKAGE_GOODS_LINE_PACKING_BINDING}-netGrossMeasurementGrp.netTotal`,
        label: t('NET重量'),
      },
      {
        value: `${EBindingSetting.LINKAGE_GOODS_LINE_PACKING_BINDING}-netGrossMeasurementGrp.grossTotal`,
        label: t('GROSS重量'),
      },
      {
        value: `${EBindingSetting.LINKAGE_GOODS_LINE_PACKING_BINDING}-netGrossMeasurementGrp.netLength`,
        label: t('長さ'),
      },
      {
        value: `${EBindingSetting.LINKAGE_GOODS_LINE_PACKING_BINDING}-netGrossMeasurementGrp.netWidth`,
        label: t('幅'),
      },
      {
        value: `${EBindingSetting.LINKAGE_GOODS_LINE_PACKING_BINDING}-netGrossMeasurementGrp.netHeight`,
        label: t('高さ'),
      },
      {
        value: `${EBindingSetting.LINKAGE_GOODS_LINE_PACKING_BINDING}-netGrossMeasurementGrp.numberOfContents`,
        label: t('入数'),
      },
    ]
  )

  return options
}

export function getTWPOBindingOption(): IFieldOption[] {
  const options = [] as IFieldOption[]
  const t = i18n.global.t
  options.push(
    ...[
      { value: 'linkageContract.poNoDateGrp.poIssuingDate', label: t('注文日') },
      { value: 'linkageContract.poNoDateGrp.poReceivedDate', label: t('受注日') },
      { value: 'linkageContract.endUserGrp.endUserName', label: t('最終需要家（名称）') },
      {
        value: 'linkageContract.requestedCargoReadyTimeGrp.requestedCargoReadyTimeFrom',
        label: t('希望納期（開始時期）'),
      },
      { value: 'linkageContract.requestedCargoReadyTimeGrp.requestedCargoReadyTimeTo', label: t('希望納期（期限）') },
      { value: 'linkageContract.paymentTermsGrp.paymentTerms1', label: t('決済条件') },
      { value: 'from.fromCompanyName', label: t('依頼元会社名') },
      { value: 'linkageTransportation.transferTermsGrp.transportation', label: t('輸送手段') },
      { value: 'linkageTransportation.transferTermsGrp.requestedPortOfDischarging', label: t('予定仕向港') },
      { value: 'linkageTransportation.packingTermsGrp.packingTypeName', label: t('荷姿名') },
    ]
  )

  return options
}

export function getTWPOLinkageGoodsLinePriceOption(): IFieldOption[] {
  const options = [] as IFieldOption[]
  const t = i18n.global.t
  options.push(
    ...[
      { value: `${EBindingSetting.LINKAGE_GOODS_LINE_PRICE_BINDING}-priceGrp.unit`, label: t('単位') },
      { value: `${EBindingSetting.LINKAGE_GOODS_LINE_PRICE_BINDING}-priceGrp.price`, label: t('単価') },
      { value: `${EBindingSetting.LINKAGE_GOODS_LINE_PRICE_BINDING}-priceGrp.currency`, label: t('通過') },
      { value: `${EBindingSetting.LINKAGE_GOODS_LINE_PRICE_BINDING}-amountGrp.amount`, label: t('合計') },
    ]
  )
  return options
}

export function getBarcodeImportStaticHeader(): IFieldOption[] {
  const options = [] as IFieldOption[]
  const t = i18n.global.t
  options.push(
    ...[
      {
        value: 'trackingNumber',
        label: `[ ${t('barcode_type.activation')} ] ${t('projects.trackingNumber')}`,
      },
      {
        value: 'externalId',
        label: `[ ${t('barcode_type.activation')} ] ${t('barcode.externalId')}`,
      },
      {
        value: 'height',
        label: `[ ${t('barcode_type.activation')} ][ ${t('barcode.dimension')} ] ${t('height')}`,
      },
      {
        value: 'width',
        label: `[ ${t('barcode_type.activation')} ][ ${t('barcode.dimension')} ] ${t('width')}`,
      },
      {
        value: 'depth',
        label: `[ ${t('barcode_type.activation')} ][ ${t('barcode.dimension')} ] ${t('depth')}`,
      },
      {
        value: 'weight',
        label: `[ ${t('barcode_type.activation')} ][ ${t('barcode.dimension')} ] ${t('weight')}`,
      },
    ]
  )
  ;['origin', 'destination'].forEach((type) => {
    ;['companyName', 'country', 'postalCode', 'prefecture', 'address1', 'address2', 'phoneNumber'].forEach((field) => {
      options.push({
        value: `${type}.${field}`,
        label: `[ ${t('barcode_type.activation')} ][ ${t('barcode.' + type)} ] ${t(field)}`,
      })
    })
  })

  return options
}

export function getCustomFieldOptions(params: {
  fields: ICustomFieldFormData[]
  excludeFieldType: string[]
  barcodeName?: string
  replaceLabelKeys?: IMappingLabel[]
}): IFieldOption[] {
  const getKey = (mappingKey: string): string =>
    (params.replaceLabelKeys ?? []).find((i) => i.from === mappingKey)?.to ?? mappingKey
  const options = [] as IFieldOption[]
  const t = i18n.global.t
  params.fields.forEach((field) => {
    if (!params.excludeFieldType.includes(field.fieldType)) {
      options.push({
        value: `activationData.customFields.${field.customFieldKey}`,
        label: `${params.barcodeName ? `[ ${params.barcodeName} ]` : ''}[ ${t(
          getKey('barcode_type.activation')
        )} ][ ${t('record_item_setting')} ]  ${field.label}`,
      })
    }
  })
  return options
}

export function getCustomFieldHeadersOptions(params: {
  fields: ICustomFieldFormData[]
  excludeFieldType: string[]
  barcodeName?: string
  replaceLabelKeys?: IMappingLabel[]
}): IFieldOption[] {
  const getKey = (mappingKey: string): string =>
    (params.replaceLabelKeys ?? []).find((i) => i.from === mappingKey)?.to ?? mappingKey
  const options = [] as IFieldOption[]
  const t = i18n.global.t
  params.fields.forEach((field) => {
    if (!params.excludeFieldType.includes(field.fieldType)) {
      options.push({
        value: `customFields.${field.customFieldKey}`,
        label: `${params.barcodeName ? `[ ${params.barcodeName} ]` : ''}[ ${t(
          getKey('barcode_type.activation')
        )} ][ ${t('record_item_setting')} ]  ${field.label}`,
      })
    }
  })
  return options
}

export function getTrackingCustomFieldOptions(
  fields: ICustomFieldCustomValidation[],
  excludeFieldType: string[]
): IFieldOption[] {
  const options = [] as IFieldOption[]
  const t = i18n.global.t

  fields.forEach((field) => {
    if (!excludeFieldType.includes(field.fieldType)) {
      options.push({
        value: `trackingData.0.customFields.${field.customFieldKey}`,
        label: `[ ${t('barcode_type.tracking')} ][ ${t('record_item_setting')} ] [ ${field.fromName} - ${
          field.toName
        } ] ${field.label}`,
      })
    }
  })

  return options
}

export function getTrackingCustomFieldOptionsWithoutIndex(params: {
  fields: ICustomFieldCustomValidation[]
  excludeFieldType: string[]
  replaceLabelKeys?: IMappingLabel[]
}): IFieldOption[] {
  const getKey = (mappingKey: string): string =>
    (params.replaceLabelKeys ?? []).find((i) => i.from === mappingKey)?.to ?? mappingKey
  const options = [] as IFieldOption[]
  const t = i18n.global.t

  params.fields.forEach((field) => {
    if (!params.excludeFieldType.includes(field.fieldType)) {
      options.push({
        value: `trackingData.customFields.${field.customFieldKey}`,
        label: `[ ${t(getKey('barcode_type.tracking'))} ][ ${t('record_item_setting')} ] [ ${field.fromName} - ${
          field.toName
        } ] ${field.label}`,
      })
    }
  })

  return options
}

export function reFormatCustomFieldOptions(data: IFieldOption[]): IFieldOption[] {
  return data.map((i) => ({ ...i, value: `{{${i.value}}}` }))
}

export function formatInitPermissionGroup(mainInfo: IProjectDetailMainInfo, permissionGroup: ICreateUserGroupForm[]) {
  const projectId = (mainInfo.isDraft ? mainInfo.targetProjectId : mainInfo.id) || ''
  const newProjectId = PROJECT_NEW_KEY_ID
  if (projectId) {
    permissionGroup.map((item) => {
      if (!mainInfo.isDraft && item.projectPermissions && item.projectPermissions[projectId]) {
        item.projectPermissions[newProjectId] = cloneDeep(item.projectPermissions[projectId])
      }
    })
  }
  return permissionGroup
}

export function isEqualPermissionGroup(permissionGroup: ICreateUserGroupForm[], oldDatas: ICreateUserGroupForm[]) {
  if (permissionGroup.length !== oldDatas.length) return false

  let result = true
  permissionGroup.forEach((item) => {
    const existOldItem = oldDatas.filter((oldData) => oldData.name === item.name)
    if (!existOldItem || existOldItem.length > 1) {
      result = false
      return false
    }
    if (!isEqual(item, existOldItem[0])) {
      result = false
      return false
    }
  })

  return result
}

export function runValidationPipe(validationPipe: IValidation[]) {
  for (const validation of validationPipe) {
    if (validation.isInvalid) {
      const executeResult = validation.exec()
      const result = typeof executeResult === 'object' ? !validation.isInvalid : executeResult
      return result
    }
  }

  return true
}

export function iconFileKindKey(fileKind?: EFileKind): string {
  switch (fileKind) {
    case EFileKind.IMAGE:
      return CUSTOM_FIELD_ICONS.IMAGES
    case EFileKind.PHOTO_ONLY:
      return CUSTOM_FIELD_ICONS.IMAGES
    case EFileKind.BARCODE_SCAN_FRAME:
      return CUSTOM_FIELD_ICONS.BARCODE_SCAN_FRAME
    default:
      return CUSTOM_FIELD_ICONS.FILES
  }
}

export function isImageFileField(field: IActivationCustomField | ICustomFieldFormData) {
  return field.fieldType === ECustomFieldType.FILES && field.fileKind === EFileKind.IMAGE
}

export function displayFileSize(filesize: number): string {
  if (isNaN(filesize)) return '0 B'
  if (filesize < 1024) return `${filesize} B`

  const units = ['KB', 'MB', 'GB', 'TB']
  let unitIndex = 0
  let fileSizeUnit = filesize / 1024

  while (fileSizeUnit >= 1024 && unitIndex < units.length - 1) {
    fileSizeUnit /= 1024
    unitIndex++
  }

  return `${fileSizeUnit.toFixed(2)} ${units[unitIndex]}`
}

export function buildSearchUrlQuery(data: IBarcodeSearchForm, page: number) {
  const queryData = {} as Record<string, string>
  const arrAllowKeys = ['projectCode', 'version', 'sortKey', 'logicalOperator']

  for (const key in data) {
    if (key === 'searchConditionBlocks') {
      data.searchConditionBlocks.forEach((block: ISearchConditionBlock, blockIdx: number) => {
        if (isEmpty(block.searchConditions) || block.searchConditions[0].key === '') return

        queryData[`logicalOperator_${key}_${blockIdx}`] = block.logicalOperator ?? LOGICAL_OPERATOR_VALUE.AND
        block.searchConditions.forEach((item: IBarcodeSearchFormItem, index: number) => {
          const searchFormat = formatSearchFieldConditions(item)
          const keySuffix = `_${index}_${blockIdx}`
          for (const searchKey in searchFormat) {
            const value = item[searchKey as keyof IBarcodeSearchFormItem] as string
            if (searchKey === 'valueList') {
              const arrValue = [...value] as string[]
              queryData[`${searchKey}${keySuffix}`] = arrValue.join(',')
            } else if (value !== undefined && value) {
              if (Array.isArray(value)) {
                queryData[`minValue${keySuffix}`] = value[0]
                queryData[`maxValue${keySuffix}`] = value[1]
              }
              queryData[`${searchKey}${keySuffix}`] = value
            }
          }
        })
      })
    } else if (key === 'sortOrder') {
      queryData[key] = data[key] === -1 ? TABLE_SORT_DESC : data[key] === 1 ? TABLE_SORT_ASC : ''
    } else if (arrAllowKeys.indexOf(key) > -1) {
      const value = data[key as keyof IBarcodeSearchForm] as string
      if (value !== '') {
        queryData[key] = value
      }
    }
  }

  if (page !== 1) {
    queryData.page = String(page)
  }
  for (const key in queryData) {
    if (!queryData[key]) {
      delete queryData[key]
    }
  }
  return queryData
}

export function saveArrayBuffetToFile(arrayBuffer: ArrayBuffer, fileName: string) {
  const blob = new Blob([new Uint8Array(arrayBuffer)], { type: 'application/pdf' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  a.click()
  a.remove()
}

export function dataUrlToArrayBuffer(dataUrl: string): ArrayBuffer {
  // Split the data URL to extract the Base64 part
  const base64String = dataUrl.split(',')[1]

  // Decode the Base64 string
  const binaryString = atob(base64String)

  // Convert the binary string to a Uint8Array
  const binaryLength = binaryString.length
  const bytes = new Uint8Array(binaryLength)
  for (let i = 0; i < binaryLength; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }

  // Return as an ArrayBuffer
  return bytes.buffer
}

export async function generateOnlineTravelLayout(
  barcodeList: IBarcodeList[],
  selectedBackGroundColor: string,
  exportFilename: string
) {
  // Load the PDF template
  const pdfTemplate = await fetch(
    'https://sbassets-all.s3.ap-northeast-1.amazonaws.com/sgw003_export_format.pdf'
  ).then((res) => res.arrayBuffer())
  const loadedTemplatePDF = await PDFDocument.load(pdfTemplate)
  const pdfDoc = await PDFDocument.create()

  const barcodes = cloneDeep(barcodeList)
  const looped = Math.ceil(barcodes.length / 4)
  for (let i = 0; i < looped; i++) {
    // Coppy 2 pages
    const coppiedPage = await pdfDoc.copyPages(loadedTemplatePDF, [0, 1])
    const barcodeImagePosition = [
      { x: 40, y: 11 },
      { x: 438, y: 11 },
    ]
    for await (const page of coppiedPage) {
      const chunkBarcode = barcodes.splice(0, 2)
      let i = 0
      for await (const barcode of chunkBarcode) {
        if (barcode?.activationData?.externalId) {
          const qrcode = await renderQrcode(
            barcode?.activationData?.externalId,
            selectedBackGroundColor,
            220,
            'image/png'
          )
          const image = await pdfDoc.embedPng(dataUrlToArrayBuffer(qrcode))
          page.drawImage(image, {
            x: barcodeImagePosition[i].x,
            y: barcodeImagePosition[i].y,
            width: image.width / 3,
            height: image.height / 3,
          })
          i++
        }
      }
      pdfDoc.addPage(page)
    }
  }
  const pdfBytes = await pdfDoc.save()
  saveArrayBuffetToFile(pdfBytes, exportFilename)
}
