import { ILocationPayload, ILocationPermissionPayload, IPaginationPayload } from 'smartbarcode-web-core/src/utils/types'
import { axiosInstance } from '@/utils/api'

export async function getLocationList(payload: IPaginationPayload & { isSummarize?: boolean }) {
  const response = await axiosInstance.get('/location/locations', { params: payload })
  return response?.data
}

export async function getLocation(locationId: string) {
  const response = await axiosInstance.get('/location/location', { params: { locationId } })
  return response?.data
}

export async function createLocation(location: ILocationPayload) {
  const response = await axiosInstance.post('/location/location', location)
  return response?.data
}
export async function updateLocation(location: ILocationPayload) {
  const response = await axiosInstance.put('/location/location', location)
  return response?.data
}

export async function getLocationPermissions(data: ILocationPermissionPayload) {
  const response = await axiosInstance.get('/location/location-projects', { params: { ...data } })
  return response?.data
}

export async function getProjectLocationsByProjectId(projectId: string) {
  const response = await axiosInstance.get('/location/project-locations', { params: { projectId } })
  return response?.data
}

export async function exportLocation(locationId: string) {
  const result = await axiosInstance.post('/location/location-export', { locationId })
  return result?.data
}

export async function getLocationExportList(payload: IPaginationPayload) {
  const result = await axiosInstance.get('/location/location-exports', { params: payload })
  return result?.data
}
