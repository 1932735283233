<template>
  <div class="border-b border-gray-400 border-solid py-8">
    <div class="grid grid-cols-5 my-5 gap-4">
      <div class="col-span-2 font-bold">
        {{ $t('barcode_type.delivery_custom_content') }}
      </div>
      <div class="col-span-3">
        <el-checkbox @change="onPostCustomContentChange($event)" v-model="isPostCustomContent">
          {{ $t('barcode_type.post_custom_content_on_tracked_barcode') }}
        </el-checkbox>
      </div>
    </div>
    <div class="grid grid-cols-5 my-5 gap-4">
      <div class="col-span-2"></div>
      <div class="col-span-3">
        <el-checkbox
          :disabled="!isPostCustomContent"
          v-model="isShowHistoryLink"
          @change="onShowTraceHistoryLinkChange($event)"
        >
          {{ $t('barcode_type.display_link_button') }}
        </el-checkbox>
      </div>
    </div>
    <div class="flex flex-row">
      <div>
        <RichTextEditor
          :isShowCustomFields="true"
          :disabled="!isPostCustomContent || isReadOnly"
          :showToolbar="true"
          v-model="templateHTML"
        />
      </div>

      <div class="flex flex-col mx-10">
        <div class="flex flex-row m-2">
          <div class="self-center font-bold mr-3">{{ $t('barcode_type.id') }}</div>
          <div><el-input v-model="previewBCId"></el-input></div>
        </div>

        <div class="m-2 pre-line hint-text-message">
          {{ $t('barcode_type.enter_barcode_id_and_display_custom_content') }}
        </div>
        <div class="my-2 preview-area">
          <div class="preview-area-l1">
            <div class="flex flex-col">
              <div class="rabbit-area-area self-center"></div>
            </div>
            <div class="preview-content">
              <img alt="SmartBarcode logo" src="@/assets/logo-smartBarcode.png" class="app-logo image-center" />
              <div v-html="mergedContent" class="custom-html"></div>
              <div class="text-center">
                <el-button v-if="false" class="tracing-history-button border-2 border-black my-12">
                  {{ $t('login') }}
                </el-button>
                <el-button class="tracing-history-button border-2 border-black my-12" v-if="isShowHistoryLink">
                  {{ $t('barcode_type.tracing_history') }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BarcodeTypesMixin from '@/components/mixins/BarcodeTypesMixin.vue'
import RichTextEditor from '@/components/project/RichTextEditor.vue'
import { compileMessage, getBarcode } from '@/utils/api'
import { DEFAULT_CUSTOM_CONTENT_TEMPLATE } from '@/utils/constants'
import { getCompiledBarcode } from 'smartbarcode-web-core/src/utils/barcodeHelper'
import {
  ITrackPointKeyVal,
  ICustomContentTemplate,
  IBarcode,
  IBarcodeDefinitionType,
} from 'smartbarcode-web-core/src/utils/types/index'
import { openMessage } from '@/utils/utils'
import debounce from 'lodash/debounce'
import { mixins, Options } from 'vue-class-component'
import { InjectReactive, Prop, PropSync, Watch } from 'vue-property-decorator'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'

export interface IPreview {
  barcodeId: string
}

@Options({
  components: { RichTextEditor },
  emits: ['update:modelValue'],
  name: 'TrackingNumberBlock',
})
export default class TrackingNumberBlock extends mixins(BarcodeTypesMixin) {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @PropSync('modelValue', { type: Array }) _modelValue?: ICustomContentTemplate[]
  @Prop({ type: String }) readonly currentBCTypeCode?: string
  @Prop({ type: Boolean }) readonly isReadOnly?: boolean

  previewBCId = ''
  isPostCustomContent = false
  isShowHistoryLink = false
  barcode: IBarcode | undefined = undefined
  mergedContent = ''

  bcTypesPreviewIds = {} as Record<string, IPreview>

  @Watch('_modelValue')
  onModelValueChanged() {
    if (this._modelValue) {
      this.isPostCustomContent = true
      this.isShowHistoryLink = this._modelValue[0].showTraceHistoryLink ?? false
    } else {
      this.isPostCustomContent = false
      this.isShowHistoryLink = false
    }
  }

  // fallback DEFAULT template in case the custom content is empty
  safeTemplate(value: string | undefined) {
    return !isEmpty(value) ? (value as string) : DEFAULT_CUSTOM_CONTENT_TEMPLATE
  }

  get templateHTML(): string {
    return this.safeTemplate(this._modelValue?.[0]?.templateHtml)
  }

  set templateHTML(newVal: string) {
    if (this._modelValue) {
      this._modelValue[0].templateHtml = this.safeTemplate(newVal)
    }
  }

  @Watch('currentBCTypeCode')
  onCurrentBCTypeCodeChange() {
    if (this.currentBCTypeCode) {
      if (this.bcTypesPreviewIds[this.currentBCTypeCode]) {
        this.previewBCId = this.bcTypesPreviewIds[this.currentBCTypeCode].barcodeId ?? ''
      } else {
        this.previewBCId = ''
      }
    }
  }

  @Watch('previewBCId')
  onBCIdChange() {
    this.contentChanged()
  }

  @Watch('templateHTML')
  contentChanged() {
    if (this.currentBCTypeCode) {
      this.bcTypesPreviewIds[this.currentBCTypeCode] = { barcodeId: this.previewBCId }
    }
    if (isEmpty(this.previewBCId)) {
      this.mergedContent = ''
    } else {
      this.debouncedWatch()
    }
  }

  get project() {
    return this.$store.state.project
  }

  get barcodeTypeInfo(): IBarcodeDefinitionType | undefined {
    return this.getBarcodeTypeInfo(this.barcode)
  }

  getBarcodeTypeInfo(barcode?: IBarcode): IBarcodeDefinitionType | undefined {
    if (!barcode) return undefined
    return this.currentBarcodeTypeInfo(barcode)
  }

  created() {
    this.debouncedWatch = debounce(() => {
      getBarcode(this.previewBCId).then((res) => {
        if (res.projectId !== this.project.projectDetail.mainInfo.id) {
          this.mergedContent = ''
          openMessage(this.$t('barcode_type.bc_project_id_not_match'), 'error')
          return
        }

        if (res.barcodeType !== this.currentBCTypeCode) {
          this.mergedContent = ''
          openMessage(this.$t('barcode_type.bc_barcode_type_not_match'), 'error')
          return
        }

        getCompiledBarcode((res as unknown) as IBarcode, this.project)

        compileMessage(this.previewBCId, this.templateHTML)
          .then((res) => (this.mergedContent = res))
          .catch((e) => openMessage(this.$t('errors.' + e.barcodeId), 'error'))
      })
    }, 500)
  }

  onPostCustomContentChange(checked: boolean) {
    this._modelValue = checked
      ? [
          {
            trackPointKeys: this.endTrackPointKey ? [this.endTrackPointKey] : ([] as string[]),
            templateHtml: DEFAULT_CUSTOM_CONTENT_TEMPLATE,
            showTraceHistoryLink: false,
          },
        ]
      : undefined
  }

  get endTrackPointKey(): string | undefined {
    return this.trackingPointDatasArr.find((o: ITrackPointKeyVal) => o?.value?.isEnd)?.key
  }

  onShowTraceHistoryLinkChange(checked: boolean) {
    if (this._modelValue) this._modelValue[0].showTraceHistoryLink = checked
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';
.preview-area {
  height: 800px;
  width: 425px;
  background-color: white;
  border-radius: 50px;
}
.rabbit-area-area {
  background-color: lightgrey;
  width: 180px;
  height: 30px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.preview-content {
  padding: 5px 8px 5px 8px;
  height: 720px;
  width: 380px;
  overflow-y: scroll;

  line-break: anywhere;
  overflow-y: auto;
  overflow-x: hidden;
}

.preview-area-l1 {
  margin: 5px;
  height: 781px;
  width: 402px;
  padding: 0px 5px 5px 5px;
  border-radius: 50px;
  border: 2px solid lightgrey;
  background-color: $body-bg;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

.app-logo {
  padding: 24px 0;
  height: 18px;
  align-self: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.custom-html {
  margin-top: 75px;
  white-space: pre-wrap;
  overflow-wrap: anywhere;

  :deep() {
    h1 {
      font-size: 32px;
      line-height: 1.2;
    }
    h2 {
      font-size: 24px;
      line-height: 1.2;
    }
    h3 {
      font-size: 18.72px;
      line-height: 1.2;
    }
    h4 {
      font-size: 16px;
      line-height: 1.2;
    }
    h5 {
      font-size: 13.28px;
      line-height: 1.2;
    }
    h6 {
      font-size: 10.72px;
      line-height: 1.2;
    }
    p {
      margin: 0px;
      line-height: 1.2;
    }
    img {
      max-width: 320px;
    }
    pre.ql-syntax {
      margin: 5px;
      padding: 8px;
      background-color: black;
      color: white;
      border-radius: 3px;
    }
    ul {
      margin-left: 20px;
      list-style-type: disc;
      list-style-position: inside;
      li {
        padding: 3px;
      }
    }
    ol {
      margin-left: 20px;
      list-style-type: decimal;
      list-style-position: inside;
      li {
        padding: 3px;
      }
    }
    s {
      text-decoration: line-through;
    }
    .ql-size-huge {
      font-size: 2em;
    }
    .ql-size-large {
      font-size: 1.5em;
    }
    .ql-size-small {
      font-size: 0.75em;
    }
    .ql-font-monospace {
      font-family: 'Monaco', 'Courier New', 'monospace';
    }
    .ql-font-serif {
      font-family: 'Georgia', 'Times New Roman', 'serif';
    }
    .ql-align-left {
      text-align: left;
    }
    .ql-align-center {
      text-align: center;
    }
    .ql-align-right {
      text-align: right;
    }
    .ql-align-justify {
      text-align: justify;
    }
    & strong {
      font-weight: bold;
    }
    & em {
      font-style: italic;
    }
    blockquote {
      background: #f9f9f9;
      border-left: 10px solid #ccc;
      margin: 1.5em 10px;
      padding: 0.5em 10px;
      quotes: '\201C''\201D''\2018''\2019';
    }
    blockquote:before {
      color: #ccc;
      content: open-quote;
      font-size: 4em;
      line-height: 0.1em;
      margin-right: 0.25em;
      vertical-align: -0.4em;
    }
    blockquote p {
      display: inline;
    }
  }
}

:deep() {
  .tracing-history-button {
    border-radius: 0px !important;
  }
}
</style>
