import {
  IAddress,
  ICarrierUserGroup,
  IClient,
  IClientUser,
  ICreateUserGroupForm,
  IEnterprise,
  IEnterpriseUser,
  ILoginPayload,
  INewPasswordPayload,
  IStaff,
  IUserGroupMergeData,
} from 'smartbarcode-web-core/src/utils/types'
import { axiosInstance, IClientSearchDataPayload } from '@/utils/api'
import { IImpersonateUserPayload, IVisitor } from '@/utils/apiPayloadInterface'
import { LOCAL_STORAGE_ITEM, PROJECT_NEW_KEY_ID } from '@/utils/constants'
import cloneDeep from 'lodash/cloneDeep'
import { getVisitorId } from '@/utils/helpers'

export async function login(payload: ILoginPayload) {
  payload.visitorId = await getVisitorId()
  return await axiosInstance.post('/user/login', payload)
}

export async function getCountries() {
  const response = await axiosInstance.get('/user/countries')
  return response?.data
}

export async function getUserGroups() {
  const response = await axiosInstance.get('/user/user-groups')
  return response?.data
}

export async function getUserGroupByProjectId(projectId: string) {
  const response = await axiosInstance.get('/user/user-groups', {
    params: {
      projectId,
    },
  })
  return response?.data
}

export async function getUserGroup(userGroupId: string) {
  const response = await axiosInstance.get('/user/user-group', {
    params: {
      userGroupId,
    },
  })
  return response?.data
}

export async function addUserGroup(userGroup: ICarrierUserGroup) {
  await axiosInstance.post('/user/user-group', {
    userIds: userGroup.userIds,
    name: userGroup.name,
    projectPermissions: userGroup.projectPermissions,
  })
}

export async function updateUserGroup(userGroup: ICarrierUserGroup) {
  await axiosInstance.put('/user/user-group', {
    userGroupId: userGroup.id,
    isActive: userGroup.isActive,
    userIds: userGroup.userIds,
    name: userGroup.name,
    projectPermissions: userGroup.projectPermissions,
  })
}

export async function mergeUserGroups(data: IUserGroupMergeData) {
  await axiosInstance.post('/user/user-group/merge', data)
}

export async function updateUserGroups(groups: ICreateUserGroupForm[], newProjectId: string, oldProjectId: string) {
  const updatedData = cloneDeep(groups)
  updatedData.forEach(async (element: ICreateUserGroupForm) => {
    if (!element.projectPermissions) return

    const newPermission = cloneDeep(element.projectPermissions[oldProjectId])
    delete element.projectPermissions[oldProjectId]
    delete element.projectPermissions[PROJECT_NEW_KEY_ID]

    if (element.isNewGroup || (!element?.id && !element.userGroupId)) {
      await axiosInstance.post('/user/user-group', {
        userIds: element.userIds,
        name: element.name,
        projectPermissions: {
          [newProjectId]: {
            ...newPermission,
          },
        },
      })
    } else {
      await axiosInstance.put('/user/user-group', {
        userGroupId: element?.id || element?.userGroupId,
        isActive: true,
        userIds: element.userIds,
        name: element.name,
        projectPermissions: {
          ...element?.projectPermissions,
          [newProjectId]: {
            ...newPermission,
          },
        },
      })
    }
  })
}

export async function fetchStaff() {
  const response = await axiosInstance.get('/user/carrier-users')
  return response?.data
}

export async function fetchStaffById(userId: string) {
  const response = await axiosInstance.get('/user/carrier-user', {
    params: {
      userId,
    },
  })
  return response?.data
}

export async function createNewStaff(staff: IStaff) {
  await axiosInstance.post('/user/carrier-user', staff)
}

export async function updateStaff(staff: unknown) {
  const response = await axiosInstance.put('/user/carrier-user', staff)
  return response?.data
}
export async function getClientList() {
  const response = await axiosInstance.get('/user/clients')
  return response?.data
}

export async function getClient(clientId: string) {
  const response = await axiosInstance.get('/user/client', {
    params: {
      clientId,
    },
  })
  return response?.data
}

export async function saveClient(newObj: IClient, isEditPassword: boolean) {
  const { isEdit, ...restObj } = newObj
  if (!isEdit) {
    const result = await axiosInstance.post('/user/client', restObj)
    return result?.data
  } else {
    const { clientUser, ...editObj } = restObj
    const resultClient = await axiosInstance.put('/user/client', editObj)

    const { id, ...restClientUser } = clientUser
    if (!restClientUser.password) {
      restClientUser.password = ''
    }
    const clientUserData = {
      ...restClientUser,
      userId: id,
      isActive: editObj.isActive,
      isEditPassword,
    } as IClientUser

    await axiosInstance.put('/user/client-user', clientUserData)
    return resultClient?.data
  }
}

export async function getEnterpriseList() {
  const response = await axiosInstance.get('/user/enterprise-users')
  return response?.data
}

export async function getEnterprise(userId: string) {
  const response = await axiosInstance.get('/user/enterprise-user', {
    params: {
      userId,
    },
  })
  return response?.data
}

export async function saveEnterprise(newObj: IEnterprise, isEditPassword: boolean) {
  const { isEdit, isActive, ...restObj } = newObj
  if (!isEdit) {
    const result = await axiosInstance.post('/user/enterprise-user', restObj)
    return result?.data
  } else {
    const { id, ...editObj } = restObj
    if (!editObj.password) {
      editObj.password = ''
    }
    const newDataFormat = {
      ...editObj,
      userId: id,
      isActive: isActive,
      isEditPassword: isEditPassword,
    } as IEnterpriseUser

    const result = await axiosInstance.put('/user/enterprise-user', newDataFormat)
    return result?.data
  }
}
// ====== ADDRESS ======
export async function getAddressDetail(addressIndex: string) {
  const response = await axiosInstance.get('/user/address-detail', {
    params: {
      addressIndex,
    },
  })
  return response?.data
}

export async function getAddresses() {
  const response = await axiosInstance.get('/user/address')

  return response?.data
}

export async function addAddress(payload: IAddress) {
  return await axiosInstance.post('/user/address', { address: payload })
}

export async function updateAddress(idx: number, payload: IAddress) {
  return await axiosInstance.put('/user/address', { index: idx, address: payload })
}

export async function deleteAddress(idx: number) {
  return await axiosInstance.delete('/user/address', { data: { index: idx } })
}

export async function getUserList() {
  const response = await axiosInstance.get('/user/users')
  return response?.data
}

// ==== UPLOAD IMAGE ( project custom content) ====
export async function uploadImage(payload: unknown) {
  const response = await axiosInstance.post('user/enterprise/file', payload)
  return response?.data
}

export async function sendResetPasswordRequest(email: string) {
  return await axiosInstance.post('/user/reset-password', { email })
}

export async function setNewPassword(payload: INewPasswordPayload) {
  payload.visitorId = await getVisitorId()
  return await axiosInstance.post('user/new-password', payload)
}

export async function getProfile() {
  const response = await axiosInstance.get('/user/profile')
  return response?.data
}

export async function logout() {
  const payload = { visitorId: await getVisitorId() }
  localStorage.removeItem(LOCAL_STORAGE_ITEM.VISITOR_ID)
  await axiosInstance.post('/user/logout', payload)
}

export async function removeImage(filePath: string) {
  const response = await axiosInstance.delete('user/enterprise/file', { data: { filePath } })
  return response?.data
}

export async function impersonate(payload: IImpersonateUserPayload) {
  return await axiosInstance.post('user/impersonate', payload)
}

export async function unimpersonate(payload: IVisitor) {
  return await axiosInstance.post('user/unimpersonate', payload)
}

export async function setClientSearchData(data: IClientSearchDataPayload) {
  return (await axiosInstance.post('/user/client-search-data', data))?.data
}
