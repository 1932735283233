
import BarcodeRowMixin from '@/components/mixins/BarcodeRowMixin.vue'
import IconCommonFile from '@/components/svg/IconCommonFile.vue'
import { MASKED_ANNOTATION } from 'smartbarcode-web-core/src/utils/constants'
import { IFileItem } from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'

@Options({
  components: { IconCommonFile },
  name: 'BarcodeRowMulti',
})
export default class BarcodeRowMulti extends Mixins(BarcodeRowMixin) {
  @Prop({ type: Array }) readonly values?: IFileItem[]
  @Prop({ type: Boolean, default: false }) readonly hidden!: boolean

  get isMasked() {
    return this.values?.some((item) => item?.url === MASKED_ANNOTATION)
  }

  get maskedAnnotation() {
    return MASKED_ANNOTATION
  }
}
