<template>
  <div v-loading.fullscreen="loading" class="flex flex-col responsive-page fixed-table">
    <div class="items-center pb-8  header-container">
      <div class="flex mx--2 barcode-search">
        <div class="flex px-2px header-container__left">
          <h4 class="font-bold text-lg truncate leading-normal items-center flex mr-4">
            {{ $t('barcode.search') }}
          </h4>
          <el-select
            key="projects"
            class="w-70 mr-4"
            v-model="dataSearch.projectCode"
            filterable
            @change="onSelectProject"
            :placeholder="$t('selectOne')"
            :disabled="isSetPresetSearch"
          >
            <el-option v-for="(item, key) in getProjectsList" :key="key" :label="item.name" :value="item.code">
              {{ item.name }}
            </el-option>
          </el-select>
          <el-select
            @change="onVersionChange"
            v-model="dataSearch.version"
            :placeholder="$t('projects.version')"
            :key="dataSearch.version"
            :disabled="isSetPresetSearch"
          >
            <el-option key="0" :label="$t('all')" :value="0">
              {{ $t('all') }}
            </el-option>
            <el-option
              v-for="index in maxProjectVersion"
              :key="maxProjectVersion - index + 1"
              :label="maxProjectVersion - index + 1"
              :value="maxProjectVersion - index + 1"
            >
              {{ maxProjectVersion - index + 1 }}
            </el-option>
          </el-select>
        </div>
        <div class="items-center flex-grow px-1px flex justify-end header-container__right"></div>
      </div>
    </div>
    <div
      class="search-container rounded-md mb-4 mx-3 pt-2 pl-2 pb-4 border-solid border border-gray-300 whitespace-nowrap align-middle"
    >
      <el-form v-if="isSetPresetSearch" :disabled="isSetPresetSearch">
        <SearchForm
          ref="presetForm"
          :version="presetData.version"
          v-model:searchConditionBlocks="presetData.searchConditionBlocks"
          v-model:logicalOperator="presetData.logicalOperator"
        />
      </el-form>
      <SearchForm
        v-if="!!dataSearch?.searchConditionBlocks"
        ref="searchForm"
        :version="dataSearch.version"
        v-model:searchConditionBlocks="dataSearch.searchConditionBlocks"
        v-model:logicalOperator="dataSearch.logicalOperator"
      />
    </div>

    <div class="flex justify-between items-center ml-2 my-3">
      <div>
        <el-checkbox v-if="isEnableArchive" v-model="dataSearch.isArchived">
          {{ $t('search_archived_barcode') }}
        </el-checkbox>
      </div>

      <div class="flex justify-end mr-2">
        <el-button-group>
          <el-button
            class="rounded-md hover:bg-white hover:text-black el-process-btn"
            type="default"
            style="padding-top: 9px; padding-bottom: 9px;"
            size="medium"
            @click="clearAllSearchForm()"
          >
            {{ $t('clearAll') }}
          </el-button>
          <el-button type="default" class="rounded-md button-blue text-white" size="medium" @click="performSearch()">
            <div class="flex items-center">
              <span>{{ $t('search') }}</span>
              <IconSearch style="height:14px;" />
            </div>
          </el-button>
        </el-button-group>
      </div>
    </div>

    <div class="flex justify-between pb-3 pt-2 header-bar items-center rounded-t-xl mt-3">
      <div class="ml-4 content-end font-bold text-base">
        {{ $t('total_barcode') }}: {{ totalBarcodes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
      </div>

      <div class="mr-4">
        <el-dropdown placement="bottom-end">
          <el-button class="rounded-l-md" type="default" size="medium">
            {{ $t('downloads') }}
            <el-icon class="el-icon-arrow-down"></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :disabled="isCSVButtonDisable" @click="downloadCSV()">
                <div class="flex flex-row items-center">
                  <div class="mr-2"><IconDownload /></div>
                  <span>{{ $t('barcode.csv_download') }}</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item v-if="!isLoggedAsClient" :disabled="totalBarcodes <= 0" @click="downloadPDFBarcodes()">
                <div class="flex flex-row items-center">
                  <div class="mr-2"><IconDownload /></div>
                  <span>{{ $t('barcode.pdf_download') }}</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item v-if="isDevelopmentEnv" :disabled="isCSVButtonDisable" @click="downloadAttachedFile()">
                <div class="flex flex-row items-center">
                  <div class="mr-2"><IconDownload /></div>
                  <span>{{ $t('barcode.download_attached_file') }}</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown placement="bottom-end">
          <el-button class="rounded-r-md" type="default" size="medium">
            <!-- <div class="py-1"> -->
            <IconVerticalThreeDots style="height:12px;width:13px;" />
            <!-- </div> -->
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-if="isEnableArchive" @click="openArchiveDialog">
                <div class="flex flex-row items-center">
                  <IconArchive style="height:24px;width:24px;padding-top:5px;" />
                  <span>{{ $t(!dataSearch.isArchived ? 'archive_results' : 'unarchive_results') }}</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item v-if="!isLoggedAsClient && isSetDisplayedBarcodeByClient" @click="openPresetDialog">
                <div class="flex flex-row items-center">
                  <IconPreset style="height:24px;width:24px; padding-top:5px;" />
                  <span>{{ $t('set_preset') }}</span>
                </div>
              </el-dropdown-item>

              <el-dropdown-item @click="openBarcodeDialog">
                <div class="flex flex-row items-center">
                  <IconSetting style="height:20px;width:20px; padding-top:5px;" />
                  <span class="ml-1">{{ $t('barcode.display_project_settings') }}</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div v-if="barcodeList.length > 0" class="flex-grow flex flex-col">
      <div class="mb-4 flex-grow table-container">
        <el-table
          v-if="isShowTable"
          :data="barcodeList"
          :default-sort="defaultSort"
          class="table-content w-full"
          header-cell-class-name="custom-table__header-cell"
          cell-class-name="custom-table__cell cursor-pointer"
          border
          :empty-text="$t('empty')"
          highlight-current-row
          @sort-change="sortBarcode"
          @row-click="onEditBarcode"
        >
          <el-table-column
            v-if="barcodeDisplayColumns.includes('id')"
            prop="id"
            :label="$t('barcode.id')"
            width="220"
            :sortable="'custom'"
          />
          <el-table-column
            v-if="barcodeDisplayColumns.includes('barcodeType')"
            :label="$t('projects.barcodeType')"
            :min-width="20"
            :sortable="'custom'"
            prop="barcodeType"
          >
            <template v-slot="scope">
              <div class="flex" v-if="scope.row.barcodeTypeDefinition">
                <div class="w-8 h-8 barcode-icon-container flex item-centers">
                  <BarcodeIcon
                    :barcodeType="formatBarcodeType(scope.row.barcodeTypeDefinition)"
                    :isShowLabel="false"
                    :isPointer="false"
                  />
                </div>
                <div class="flex-grow flex items-center ml-4">{{ scope.row.barcodeTypeDefinition.name }}</div>
              </div>
            </template>
          </el-table-column>
          <!-- externalId -->
          <el-table-column
            v-if="barcodeDisplayColumns.includes('externalId')"
            :label="$t('barcode.externalId')"
            :min-width="20"
            :sortable="'custom'"
            prop="activationData.externalId"
          >
            <template v-slot="scope">
              <div class="flex" v-if="scope.row.activationData?.externalId">
                <div class="flex-grow flex items-center ml-4">{{ scope.row.activationData?.externalId }}</div>
              </div>
            </template>
          </el-table-column>
          <!-- trackingNumber -->
          <el-table-column
            v-if="barcodeDisplayColumns.includes('trackingNumber')"
            :label="$t('barcode.trackingNumber')"
            :min-width="20"
            :sortable="'custom'"
            prop="activationData.trackingNumber"
          >
            <template v-slot="scope">
              <div class="flex" v-if="scope.row.activationData?.trackingNumber">
                <div class="flex-grow flex items-center ml-4">{{ scope.row.activationData?.trackingNumber }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="barcodeDisplayColumns.includes('currentTrackpoint')"
            :label="$t('barcode.currentTrackpoint')"
            width="180"
            :sortable="'custom'"
            prop="currentTrackPointKey"
          >
            <template v-slot="scope">{{ scope.row.currentTrackPointName }}</template>
          </el-table-column>
          <el-table-column
            v-if="barcodeDisplayColumns.includes('createdDate')"
            prop="audit.createdDateTime"
            :label="$t('barcode.createdDate')"
            :min-width="16"
            :sortable="'custom'"
          >
            <template v-slot="scope">{{ displayDateTime(scope.row.audit?.createdDateTime) }}</template>
          </el-table-column>
          <el-table-column
            v-if="barcodeDisplayColumns.includes('lastTrackingDateTime')"
            prop="audit.lastTrackingDateTime"
            :label="$t('barcode.lastTrackingDateTime')"
            :min-width="16"
            :sortable="'custom'"
          >
            <template v-slot="scope">{{ displayDateTime(scope.row.audit?.lastTrackingDateTime) }}</template>
          </el-table-column>
          <el-table-column
            v-if="barcodeDisplayColumns.includes('status')"
            prop="isActive"
            :label="$t('status')"
            width="100"
          >
            <template v-slot="scope">{{ scope.row.isActive ? $t('active') : $t('inactive') }}</template>
          </el-table-column>
          <el-table-column
            v-if="!isLoggedAsClient && barcodeDisplayColumns.includes('report_output')"
            prop="isActive"
            :label="$t('barcode_type.report_output')"
            width="200"
          >
            <template v-slot="scope">
              <div v-if="barcodeReportTemplates(scope?.row?.barcodeType).length > 0" class="text-center">
                <el-popover placement="bottom-end" :width="200" trigger="hover" popper-class="popover-barcode-list">
                  <template #reference>
                    <el-button
                      @click.stop="() => {}"
                      type="primary"
                      size="medium"
                      class="text-white focus:text-white"
                      :disabled="loading"
                    >
                      {{ $t('barcode_type.report_output') }}
                    </el-button>
                  </template>
                  <div
                    v-for="reportTemplate in barcodeReportTemplates(scope?.row?.barcodeType)"
                    :key="reportTemplate.code"
                    :class="['cursor-pointer text-center', 'text-black', 'my-2']"
                    @click="requestReport(reportTemplate.code || '', scope.row.id)"
                  >
                    {{ reportTemplate.buttonLabel }}
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="justify-center flex flex-row mb-16 paging-container">
        <el-pagination
          layout="prev, pager, next"
          :total="totalBarcodes"
          :page-size="dataSearch.count"
          :current-page="currentPageNum"
          @current-change="currentPageChange($event)"
          background
          :class="totalBarcodes > 60 ? 'custom-pagination-no-last-page' : 'custom-pagination'"
        />
      </div>
      <BarcodePDF ref="barcodePDFComponent" :barcodes="exportBarcodes" :project="getCurrentProject" />
    </div>

    <template v-else>
      <ItemNotFound class="mt-56" :content="$t('empty')" />
    </template>
    <div class="dialog-area">
      <el-dialog
        custom-class="el-dialog--custom"
        v-model="isShowArchiveBCDialog"
        width="550px"
        top="0"
        :title="$t(!dataSearch.isArchived ? 'archive_barcode' : 'unarchive_barcode')"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        :show-close="true"
        @close="isShowArchiveBCDialog = false"
      >
        <div class="my-4 pre-line leading-8 text-center">
          {{
            $t(!dataSearch.isArchived ? 'confirm_archive_barcode_message' : 'confirm_unarchive_barcode_message', {
              total_barcode: totalBarcodes,
            })
          }}
        </div>
        <template #footer>
          <div class="flex">
            <div class="flex-1">
              <el-button type="default" class="btn-default-cancel" @click="isShowArchiveBCDialog = false">
                {{ $t('barcode_type.cancel') }}
              </el-button>
            </div>
            <div class="flex-1">
              <el-button type="primary" @click="archiveOrUnarchive">
                {{ $t('confirm') }}
              </el-button>
            </div>
          </div>
        </template>
      </el-dialog>
      <el-dialog
        v-if="!isLoggedAsClient"
        custom-class="el-dialog--custom"
        v-model="isShowClientPresetDialog"
        width="1000px"
        top="0"
        :title="$t('set_client_search_preset')"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        :show-close="true"
        @close="isShowClientPresetDialog = false"
      >
        <div>
          {{ $t('select_clients_to_current_search_preset') }}
        </div>
        <div class="my-4">
          <el-checkbox-group v-model="selectedClients">
            <div class="flex flex-row flex-wrap px-2 group-name-container">
              <div v-for="client in clients" :key="client.id" class="w-1/4 py-2">
                <el-checkbox :value="client.id" :label="client.id">
                  {{ client.name }}
                </el-checkbox>
              </div>
            </div>
          </el-checkbox-group>
        </div>
        <template #footer>
          <div class="flex">
            <div class="flex-1">
              <el-button type="default" class="btn-default-cancel" @click="isShowClientPresetDialog = false">
                {{ $t('barcode_type.cancel') }}
              </el-button>
            </div>
            <div class="flex-1">
              <el-button type="primary" @click="setSearchPreset2Clients">
                {{ $t('set') }}
              </el-button>
            </div>
          </div>
        </template>
      </el-dialog>
      <el-dialog
        v-model="isShowBarcodeDisplaySetting"
        custom-class="el-dialog--custom"
        width="600px"
        top="0"
        :title="$t('barcode.display_project_settings')"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        :show-close="true"
        @close="isShowBarcodeDisplaySetting = false"
      >
        <div>
          {{ $t('barcode.select_item_for_display_in_barcode_search_list') }}
        </div>
        <div class="my-4 flex justify-center">
          <div>
            <el-checkbox-group v-model="selectedBarcodeDisplayColumns">
              <div v-for="column in displayColumns" :key="column.idx" class="w-1/4 py-2 mr-8">
                <el-checkbox :value="column.idx" :label="column.idx">{{ column.label }}</el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </div>
        <template #footer>
          <div class="flex">
            <div class="flex-1">
              <el-button type="default" class="btn-default-cancel" @click="isShowBarcodeDisplaySetting = false">
                {{ $t('barcode_type.cancel') }}
              </el-button>
            </div>
            <div class="flex-1">
              <el-button type="primary" @click="saveBarcodeDisplaySettings">
                {{ $t('apply') }}
              </el-button>
            </div>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import BarcodePDF from '@/components/barcodeImport/BarcodePDF.vue'
import SearchForm from '@/components/barcodeSearch/SearchForm.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import IconArchive from '@/components/svg/IconArchive.vue'
import IconDownload from '@/components/svg/IconDownload.vue'
import IconPreset from '@/components/svg/IconPreset.vue'
import IconSearch from '@/components/svg/IconSearch.vue'
import IconSetting from '@/components/svg/IconSetting.vue'
import IconVerticalThreeDots from '@/components/svg/IconVerticalThreeDots.vue'
import { LOAD_BARCODE_LIST, LOAD_CLIENT_LIST, LOAD_PROJECTS_LIST, SET_HAVE_NEW_EXPORT_RECORD } from '@/store/actions'
import {
  archiveBarcodes,
  exportBarCode,
  exportBarCodeAttchFiles,
  getBarcodeList,
  requestBarcodeReport,
  setClientSearchData,
} from '@/utils/api'
import {
  BARCODE_SEARCH_VALUE_TYPES,
  EXPORT_BARCODES_LIMIT_NUMBER,
  TABLE_SORT_ASC,
  TABLE_SORT_DESC,
  TABLE_SORT_TYPE,
} from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { buildSearchUrlQuery, formatSearchConditionBlocks, initDataSearch } from '@/utils/helpers'
import { openMessage, sortTrackingPoints } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { EExportType, ESmartBarcodeMenu, EUserType } from 'smartbarcode-web-core/src/utils/enums/index'
import { objectToArray } from 'smartbarcode-web-core/src/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcodeDefinitionType,
  IBarcodeList,
  IBarcodeReportTemplate,
  IBarcodeSearch,
  IBarcodeSearchForm,
  IBarcodeSearchFormItem,
  IClientList,
  IProject,
  ISearchConditionBlock,
  ITableSortData,
  ITrackpoint,
  ITrackPointKeyVal,
  TError,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, ProvideReactive, Watch } from 'vue-property-decorator'
type TListType = 'searchList' | 'presetList'

export interface IClient {
  id: string
  name: string
  email: string
}

@Options({
  components: {
    ItemNotFound,
    SearchForm,
    BarcodeIcon,
    IconDownload,
    BarcodePDF,
    IconSearch,
    IconSetting,
    IconPreset,
    IconVerticalThreeDots,
    IconArchive,
  },
  name: ESmartBarcodeMenu.BARCODE_SEARCH,
})
export default class Barcode extends Mixins(DataTableMixin) {
  @ProvideReactive() projectVersion = 0
  @ProvideReactive() trackingPointDatasArr: ITrackPointKeyVal[] = []

  isShowClientPresetDialog = false
  isShowBarcodeDisplaySetting = false
  isShowArchiveBCDialog = false

  currentPageNum = 1
  dataSearch = {} as IBarcodeSearchForm
  loading = false
  isShowTable = true
  exportBarcodes = []
  selectedClients: string[] = []

  barcodeDisplayColumns: string[] = []
  selectedBarcodeDisplayColumns: string[] = []

  get isEnableArchive(): boolean {
    return this.$store.getters.isEnableArchive
  }

  openBarcodeDialog() {
    this.selectedBarcodeDisplayColumns = JSON.parse(localStorage.getItem('barcode_table_display_columns') ?? '[]')
    this.isShowBarcodeDisplaySetting = true
  }

  saveBarcodeDisplaySettings() {
    localStorage.setItem('barcode_table_display_columns', JSON.stringify(this.selectedBarcodeDisplayColumns))
    this.barcodeDisplayColumns = [...this.selectedBarcodeDisplayColumns]
    this.isShowBarcodeDisplaySetting = false
  }

  get displayColumns() {
    return [
      { idx: 'id', label: this.$t('barcode.id') },
      { idx: 'barcodeType', label: this.$t('barcode.barcodeType') },
      { idx: 'externalId', label: this.$t('barcode.externalId') },
      { idx: 'trackingNumber', label: this.$t('barcode.trackingNumber') },
      { idx: 'currentTrackpoint', label: this.$t('barcode.currentTrackpoint') },
      { idx: 'createdDate', label: this.$t('barcode.createdDate') },
      { idx: 'lastTrackingDateTime', label: this.$t('barcode.lastTrackingDateTime') },
      { idx: 'status', label: this.$t('status') },
      { idx: 'report_output', label: this.$t('barcode_type.report_output') },
    ]
  }

  openArchiveDialog() {
    this.isShowArchiveBCDialog = true
  }

  openPresetDialog() {
    this.isShowClientPresetDialog = true
    this.selectedClients = []
  }

  get clients(): IClient[] {
    return ([...this.$store.state.client.clients]
      .filter((val: IClientList) => !!val.clientUser)
      .map((c: IClientList) => ({
        id: c.clientUser.id,
        name: c.client.name,
        email: c.clientUser.email,
      })) ?? []) as IClient[]
  }

  get isSetDisplayedBarcodeByClient() {
    return this.$store.state.profile?.organization?.setDisplayedBarcodeByClient || false
  }

  async fetchClients() {
    if (this.isLoggedAsClient) return
    try {
      this.loading = true
      await this.$store.dispatch(LOAD_CLIENT_LIST)
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  @Watch('dataSearch.version')
  updateProjectVersion() {
    this.projectVersion = this.dataSearch.version
  }

  get maxProjectVersion() {
    const project = this.getProjectsList.find((item) => item.code === this.dataSearch.projectCode)
    return project?.version ?? 1
  }

  get isSetPresetSearch() {
    return this.$store.state.profile?.user?.setPresetSearch
  }

  presetData = initDataSearch() as IBarcodeSearchForm
  async loadClientPreset() {
    if (!this.isLoggedAsClient || !this.isSetPresetSearch) return false
    const clientPreset = cloneDeep(this.$store.state.profile?.user?.condition)
    clientPreset.searchConditionBlocks.forEach(async (block: ISearchConditionBlock) => {
      // specially handle projectId
      if (block.searchConditions[0].key === 'projectId') block.searchConditions.splice(0, 1)

      block.searchConditions.forEach((condition) => {
        // specially handle subKey
        const keys = (condition.key ?? '').split('.')
        if (keys.length === 3) {
          condition.key = `${keys[0]}.${keys[1]}`
          condition.subKey = keys[2]
        }
      })
    })

    this.presetData = {
      ...this.dataSearch,
      ...clientPreset,
      projectCode: clientPreset.projectCode,
      version: clientPreset.version,
      count: 10,
    }
    this.dataSearch = {
      ...initDataSearch(),
      projectCode: clientPreset.projectCode,
      version: clientPreset.version,
    }

    return true
  }

  get totalBarcodes(): number {
    return this.$store.state.barcode?.totalBarcodes
  }

  get defaultSort() {
    return {
      prop: (this.dataSearch?.sortKey !== '_id' ? this.dataSearch?.sortKey : 'id') || null,
      order:
        this.dataSearch?.sortOrder === 1 ? TABLE_SORT_ASC : this.dataSearch?.sortOrder === -1 ? TABLE_SORT_DESC : '',
    }
  }

  get loggedUser() {
    return this.$store.state.profile?.user || {}
  }

  get isLoggedAsClient() {
    return this.loggedUser.userType === EUserType.CLIENT || false
  }

  get isCSVButtonDisable(): boolean {
    const isSearchValue = this.dataSearch?.searchConditionBlocks?.some((block) => {
      return block.searchConditions?.some(
        (val) => !isEmpty(val.value) || (!isEmpty(val.minValue) && !isEmpty(val.maxValue))
      )
    })
    return !isSearchValue || this.barcodeList.length < 1
  }

  get barcodeList(): IBarcodeList[] {
    return this.$store.state.barcode?.barcodes
  }

  get getCurrentProject(): IProject {
    return this.$store.state.barcode?.projectList
  }

  get getProjectsList(): IProject[] {
    return this.$store.state.project?.originalProjects.filter(
      (item: IProject) => !(item.isDraft && item.isDraft === true)
    )
  }

  barcodeReportTemplates(barcodeCode: string): IBarcodeReportTemplate[] {
    if (!barcodeCode) return []
    return this.getCurrentProject?.barcodeTypes?.[barcodeCode]?.barcodeReportTemplates || []
  }

  formatBarcodeType(barcodeTypeDefinition: IBarcodeDefinitionType): IBarcodeDefinitionType {
    return barcodeTypeDefinition as IBarcodeDefinitionType
  }

  async setSearchPreset2Clients() {
    this.loading = true
    const preset = cloneDeep(this.dataSearch)
    const searchConditionBlocks = formatSearchConditionBlocks(preset.searchConditionBlocks)

    const setPresetPayload = {
      isConfirm: false,
      clientIds: this.selectedClients,
      condition: { ...preset, searchConditionBlocks } as IBarcodeSearchForm,
    }
    try {
      await setClientSearchData(setPresetPayload)
    } catch (e) {
      if (Object.values(e as TError)?.[0]?.[0] === '3085') {
        this.$confirm(this.$t('confirm_overwrite_exist_client_preset'), this.$t('confirm'), {
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'danger',
          cancelButtonText: this.$t('cancel'),
        }).then(async () => {
          await setClientSearchData({ ...setPresetPayload, isConfirm: true })
          openMessage(this.$t('set_preset_successfully'), 'success')
        })
      } else {
        errorHandler(e)
      }
    } finally {
      this.loading = false
      this.isShowClientPresetDialog = false
    }
  }

  async archiveOrUnarchive() {
    try {
      this.loading = true
      const condition = (() => {
        const condBlocks = [] as ISearchConditionBlock[]
        const searchData = cloneDeep(this.dataSearch)
        searchData.searchConditionBlocks.forEach((i) => condBlocks.push(cloneDeep(i)))

        return { ...searchData, searchConditionBlocks: formatSearchConditionBlocks(condBlocks) } as IBarcodeSearchForm
      })()

      await archiveBarcodes({
        archiveType: !this.dataSearch.isArchived ? 'archive' : 'unarchive',
        condition,
      })

      this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.ARCHIVE, isHaveNew: true })
      openMessage(this.$t('archive_barcode_success'), 'success')
    } catch (e) {
      errorHandler(e)
    } finally {
      this.isShowArchiveBCDialog = false
      this.loading = false
    }
  }

  listType: TListType = 'searchList'
  async fetchBarcodesList(data: IBarcodeSearchForm, type: TListType) {
    this.loading = true

    if (type === 'presetList') data = { ...this.presetData, skip: data.skip }

    this.listType = type
    this.isShowTable = false
    this.redirectIndex(data, this.currentPageNum)
    await this.$store.dispatch(LOAD_BARCODE_LIST, data).finally(() => (this.loading = false))
    setTimeout(() => (this.isShowTable = true))

    const newTPs = this.getCurrentProject.trackPoints
    if (isEmpty(newTPs)) return

    const arrTrackPoints = objectToArray<ITrackpoint>(cloneDeep(newTPs)) || []
    sortTrackingPoints(arrTrackPoints)
    this.trackingPointDatasArr = arrTrackPoints
  }

  async sortBarcode(data: ITableSortData) {
    this.dataSearch.sortKey = data.prop && data.prop !== 'id' ? data.prop : data.prop ?? '_id'
    this.dataSearch.sortOrder = TABLE_SORT_TYPE[data.order ?? TABLE_SORT_DESC]
    await this.fetchBarcodesList(this.dataSearch, this.listType)
  }

  async currentPageChange(pageNum: number) {
    if (this.isTriggerPageChanging) {
      this.currentPageNum = pageNum
      const skipNum = (Number(pageNum) - 1) * Number(this.dataSearch.count)
      this.dataSearch.skip = skipNum
      await this.fetchBarcodesList(this.dataSearch, this.listType)
    } else {
      this.isTriggerPageChanging = true
    }
  }

  async onSelectProject() {
    const dataSearch = initDataSearch()
    dataSearch.projectCode = this.dataSearch.projectCode

    const project = this.getProjectsList.find((item) => item.code === this.dataSearch.projectCode)
    dataSearch.version = project?.version ?? 1

    this.currentPageNum = 1
    await this.fetchBarcodesList(dataSearch, this.listType)
    this.dataSearch = dataSearch
  }

  async requestReport(code: string, barcodeId: string) {
    try {
      this.loading = true
      if (barcodeId) {
        await requestBarcodeReport(barcodeId, code)
      }

      this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.BARCODE, isHaveNew: true })
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  get isDevelopmentEnv(): boolean {
    return this.$store?.getters?.isDevelopmentEnv
  }

  async downloadAttachedFile() {
    try {
      const payload = cloneDeep(this.dataSearch)
      delete payload.count
      delete payload.skip
      // format dataSearch
      const searchConditionBlocks = formatSearchConditionBlocks(payload.searchConditionBlocks)
      const dataSearch = { condition: { ...payload, searchConditionBlocks } } as IBarcodeSearch
      await exportBarCodeAttchFiles((dataSearch as unknown) as Record<string, unknown>)
      this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.FILE, isHaveNew: true })
    } catch (error) {
      openMessage(error as string, 'error')
    }
  }

  async downloadCSV() {
    try {
      const payload = cloneDeep(this.dataSearch)
      delete payload.count
      delete payload.skip
      // format dataSearch
      const searchConditionBlocks = formatSearchConditionBlocks(payload.searchConditionBlocks)
      const dataSearch = { condition: { ...payload, searchConditionBlocks } } as IBarcodeSearch
      await exportBarCode((dataSearch as unknown) as Record<string, unknown>)
      this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.BARCODE, isHaveNew: true })
    } catch (error) {
      openMessage(error as string, 'error')
    }
  }

  async downloadPDFBarcodes() {
    try {
      this.loading = true
      const condBlocks = [] as ISearchConditionBlock[]
      this.dataSearch.searchConditionBlocks.forEach((i) => condBlocks.push(cloneDeep(i)))
      const dataSearch = {
        condition: {
          ...this.dataSearch,
          count: EXPORT_BARCODES_LIMIT_NUMBER,
          searchConditionBlocks: formatSearchConditionBlocks(condBlocks),
        },
      } as IBarcodeSearch
      // const result = await getBarcodeList(dataSearch)
      this.exportBarcodes = (await getBarcodeList(dataSearch)) ?? []
      this.$refs.barcodePDFComponent.showComfirmPopup()
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  onEditBarcode(barcode: IBarcodeList) {
    this.$router.push({ name: 'barcodeView', params: { id: barcode.id } })
  }

  // this option control - turn off onPageChange event trigger at first time when searching.
  isTriggerPageChanging = true
  async performSearch() {
    this.isTriggerPageChanging = false

    this.dataSearch.skip = 0
    await this.fetchBarcodesList(this.dataSearch, 'searchList')
  }

  async clearAllSearchForm() {
    const dataSearch = {
      ...initDataSearch(),
      projectCode: this.dataSearch.projectCode,
      version: this.dataSearch.version,
    }
    this.dataSearch = dataSearch
    await this.fetchBarcodesList(dataSearch, this.listType)
  }

  initializeDisplayColumnSettings() {
    this.barcodeDisplayColumns = JSON.parse(localStorage.getItem('barcode_table_display_columns') ?? '[]')
    if (isEmpty(this.barcodeDisplayColumns)) {
      const columns = this.displayColumns.map((i) => i.idx)
      localStorage.setItem('barcode_table_display_columns', JSON.stringify([...columns]))
      this.barcodeDisplayColumns = [...columns]
      this.selectedBarcodeDisplayColumns = [...columns]
    }
  }

  async created() {
    this.initializeDisplayColumnSettings()
    await this.fetchClients()

    if (!this.$store.state.project?.isLoaded) {
      this.loading = true
      await this.$store.dispatch(LOAD_PROJECTS_LIST)
      this.loading = false
    }

    const hasQueryData = !isEmpty(this.$route.query)
    if (hasQueryData && !this.isSetPresetSearch) {
      this.loadedURLSearchData()
      await this.fetchBarcodesList(this.dataSearch, 'searchList')
    } else {
      const isLoadedPreset = await this.loadClientPreset()
      if (isLoadedPreset) {
        await this.fetchBarcodesList(this.presetData, 'presetList')
      } else {
        this.dataSearch = initDataSearch()
        this.dataSearch.projectCode = this.getProjectsList?.[0].code ?? ''
        this.dataSearch.version = this.getProjectsList.find((i) => i.code === this.dataSearch.projectCode)?.version ?? 1
        await this.fetchBarcodesList(this.dataSearch, 'searchList')
      }
    }
  }

  async onVersionChange() {
    const dataSearch = {
      ...initDataSearch(),
      projectCode: this.dataSearch.projectCode,
      version: this.dataSearch.version,
    }
    this.dataSearch = dataSearch
    await this.fetchBarcodesList(this.dataSearch, this.listType)
  }

  loadedURLSearchData() {
    const queryData = this.$route.query
    this.dataSearch = initDataSearch()
    if (queryData.projectCode) {
      for (const key in queryData) {
        switch (key) {
          case 'sortOrder':
            this.dataSearch[key] = queryData[key] === TABLE_SORT_DESC ? -1 : queryData[key] === TABLE_SORT_ASC ? 1 : 0
            break
          case 'version':
            this.dataSearch[key] = Number(queryData[key])
            break
          case 'projectCode':
          case 'logicalOperator':
          case 'sortKey':
            this.dataSearch[key] = queryData[key]
            break
          case 'page':
            if (queryData.page) {
              this.currentPageNum = Number(queryData.page)
              const skipNum = (this.currentPageNum - 1) * Number(this.dataSearch.count)
              this.dataSearch.skip = skipNum
            } else {
              this.currentPageNum = 1
            }
            break
          default:
            this.dataSearch = this.getSearchConditionBlocks(this.dataSearch, queryData, key)
            break
        }
      }
    }
  }

  getSearchConditionBlocks(dataSearch: IBarcodeSearchForm, queryData: Record<string, string>, key: string) {
    const [mainKey, conditionIdx, blockIdx] = key.split('_')

    const idxNum = Number(conditionIdx)
    const blockIdxNum = Number(blockIdx)

    if (!dataSearch.searchConditionBlocks[blockIdxNum]) {
      dataSearch.searchConditionBlocks[blockIdxNum] = { searchConditions: [] }
    }
    const block = dataSearch.searchConditionBlocks[blockIdxNum]
    if (mainKey) {
      if (mainKey === 'logicalOperator') {
        block.logicalOperator = queryData[key]
      } else if (mainKey === 'valueList') {
        const valueList = queryData[key].split(',').map((item) => item.trim())
        block.searchConditions[idxNum].valueList = valueList as string[]
      } else {
        if (!block.searchConditions?.[idxNum]) block.searchConditions[idxNum] = {} as IBarcodeSearchFormItem

        block.searchConditions[idxNum][
          mainKey as 'key' | 'subKey' | 'value' | 'minValue' | 'maxValue' | 'valueType' | 'bcTypeKey' | 'componentType'
        ] = queryData[key]
      }
    }

    block.searchConditions.map((item: IBarcodeSearchFormItem) => {
      if (item.valueType === BARCODE_SEARCH_VALUE_TYPES.date) {
        if (item.value) item.value = moment(item.value).format()
        if (item.minValue) item.minValue = moment(item.minValue).format()
        if (item.maxValue) item.maxValue = moment(item.maxValue).format()
        if (item.minValue && item.maxValue) {
          item.value = ([item.minValue, item.maxValue] as unknown) as string
        }
      }
    })

    return dataSearch
  }

  redirectIndex(data: IBarcodeSearchForm, page: number) {
    this.$router.push({ name: this.routeName, query: buildSearchUrlQuery(data, page) })
  }
}
</script>

<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';

.header-container-wrapper {
  background-color: $body-bg;
}

.fixed-table:deep() {
  height: calc(100vh - 100px);
  min-width: 800px;

  &.table-content {
    overflow: auto;
    height: calc(100vh - 400px);
    min-height: 200px;
  }

  &.el-table {
    &__header-wrapper {
    }
    &__header {
      thead {
        tr:first-child {
          th:first-child {
            border-top-left-radius: 0px !important;
          }
          th:last-child {
            border-top-right-radius: 0px !important;
          }
        }
      }
    }
    &::before {
      height: 0px;
    }

    &__header-wrapper {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 10;
    }
  }
}

.dialog-area :deep() {
  .el-overlay .el-dialog {
    max-width: 1000px;
  }

  .el-button--small {
    padding: 7px;
    min-height: 28px !important;
    margin-top: 3px;
  }
  .el-form-item {
    margin-bottom: 16px;
    &__item {
      top: calc(100% - 5px);
    }
  }
}

.header-bar {
  background-color: $light-gray;
}
.el-process-btn {
  &.orange-btn {
    &:hover {
      border-color: lightgoldenrodyellow;
      background-color: #fb923c;
      color: white;
    }
  }

  &.green-btn {
    background-color: #20cf90;
    border-color: #a5ebd2;
    color: white;
  }

  &.is-disabled {
    color: gray;
    background-color: $light-gray !important;
  }
}
</style>
