
import BarcodeTypesMixin from '@/components/mixins/BarcodeTypesMixin.vue'
import RichTextEditor from '@/components/project/RichTextEditor.vue'
import { compileMessage, getBarcode } from '@/utils/api'
import { DEFAULT_CUSTOM_CONTENT_TEMPLATE } from '@/utils/constants'
import { getCompiledBarcode } from 'smartbarcode-web-core/src/utils/barcodeHelper'
import {
  ITrackPointKeyVal,
  ICustomContentTemplate,
  IBarcode,
  IBarcodeDefinitionType,
} from 'smartbarcode-web-core/src/utils/types/index'
import { openMessage } from '@/utils/utils'
import debounce from 'lodash/debounce'
import { mixins, Options } from 'vue-class-component'
import { InjectReactive, Prop, PropSync, Watch } from 'vue-property-decorator'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'

export interface IPreview {
  barcodeId: string
}

@Options({
  components: { RichTextEditor },
  emits: ['update:modelValue'],
  name: 'TrackingNumberBlock',
})
export default class TrackingNumberBlock extends mixins(BarcodeTypesMixin) {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @PropSync('modelValue', { type: Array }) _modelValue?: ICustomContentTemplate[]
  @Prop({ type: String }) readonly currentBCTypeCode?: string
  @Prop({ type: Boolean }) readonly isReadOnly?: boolean

  previewBCId = ''
  isPostCustomContent = false
  isShowHistoryLink = false
  barcode: IBarcode | undefined = undefined
  mergedContent = ''

  bcTypesPreviewIds = {} as Record<string, IPreview>

  @Watch('_modelValue')
  onModelValueChanged() {
    if (this._modelValue) {
      this.isPostCustomContent = true
      this.isShowHistoryLink = this._modelValue[0].showTraceHistoryLink ?? false
    } else {
      this.isPostCustomContent = false
      this.isShowHistoryLink = false
    }
  }

  // fallback DEFAULT template in case the custom content is empty
  safeTemplate(value: string | undefined) {
    return !isEmpty(value) ? (value as string) : DEFAULT_CUSTOM_CONTENT_TEMPLATE
  }

  get templateHTML(): string {
    return this.safeTemplate(this._modelValue?.[0]?.templateHtml)
  }

  set templateHTML(newVal: string) {
    if (this._modelValue) {
      this._modelValue[0].templateHtml = this.safeTemplate(newVal)
    }
  }

  @Watch('currentBCTypeCode')
  onCurrentBCTypeCodeChange() {
    if (this.currentBCTypeCode) {
      if (this.bcTypesPreviewIds[this.currentBCTypeCode]) {
        this.previewBCId = this.bcTypesPreviewIds[this.currentBCTypeCode].barcodeId ?? ''
      } else {
        this.previewBCId = ''
      }
    }
  }

  @Watch('previewBCId')
  onBCIdChange() {
    this.contentChanged()
  }

  @Watch('templateHTML')
  contentChanged() {
    if (this.currentBCTypeCode) {
      this.bcTypesPreviewIds[this.currentBCTypeCode] = { barcodeId: this.previewBCId }
    }
    if (isEmpty(this.previewBCId)) {
      this.mergedContent = ''
    } else {
      this.debouncedWatch()
    }
  }

  get project() {
    return this.$store.state.project
  }

  get barcodeTypeInfo(): IBarcodeDefinitionType | undefined {
    return this.getBarcodeTypeInfo(this.barcode)
  }

  getBarcodeTypeInfo(barcode?: IBarcode): IBarcodeDefinitionType | undefined {
    if (!barcode) return undefined
    return this.currentBarcodeTypeInfo(barcode)
  }

  created() {
    this.debouncedWatch = debounce(() => {
      getBarcode(this.previewBCId).then((res) => {
        if (res.projectId !== this.project.projectDetail.mainInfo.id) {
          this.mergedContent = ''
          openMessage(this.$t('barcode_type.bc_project_id_not_match'), 'error')
          return
        }

        if (res.barcodeType !== this.currentBCTypeCode) {
          this.mergedContent = ''
          openMessage(this.$t('barcode_type.bc_barcode_type_not_match'), 'error')
          return
        }

        getCompiledBarcode((res as unknown) as IBarcode, this.project)

        compileMessage(this.previewBCId, this.templateHTML)
          .then((res) => (this.mergedContent = res))
          .catch((e) => openMessage(this.$t('errors.' + e.barcodeId), 'error'))
      })
    }, 500)
  }

  onPostCustomContentChange(checked: boolean) {
    this._modelValue = checked
      ? [
          {
            trackPointKeys: this.endTrackPointKey ? [this.endTrackPointKey] : ([] as string[]),
            templateHtml: DEFAULT_CUSTOM_CONTENT_TEMPLATE,
            showTraceHistoryLink: false,
          },
        ]
      : undefined
  }

  get endTrackPointKey(): string | undefined {
    return this.trackingPointDatasArr.find((o: ITrackPointKeyVal) => o?.value?.isEnd)?.key
  }

  onShowTraceHistoryLinkChange(checked: boolean) {
    if (this._modelValue) this._modelValue[0].showTraceHistoryLink = checked
  }
}
