import { IRoutePath } from 'smartbarcode-web-core/src/utils/types'
import { axiosInstance } from '@/utils/api'

export async function getTWProcessList() {
  return (await axiosInstance.get('/tradeWaltz/processed')).data
}

export async function activateTWProess(data: {
  entityId: string
  linkageGoodsLineBinding: IRoutePath[]
  linkageGoodsLinePackingBinding: IRoutePath[]
  linkageGoodsLinePriceBinding: IRoutePath[]
  bindings: IRoutePath[]
  startTrackingPointKey: string
  projectCode: string
  projectVersion: number
  isDryRun: boolean
}) {
  return (await axiosInstance.post('/tradeWaltz/activate-process', data))?.data
}
