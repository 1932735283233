<template>
  <div
    :class="[
      'table-notfound__container',
      'mt-6',
      'p-10',
      'mx-auto',
      'max-w-lg',
      'rounded-2xl',
      'w-11/12',
      'text-center',
      'item-not-found',
      backgroundColor,
    ]"
  >
    <div>
      <IconNotFound :color="iconColor" />
    </div>
    <h4 class="mt-6 pre-line">
      {{ content }}
    </h4>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import IconNotFound from '@/components/svg/IconNotFound.vue'

@Options({
  components: { IconNotFound },
  name: 'ItemNotFound',
})
export default class ItemNotFound extends Vue {
  @Prop({ type: String }) readonly content!: string
  @Prop({ type: String }) readonly background: string = 'bg-gray-200'
  @Prop({ type: String }) readonly iconColor: string = 'white'

  get backgroundColor() {
    return this.background
  }
}
</script>
<style lang="scss">
.pre-line {
  white-space: pre-line;
}
</style>
