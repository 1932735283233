<template>
  <div class="border-l-2 border-gray-500 border-solid pl-2 ml-12">
    <div v-for="(item, idx) in customFieldVisibilitieValue" :key="idx" class="p-1">
      <el-checkbox
        :disabled="disabled"
        v-model="customFieldVisibilitieValue[idx]"
        @change="updateCustomFieldVisibilities()"
      >
        {{ $t(`customFields.${idx}`) }}
      </el-checkbox>
    </div>
  </div>
</template>
<script lang="ts">
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'
import cloneDeep from 'lodash/cloneDeep'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'

@Options({
  emits: ['update:customFieldVisibilities'],
  name: 'ShowCustomFieldsBlock',
})
export default class ShowCustomFieldsBlock extends Vue {
  @PropSync('customFieldVisibilities', { type: Object }) _customFieldVisibilities!: Array<string>
  @Prop({ default: false }) disabled!: boolean
  customFieldVisibilitieValue = {} as Record<string, boolean>

  created() {
    this.initCustomFieldVisibilities()
  }

  @Watch('_customFieldVisibilities')
  onCustomFieldVisibilitiesChange() {
    this.initCustomFieldVisibilities()
  }

  initCustomFieldVisibilities() {
    this.customFieldVisibilitieData.forEach((item) => {
      this.customFieldVisibilitieValue[item] = this._customFieldVisibilities?.includes(item) ?? false
    })
  }

  updateCustomFieldVisibilities() {
    const newValue = [] as string[]
    for (const i in this.customFieldVisibilitieValue) {
      if (this.customFieldVisibilitieValue[i]) newValue.push(i)
    }
    this._customFieldVisibilities = cloneDeep(newValue)
  }

  get customFieldVisibilitieData() {
    return [
      ECustomFieldType.TEXT,
      ECustomFieldType.DATE,
      ECustomFieldType.SINGLE_SELECT,
      ECustomFieldType.MULTI_SELECT,
      ECustomFieldType.NUMBER,
      ECustomFieldType.ESIGN,
      ECustomFieldType.FILES,
      ECustomFieldType.PHONE_NUMBER,
      ECustomFieldType.EMAIL,
      ECustomFieldType.REFERENCE,
      ECustomFieldType.GHG_EMISSION,
      ECustomFieldType.CALCULATION,
    ]
  }
}
</script>
